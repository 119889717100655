import { useState, useEffect, useCallback } from 'react';

export default function useStepNavigation(numSteps) {
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (numSteps && typeof numSteps === 'number') {
      setSteps(Array.from(Array(numSteps), (_, i) => i));
    }
  }, [numSteps]);

  const onNextStep = useCallback(() => {
    setCurrentStep((step) => {
      return step < numSteps ? step + 1 : step;
    });
  }, [numSteps]);

  const onPrevStep = useCallback(() => {
    setCurrentStep((step) => {
      return step > 0 ? step - 1 : step;
    });
  }, [numSteps]);

  return {
    steps,
    currentStep,
    onNextStep,
    onPrevStep,
    setCurrentStep,
  };
}
