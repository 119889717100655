import React from 'react';
import GreenCheckIcon from '../../assets/icon-check-circle.svg';

export default function StepsNav({ steps, currentStep, onClick }) {
  return (
    <div className='steps'>
      {steps.map((step) => {
        const _className = currentStep === step ? 'step selected' : step < currentStep ? 'step completed' : 'step';
        return (
          <button key={`step-${step}`} className={_className} onClick={() => onClick && onClick(step)} disabled={step > currentStep}>
            {step < currentStep ? <img src={GreenCheckIcon} alt={''} width={36} height={36} /> : <>{step + 1}</>}
          </button>
        );
      })}
    </div>
  );
}
