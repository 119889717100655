import React, { useEffect, useState, useRef } from 'react';
import swal from '@sweetalert/with-react';
import Logo from '../../assets/pando-logo.png';
import './Messages.css';

function EndEventMessage({ wait, hasRedirectUrl }) {
  const _timerId = useRef(null);
  const [secondsLeft, setSecondsLeft] = useState(Math.floor(wait / 1000));

  useEffect(() => {
    _timerId.current = setInterval(() => {
      setSecondsLeft((secondsLeft) => secondsLeft - 1);
    }, 1000);
    return () => {
      clearInterval(_timerId.current);
    };
  }, []);

  useEffect(() => {
    if (secondsLeft <= 0) {
      clearInterval(_timerId.current);
      swal.close();
    }
  }, [secondsLeft]);

  let message;
  if (hasRedirectUrl) {
    message = `You will be redirected in ${secondsLeft < 0 ? 0 : secondsLeft} seconds.`;
  } else {
    message = `You will be disconnected in ${secondsLeft < 0 ? 0 : secondsLeft} seconds.`;
  }

  return (
    <div className='horizontal-message-box'>
      <div className='header'>
        <img src={Logo} alt='Pando' />
      </div>
      <div className='content'>
        <div className='left'>
          <div className='text'>
            <div>{message}</div>
          </div>
        </div>
        {/* <div className='right' /> */}
      </div>
    </div>
  );
}

export default EndEventMessage;
