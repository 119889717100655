import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { store, history } from './store';
import { setLoginUrlSearchParams } from './store/actions';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import Wall from './routes/connect/Wall';
import TwilioBreakoutRoom from './routes/twilioBreakoutRoom/index';
import LoadTestClient from './routes/loadTestClient/index';
import swal from '@sweetalert/with-react';
import CustomMessage from './common/Messages/CustomMessage';
import SocketClient from './utils/socket-client';
import OffWallSystemCheck from './routes/offWallSystemCheck/index';
import BroadcastSystemCheck from './routes/broadcastSystemCheck/index';
import BrowserCheckPage from './routes/onboarding/BrowserCheckPage';
import Login from './routes/login/index';
import Onboarding from './routes/onboarding/index';
import Connect from './routes/connect/index';
import ArchivePlayer from './routes/archivePlayer/index';
import VenueLogin from './routes/venueLogin/index';
import { reloadBrowser, isIpad, isMobile } from './utils/browser-util';
import { OFF_WALL_ROLES } from './utils/user-util';
import Heartbeat from './utils/heartbeat';
import Spinner from './common/Spinner';
import ImageCache from './utils/image-cache';
import PandoLogo from './assets/pando-logo.png';
import 'react-toastify/dist/ReactToastify.css';
import './css/App.css';
import Modal from 'react-modal';

Modal.setAppElement('body');

class App extends Component {
  constructor(props) {
    super(props);
    this._prevVisibilityState = 'visible';
    this.state = {
      connectionAllowed: null,
    };
  }

  async componentDidMount() {
    const { pathname, search } = window.location;
    const urlParams = new URLSearchParams(search);
    const excludedPaths = ['/system-check', '/off-wall-system-check', '/broadcast-system-check', '/venue-login'];
    const connectionAllowed = excludedPaths.includes(pathname) || urlParams.get('heartbeat') === 'false' || (await Heartbeat.start());
    if (connectionAllowed) {
      await ImageCache.preload(PandoLogo);
      this.setState({ connectionAllowed });
      store.dispatch(setLoginUrlSearchParams(search));
      document.addEventListener('visibilitychange', this._handleVisibilityChange, false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this._handleVisibilityChange, false);
  }

  _handleVisibilityChange = async () => {
    const role = localStorage.getItem('participantRole');
    const studioTypeString = sessionStorage.getItem('studioTypeString');
    if (
      (studioTypeString === 'Pando' && isIpad && this._prevVisibilityState && !OFF_WALL_ROLES.includes(role)) ||
      (studioTypeString === 'Broadcast' && this._prevVisibilityState && (isIpad || isMobile()))
    ) {
      if (this._prevVisibilityState === 'hidden' && document.visibilityState === 'visible') {
        await swal({
          buttons: {},
          closeOnClickOutside: false,
          closeOnEsc: false,
          className: 'swal-custom-content',
          content: (
            <CustomMessage
              title='Connection Error'
              buttonLabel='Reload'
              onClick={() => swal.close()}
              body={`Your connection was interrupted. Please keep the window in the foreground to prevent future interruptions. Press 'reload' now to re-initialize your connection.`}
              showLogo={studioTypeString === 'Pando'}
            />
          ),
        });
        reloadBrowser(true);
      } else if (this._prevVisibilityState === 'visible' && document.visibilityState === 'hidden') {
        SocketClient.isBackgroundIpad = true;
        SocketClient.socket.disconnect();
      }
    }
    this._prevVisibilityState = document.visibilityState;
  };

  render() {
    if (this.state.connectionAllowed !== true)
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <Spinner />
        </div>
      );
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path='/login' component={Login} />
            <Route path='/system-check' component={Onboarding} />
            <Route path='/connect' component={Connect} />
            <Route path='/breakout-room' component={TwilioBreakoutRoom} />
            <Route path='/load-test-client' component={LoadTestClient} />
            <Route path='/wall' component={Wall} />
            <Route path='/off-wall-system-check' component={OffWallSystemCheck} />
            <Route path='/broadcast-system-check' component={BroadcastSystemCheck} />
            <Route path='/browser-check' component={BrowserCheckPage} />
            <Route path='/archive-player' component={ArchivePlayer} />
            <Route path='/venue-login' component={VenueLogin} />
          </Switch>
          <ToastContainer />
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
