import React, { PureComponent } from 'react';
import swal from '@sweetalert/with-react';
import PresentationArea from './PresentationArea';
import SocketClient from '../../utils/socket-client';
import { isSafari } from '../../utils/browser-util';
import TimeUtil from '../../utils/time-util';

export default class ScreenShareRoom extends PureComponent {
  state = {
    screenTrack: undefined,
    showPresentation: false,
  };

  async componentDidUpdate(prevProps) {
    const { isSharingScreen, currentHost, isHost, currentPresenter } = this.props;
    if (prevProps.isSharingScreen !== isSharingScreen) {
      if (isSharingScreen === true) {
        if (isSafari()) {
          try {
            this._displayMediaStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
          } catch (error) {
            console.log(error);
          }
        }
        const { showPresentation, screenTrack } = this.state;
        if (showPresentation === true && screenTrack && !isSharingScreen) {
          let uuid;
          if (isHost) {
            uuid = currentPresenter.display.split('/')[1];
          } else {
            uuid = currentHost;
          }
          SocketClient.emit('message', { roomName: this.props.opaqueId, message: { type: 'stop-presenter-screen-share', uuid } });
          await TimeUtil.sleep(500);
        }
        const myusername = window.Janus.randomString(12);
        const register = { request: 'join', room: this.props.screenShareRoomId, ptype: 'publisher', display: myusername };
        this._screenShareRoom.send({ message: register });
      } else {
        if (this._displayMediaStream) {
          this._displayMediaStream.getTracks().forEach((track) => {
            track.stop();
          });
        }
        this._screenShareRoom.send({ message: { request: 'unpublish' } });
        SocketClient.emit('message', { roomName: this.props.opaqueId, message: { type: 'stop-screen-share', room: this.props.screenShareRoomId } });
        this.setState({ screenTrack: undefined, showPresentation: false });
        this.props.onStopScreenShare && this.props.onStopScreenShare();
      }
    }
  }

  _createScreeshareRoom = () => {
    this._screenShareRoom.send({
      message: { request: 'exists', room: this.props.screenShareRoomId },
      success: (response) => {
        const { videoroom, exists } = response;
        if (videoroom === 'success' && exists !== true) {
          this._screenShareRoom.send({
            message: {
              request: 'create',
              room: this.props.screenShareRoomId,
              description: 'screenshare',
              videocodec: 'vp8',
              bitrate: 384000,
              publishers: 1,
              is_private: false,
            },
          });
        }
      },
    });
  };

  componentWillUnmount() {
    this._unmounted = true;

    const { screenTrack } = this.state;
    if (screenTrack) {
      screenTrack.getTracks().forEach((t) => t.stop());
    }
    if (this._displayMediaStream) {
      this._displayMediaStream.getTracks().forEach((t) => t.stop());
    }

    if (this._screenShareRoom && !this._screenShareRoom.detached) {
      this._screenShareRoom.send({
        message: { request: 'leave' },
        success: () => {
          if (!this._screenShareRoom.detached) {
            this._screenShareRoom.detach();
          }
        },
        error: (error) => {
          console.log('An error occured leaving the room', error);
        },
      });
    }

    SocketClient.removeListener('message');
  }

  componentDidMount() {
    const { janusInstance, isHost, isTechSupportRoom, screenShareRoomId, opaqueId } = this.props;

    SocketClient.addListener('message', (data) => {
      const { type } = data;
      if (type === 'start-screen-share') {
        const username = window.Janus.randomString(12);
        const register = { request: 'join', room: data.room, ptype: 'publisher', display: username };
        this._screenShareRoom.send({ message: register });
        this.setState({ showPresentation: true });
      } else if (type === 'stop-screen-share') {
        this.setState({ showPresentation: false, screenTrack: undefined });
      } else if (type === 'stop-presenter-screen-share') {
        if (this.props.credentials.uuid === data.uuid) {
          this._stopScreenShare();
        }
      }
    });

    janusInstance.attach({
      plugin: 'janus.plugin.videoroom',
      opaqueId,
      success: async (handle) => {
        this._screenShareRoom = handle;
        if (isHost || isTechSupportRoom) {
          this._createScreeshareRoom();
        } else {
          this._screenShareRoom.send({
            message: { request: 'listparticipants', room: screenShareRoomId },
            success: (result) => {
              // If screenshare has already started when joining, join the screenshare room
              if (result.participants && result.participants.length > 0) {
                const publisher = result.participants.find((p) => p.publisher);
                this._connectToScreenShare({ ...publisher, room: screenShareRoomId });
              }
            },
          });
        }
      },
      error: (error) => {
        console.error('Error attaching plugin - screenshare', error);
      },
      mediaState: (medium, on) => {
        console.log('mediaState Event:', medium, on);
      },
      onmessage: (msg, jsep) => {
        const event = msg.videoroom;
        if (event) {
          if (event === 'joined') {
            if (this.props.isSharingScreen === true) {
              this._configureScreenShare();
            } else {
              if (msg.publishers) {
                for (let p in msg.publishers) {
                  const publisher = msg.publishers[p];
                  this._connectToScreenShare({ ...publisher, room: msg.room });
                }
              }
            }
          } else if (event === 'event') {
            if (msg.publishers) {
              for (let p in msg.publishers) {
                const publisher = msg.publishers[p];
                this._connectToScreenShare({ ...publisher, room: msg.room });
              }
            } else if (msg.error && msg.error_code === 425) {
              if (this.props.isSharingScreen === true) {
                this._configureScreenShare();
              }
            }
          }
        }
        if (jsep !== undefined && jsep !== null) {
          this._screenShareRoom.handleRemoteJsep({ jsep: jsep });
        }
      },
      onlocalstream: (stream) => {
        if (!this._unmounted) {
          this.setState({ screenTrack: stream });
          stream.getVideoTracks()[0].onended = () => {
            console.log('Local stream ended. Stopping screenshare.');
            this._screenShareRoom.send({ message: { request: 'unpublish' } });
            SocketClient.emit('message', { roomName: this.props.opaqueId, message: { type: 'stop-screen-share', room: screenShareRoomId } });
            this.setState({ screenTrack: undefined, isSharingScreen: false });
            this.props.onStopScreenShare && this.props.onStopScreenShare();
          };
        }
      },
      oncleanup: () => {
        if (!this._unmounted) {
          const { screenTrack } = this.state;
          if (screenTrack) {
            screenTrack.getTracks().forEach((t) => t.stop());
          }
          if (this._displayMediaStream) {
            this._displayMediaStream.getTracks().forEach((t) => t.stop());
          }
          this.setState({ screenTrack: undefined, showPresentation: false });
          this.props.onStopScreenShare && this.props.onStopScreenShare();
        }
      },
    });
  }

  _stopScreenShare = () => {
    this._screenShareRoom.send({ message: { request: 'unpublish' } });
    SocketClient.emit('message', { roomName: this.props.opaqueId, message: { type: 'stop-screen-share', room: this.props.screenShareRoomId } });
    this.setState({ screenTrack: undefined, showPresentation: false }, () => {
      this.props.onStopScreenShare && this.props.onStopScreenShare();
    });
  };

  _configureScreenShare = () => {
    let options = { media: { video: 'screen', audioSend: false, videoRecv: false } }; // Screen sharing Publishers are sendonly
    if (isSafari()) {
      // TODO - 720p for screenshare
      options = { stream: this._displayMediaStream };
    }

    this._screenShareRoom.createOffer({
      ...options,
      success: (jsep) => {
        const publish = { request: 'configure', audio: true, video: true };
        this._screenShareRoom.send({ message: publish, jsep: jsep });
        SocketClient.emit('message', { roomName: this.props.opaqueId, message: { type: 'start-screen-share', room: this.props.screenShareRoomId } });
        this.setState({ showPresentation: true });
        this.props.onStartScreenShare && this.props.onStartScreenShare();
      },
      error: async (error) => {
        console.error('WebRTC error:', error);
        await swal({
          title: 'Screen Share Error',
          text: error.message,
        });

        this.setState({ screenTrack: undefined, showPresentation: false }, () => {
          this.props.onStopScreenShare && this.props.onStopScreenShare();
        });
      },
    });
  };

  _connectToScreenShare = (p) => {
    let remoteFeed;
    this.props.janusInstance.attach({
      plugin: 'janus.plugin.videoroom',
      opaqueId: this.props.opaqueId,
      success: (pluginHandle) => {
        remoteFeed = pluginHandle;
        const listen = { request: 'join', room: p.room, ptype: 'listener', feed: p.id };
        remoteFeed.send({ message: listen });
      },
      error: (error) => {
        console.error('  -- Error attaching plugin...', error);
      },
      onmessage: (msg, jsep) => {
        if (jsep !== undefined && jsep !== null) {
          remoteFeed.createAnswer({
            jsep: jsep,
            media: { audioSend: false, videoSend: false }, // We want recvonly audio/video
            success: function (jsep) {
              const body = { request: 'start', room: p.room };
              remoteFeed.send({ message: body, jsep: jsep });
            },
            error: function (error) {
              console.error('WebRTC error:', error);
            },
          });
        }
      },
      onremotestream: (stream) => {
        if (!this._unmounted) {
          this.setState({ screenTrack: stream, showPresentation: true });
          this.props.onStartScreenShare && this.props.onStartScreenShare();
        }
      },
      oncleanup: () => {
        if (!this._unmounted) {
          const { screenTrack } = this.state;
          if (screenTrack) {
            screenTrack.getTracks().forEach((t) => t.stop());
          }
          this.setState({ screenTrack: undefined, showPresentation: false });
          this.props.onStopScreenShare && this.props.onStopScreenShare();
        }
      },
    });
  };

  render() {
    const { hideSideBar, remountVideo, afterRemount } = this.props;
    const { showPresentation, screenTrack } = this.state;
    return showPresentation === true && screenTrack ? (
      <PresentationArea screenTrack={screenTrack} hideSideBar={hideSideBar} remountVideo={remountVideo} afterRemount={afterRemount} />
    ) : null;
  }
}
