import React from 'react';
import AudioVideoIcon from '../../assets/audio_and_video_settings.svg';
import Button from '../Button';
import './Messages.css';

function ConfirmPauseMessage({ onAccept, onCancel }) {
  return (
    <div className='vertical-message-box'>
      <div style={{ margin: '0 30px' }}>
        <div style={{ color: 'white', fontSize: 22, fontWeight: 600 }}>Pause Video and Mute Audio</div>
        <img src={AudioVideoIcon} style={{ height: 210 }} alt='audio and video settings' />
        <p style={{ color: 'rgb(187 187 187)', fontSize: 14, margin: 0 }}>
          Proceeding will pause your video and replace your image on the Pando Wall with your picture or the image that you chose upon logging in and it will
          mute your microphone. Your participation and interaction is important to the meeting's success, so please use this functionality only when necessary.
        </p>
        <div style={{ marginTop: 30 }}>
          <Button
            text='Yes, pause my video and mute my audio now'
            type='primary'
            onClick={onAccept}
            containerStyle={{ width: '100%', margin: 0, marginBottom: 15, fontSize: 16 }}
          />
          <Button text='No, take me back to the meeting' type='secondary' onClick={onCancel} containerStyle={{ width: '100%', margin: 0, fontSize: 16 }} />
        </div>
      </div>
    </div>
  );
}

export default ConfirmPauseMessage;
