import React, { useState, useEffect, useRef } from 'react';
import { throttle } from 'throttle-debounce';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import ChatNotification from './Chat/ChatNotification';
import attendeesGreenIcon from '../../assets/new_icons/attendees_green.svg';
import attendeesIcon from '../../assets/new_icons/attendees.svg';
import chatGreenIcon from '../../assets/new_icons/chat_green.svg';
import chatIcon from '../../assets/new_icons/chat.svg';
import questionsIcon from '../../assets/new_icons/icon-questions.svg';
import questionsGreenIcon from '../../assets/new_icons/icon-questions-green.svg';
import notesIcon from '../../assets/new_icons/icon-notes.svg';
import notesGreenIcon from '../../assets/new_icons/icon-notes-green.svg';
import SocketClient from '../../utils/socket-client';
import { post } from '../../services/api';
import { store } from '../../store';
import { setIsOffWallAttendeeListOpen } from '../../store/actions';
import {
  EVENT_SUPPORT,
  WALL_PARTICIPANT,
  WALL_PRESENTER,
  OFF_WALL_ADMIN,
  OFF_WALL_PARTICIPANT,
  OFF_WALL_ROLES,
  VENUE_PARTICIPANT,
  VENUE_OFF_WALL,
  VENUE_ON_WALL,
} from '../../utils/user-util';
import EventSupportAttendeeList from './AttendeeList/EventSupportAttendeeList';
import AttendeeList from './AttendeeList/AttendeeList';
import OffWallAttendeeList from './OffWall/OffWallAttendeeList.js';
import Chat, { GENERAL_CHAT_TAB } from './Chat/Chat';
import QNAWidget from './QNAWidget';
import Notes from './Notes';
import HtmlWidget from '../../common/HtmlWidget';
import { isMobile } from '../../utils/browser-util';

const ATTENDEES_TAB = 1;
const CHAT_TAB = 2;
const QNA_TAB = 3;
const NOTES_TAB = 4;
const failStatuses = ['COMPLETED', 'ARCHIVED', 'NOT_CONNECTED', 'REMOVED'];
const incomingChatMessages = [];

export default function Tabs({ event, participantData }) {
  const _chatRef = useRef();
  const _stateRef = useRef();
  const _isMobile = isMobile();

  const isOffWallAttendeeListOpen = useSelector((state) => state.app.isOffWallAttendeeListOpen);
  const isPando = event && event.eventType === 'PANDO';
  const hideAttendeeDetails = event && !!event.hideAttendeeDetails && participantData && ![EVENT_SUPPORT, OFF_WALL_ADMIN].includes(participantData.role);
  const isVenueParticipant = participantData && participantData.role === VENUE_PARTICIPANT;

  const [allowESChatforWallPresenters, setAllowESChatforWallPresenters] = useState(false);
  const [activeTab, setActiveTab] = useState(isPando && !isVenueParticipant ? ATTENDEES_TAB : _isMobile && !isVenueParticipant ? QNA_TAB : CHAT_TAB);
  const [attendees, setAttendees] = useState([]);
  const [selectedChatTab, setSelectedChatTab] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const [latestMessage, setLatestMessage] = useState(0);
  const [showChat, setShowChat] = useState(false);
  const [showQNAWidget, setShowQNAWidget] = useState(false);
  const [showNotes, setShowNotes] = useState(false);

  const _getAttendeeList = async (roles) => {
    try {
      const response = await post('/participant/list', { event, roles });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setShowChat(event.enableChat);
    setShowQNAWidget(event.enableQNAWidget);
    setShowNotes(event.enableNotes);

    if (isPando) {
      setAllowESChatforWallPresenters(event.allowEventSupportChatForPresenters);
      let _roles = [];
      (async () => {
        if (
          participantData.role === EVENT_SUPPORT ||
          participantData.role === OFF_WALL_ADMIN ||
          (event.allowEventSupportChatForPresenters && participantData.role === WALL_PRESENTER)
        ) {
          _roles = [EVENT_SUPPORT, WALL_PRESENTER, WALL_PARTICIPANT, VENUE_OFF_WALL];
        } else if (
          participantData.role === WALL_PARTICIPANT ||
          participantData.role === OFF_WALL_PARTICIPANT ||
          participantData.role === VENUE_OFF_WALL ||
          participantData.role === VENUE_ON_WALL ||
          (!event.allowEventSupportChatForPresenters && participantData.role === WALL_PRESENTER)
        ) {
          _roles = [WALL_PARTICIPANT, WALL_PRESENTER];
        }
        const list = await _getAttendeeList(_roles);
        if (participantData.role === OFF_WALL_PARTICIPANT || participantData.role === OFF_WALL_ADMIN || participantData.role === VENUE_OFF_WALL) {
          const nameParts = participantData.name.split(' ');
          const firstName = nameParts[0];
          const lastName = nameParts.slice(1).join(' ');
          list.push({
            _id: participantData._id,
            uuid: participantData.uuid,
            role: participantData.role,
            reactionStatus: null,
            firstName,
            lastName,
          });
        }
        setAttendees(list);
      })();

      const _onWallParticipantsUpdate = (data, attendees) => {
        if (OFF_WALL_ROLES.includes(data.role) && data.uuid === participantData.uuid) {
          if (data.role !== participantData.role) {
            const index = attendees.findIndex((a) => a.uuid === data.uuid);
            attendees[index] = { ...data };
          }
          return;
        }
        const attendee = attendees.find((a) => a.uuid === data.uuid);
        if (
          attendee &&
          attendee.firstName === data.firstName &&
          attendee.lastName === data.lastName &&
          attendee.role === data.role &&
          attendee.status === data.status &&
          attendee.reactionStatus === data.reactionStatus &&
          attendee.onAirNum === data.onAirNum
        ) {
          return;
        }

        const index = attendees.findIndex((a) => a.uuid === data.uuid);
        if (failStatuses.includes(data.status) || OFF_WALL_ROLES.includes(data.role)) {
          if (index !== -1) {
            attendees.splice(index, 1);
          }
        } else {
          if (index !== -1) {
            attendees[index] = { ...data };
          } else {
            attendees.push(data);
          }
        }
      };

      SocketClient.joinRoom(`event-${event._id}:on-wall-participants`);
      SocketClient.addListener('on-wall-list-update', (participants) => {
        let { attendees } = _stateRef.current;
        participants.forEach((p) => {
          _onWallParticipantsUpdate(p, attendees);
        });
        setAttendees([...attendees]);
      });

      SocketClient.addListener('clear-all-audience-responses', () => {
        setAttendees((_attendees) => {
          return _attendees.map((a) => {
            a.reactionStatus = null;
            return a;
          });
        });
      });

      SocketClient.addListener('allow-event-support-chat-for-presenters', ({ value }) => {
        setAllowESChatforWallPresenters(value);
      });
    } else if (!_isMobile) {
      [
        { enabled: event.enableChat, tab: CHAT_TAB },
        { enabled: event.enableQNAWidget, tab: QNA_TAB },
        { enabled: event.enableNotes, tab: NOTES_TAB },
      ].every(({ enabled, tab }) => {
        if (enabled) {
          setActiveTab(tab);
          return false;
        }
        return true;
      });
    }

    const _onChatMessage = () => {
      const { notificationCount, selectedChatTab, allowESChatforWallPresenters, activeTab, participantData } = _stateRef.current;
      const _latestMessage = incomingChatMessages[incomingChatMessages.length - 1];
      setLatestMessage(_latestMessage);
      _chatRef.current.addMessages(incomingChatMessages.filter((msg) => !msg.rendered));

      for (let i = 0; i < incomingChatMessages.length; i++) {
        const message = incomingChatMessages[i];
        if (message.rendered) continue;
        message.rendered = true;

        let highlightTab = false;
        const newMessageTabId = message.sendTo === 'PARTICIPANT' ? message.fromParticipant._id : GENERAL_CHAT_TAB.id;
        const isAdmin =
          participantData.role === EVENT_SUPPORT ||
          participantData.role === OFF_WALL_ADMIN ||
          (allowESChatforWallPresenters && participantData.role === WALL_PRESENTER);

        if (selectedChatTab && selectedChatTab.id !== newMessageTabId) {
          if (message.sendTo !== 'ADMINS') {
            highlightTab = true;
          } else if (message.sendTo === 'ADMINS' && isAdmin) {
            highlightTab = true;
          }
        }

        if (highlightTab) {
          _chatRef.current.highlightChatTab(message);
        }
        if (
          (highlightTab || (message.sendTo !== 'ADMINS' && activeTab !== CHAT_TAB) || (message.sendTo === 'ADMINS' && isAdmin && activeTab !== CHAT_TAB)) &&
          notificationCount === 0
        ) {
          toast(<ChatNotification />, {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            onOpen: () => {
              setNotificationCount(1);
            },
            onClose: () => {
              setNotificationCount(0);
            },
            onClick: () => {
              setActiveTab(CHAT_TAB);
              const { latestMessage } = _stateRef.current;
              _chatRef.current.openChatTab(latestMessage);
            },
          });
        }
      }
    };

    if (!_isMobile || isVenueParticipant) {
      const throttledOnChatMessage = throttle(250, _onChatMessage);

      SocketClient.addListener('chat-message', ({ message }) => {
        const myUuid = localStorage.getItem('uuid');
        const notSentByMe = myUuid && myUuid !== message.fromParticipant._id;
        if (notSentByMe) {
          incomingChatMessages.push(message);
          throttledOnChatMessage();
        }
      });

      SocketClient.addListener('chat-message-update', ({ message }) => {
        _chatRef.current.updateMessage(message);
      });

      SocketClient.addListener('clear-text-chat', () => {
        _chatRef.current.clearTextChat();
      });
    }

    return () => {
      if (isPando) SocketClient.leaveRoom(`event-${event._id}:on-wall-participants`);
    };
  }, []);

  useEffect(() => {
    _stateRef.current = { notificationCount, selectedChatTab, allowESChatforWallPresenters, activeTab, latestMessage, attendees, participantData };
  });

  const _onStartPrivateChat = (item) => {
    _chatRef.current.startPrivateChat(item);
    store.dispatch(setIsOffWallAttendeeListOpen(false));
    setActiveTab(CHAT_TAB);
  };

  const _toggleOffWallAttendeeListModal = () => {
    store.dispatch(setIsOffWallAttendeeListOpen(!isOffWallAttendeeListOpen));
  };

  const isOffWallUser = [OFF_WALL_ADMIN, OFF_WALL_PARTICIPANT, VENUE_OFF_WALL].includes(participantData.role);
  const htmlWidget = participantData && participantData.event && participantData.event.htmlWidget;
  const showHtmlWidget = htmlWidget && htmlWidget.enabled;

  let tabsHeight;
  if (isVenueParticipant) {
    tabsHeight = 'calc(100% - 92px)';
  } else if (_isMobile && showHtmlWidget) {
    tabsHeight = '30%';
  } else {
    tabsHeight = isOffWallUser ? '100%' : 'calc(100% - 180px)';
  }

  return (
    <>
      {isPando && (
        <Modal
          isOpen={isOffWallAttendeeListOpen}
          onRequestClose={_toggleOffWallAttendeeListModal}
          contentLabel='My dialog'
          className='mymodal'
          overlayClassName='myoverlay'
          closeTimeoutMS={100}
        >
          <OffWallAttendeeList onClose={_toggleOffWallAttendeeListModal} onStartPrivateChat={_onStartPrivateChat} />
        </Modal>
      )}
      {_isMobile && !isVenueParticipant && showHtmlWidget && (
        <div style={{ position: 'relative', height: showQNAWidget ? '70%' : '100%' }}>
          <HtmlWidget
            height={`${htmlWidget.height}${htmlWidget.heightUnits}`}
            width={`${htmlWidget.width}${htmlWidget.widthUnits}`}
            backgroundColor={htmlWidget.bgColor}
            pinTo={htmlWidget.pinTo}
            spacing={htmlWidget.spacing}
            html={htmlWidget.html}
          />
        </div>
      )}
      <div style={{ position: 'relative', height: tabsHeight, marginBottom: isVenueParticipant ? 20 : 0 }}>
        <div className='tabs' style={{ display: _isMobile && !isVenueParticipant ? 'none' : 'flex' }}>
          {isPando && !isVenueParticipant && (
            <div className='tab' onClick={() => setActiveTab(ATTENDEES_TAB)}>
              <img src={activeTab === ATTENDEES_TAB ? attendeesGreenIcon : attendeesIcon} />
              <span className={'tooltip bottom'}>Attendees</span>
            </div>
          )}
          {showChat && (
            <div className='tab' onClick={() => setActiveTab(CHAT_TAB)}>
              <img src={activeTab === CHAT_TAB ? chatGreenIcon : chatIcon} />
              <span className={'tooltip bottom'}>Chat</span>
            </div>
          )}
          {showQNAWidget && (
            <div className='tab' onClick={() => setActiveTab(QNA_TAB)}>
              <img src={activeTab === QNA_TAB ? questionsGreenIcon : questionsIcon} />
              <span className={'tooltip bottom'}>Q&A</span>
            </div>
          )}
          {showNotes && !isVenueParticipant && (
            <div className='tab' onClick={() => setActiveTab(NOTES_TAB)}>
              <img src={activeTab === NOTES_TAB ? notesGreenIcon : notesIcon} />
              <span className={'tooltip bottom'}>Notes</span>
            </div>
          )}
        </div>
        <div className='tab-content' style={{ top: _isMobile && !isVenueParticipant ? 0 : 55 }}>
          {isPando && !isVenueParticipant && (
            <>
              {participantData.role === EVENT_SUPPORT ||
              participantData.role === OFF_WALL_ADMIN ||
              (allowESChatforWallPresenters && participantData.role === WALL_PRESENTER) ? (
                <EventSupportAttendeeList
                  event={event}
                  attendees={attendees}
                  show={activeTab === ATTENDEES_TAB}
                  onStartPrivateChat={_onStartPrivateChat}
                  isOffWallUser={isOffWallUser}
                  hideAttendeeDetails={hideAttendeeDetails}
                />
              ) : (
                <AttendeeList
                  event={event}
                  attendees={attendees}
                  show={activeTab === ATTENDEES_TAB}
                  role={participantData.role}
                  isOffWallUser={isOffWallUser}
                  hideAttendeeDetails={hideAttendeeDetails}
                />
              )}
            </>
          )}
          {showNotes && <Notes event={event} participantData={participantData} show={activeTab === NOTES_TAB} />}
          {showQNAWidget && <QNAWidget event={event} participant={participantData} show={activeTab === QNA_TAB} />}
          {showChat && (
            <Chat
              ref={_chatRef}
              isPando={isPando}
              event={event}
              role={participantData.role}
              show={activeTab === CHAT_TAB}
              allowESChatforWallPresenters={allowESChatforWallPresenters}
              onChatTabSelected={(tab) => setSelectedChatTab(tab)}
              hideAttendeeDetails={hideAttendeeDetails}
              isVenueParticipant={isVenueParticipant}
            />
          )}
        </div>
      </div>
    </>
  );
}
