import React, { useEffect, useState } from 'react';
import SpeedTest from '../../common/SpeedTest';
import MediaDeviceUtil from '../../utils/media-device-util';
import TimeUtil from '../../utils/time-util';
import { WALL_PRESENTER, OFF_WALL_PARTICIPANT, OFF_WALL_ADMIN, OBSERVER } from '../../utils/user-util';

export default function BandwidthTest({ title, onComplete, onSuccess, onUpdateProgress, role }) {
  const [validSpeedConnection, setValidSpeedConnection] = useState(null);

  const _onSpeedTestSuccess = (bandwidth) => {
    onUpdateProgress({ bandwidth });
    setValidSpeedConnection(true);
  };

  useEffect(() => {
    (async () => {
      if (validSpeedConnection === true) {
        await TimeUtil.sleep(3000);
        if (role === WALL_PRESENTER) {
          onSuccess && onSuccess();
        } else {
          onComplete && onComplete();
        }
      } else if (validSpeedConnection === false) {
        onComplete && onComplete(false);
      }
    })();
  }, [validSpeedConnection]);

  return (
    <div style={{ width: '100%' }}>
      <h3>{validSpeedConnection === true ? title : title}</h3>
      <div>
        <table cellSpacing={3} style={{ width: '100%' }}>
          <tbody>
            <tr style={{ backgroundColor: 'rgb( 31, 43, 55)' }}>
              <td style={{ verticalAlign: 'top', padding: 10, fontSize: 12 }}>
                {MediaDeviceUtil.notesData && (
                  <SpeedTest
                    uuid={MediaDeviceUtil.notesData.uuid}
                    eventId={MediaDeviceUtil.notesData.event._id}
                    studioId={MediaDeviceUtil.notesData.event.studioWall}
                    socketServerUrl={MediaDeviceUtil.notesData.event.socketServerUrl}
                    isOffWallParticipant={[OFF_WALL_PARTICIPANT, OFF_WALL_ADMIN, OBSERVER].includes(role)}
                    hideContinueButton={true}
                    minBandwidth={1.5}
                    speedTests={['2mb', '5mb']}
                    onSuccess={_onSpeedTestSuccess}
                    onFailure={() => setValidSpeedConnection(false)}
                    notesData={MediaDeviceUtil.notesData}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
