import axios from 'axios';
import Storage from '../utils/storage';

const headers = {
  'Content-Type': 'application/json',
  'x-requested-with': 'XMLHttpRequest',
};

const { REACT_APP_BACKEND_URL } = process.env;
const API_BASE_URL = REACT_APP_BACKEND_URL;

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30000,
  headers,
});

function call(URL, data = {}, method) {
  return axiosInstance({
    method,
    url: URL,
    data,
  });
}

export function setToken(token) {
  axiosInstance.defaults.headers.common.Authorization = `bearer ${token}`;
}

export function setStudioId(studioId, save = true) {
  if (save) {
    const localStorage = Storage.getLocalStorage();
    localStorage.setItem('studioId', studioId);
  }
  axiosInstance.defaults.headers.common['x-pando-studio-id'] = `${studioId}`;
}

export function get(URL, data, responseType = 'json') {
  return axiosInstance({
    method: 'GET',
    url: URL,
    params: data,
    responseType,
  });
}

export function post(URL, data) {
  return call(URL, data, 'POST');
}

export function put(URL, data) {
  return call(URL, data, 'PUT');
}

export function del(URL) {
  return call(URL, null, 'DELETE');
}

export function postFormData(URL, formData) {
  const config = {
    onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axios.post(`${API_BASE_URL}${URL}`, formData, config);
}

export function putFormData(URL, formData) {
  const config = {
    onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axios.put(`${API_BASE_URL}${URL}`, formData, config);
}
