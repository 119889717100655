import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { push, replace } from 'connected-react-router';
import PageVisibility from 'react-page-visibility';
import { store } from '../../store';
import SocketClient from '../../utils/socket-client';
import MediaDeviceUtil from '../../utils/media-device-util';
import RTCClient from '../../utils/rtc-client';
import { post, get, setStudioId, setToken, put } from '../../services/api';
import ConnectForm from './ConnectForm';
import InScreeningQueueMessage from '../../common/Messages/InScreeningQueue';
import InStudioQueueMessage from '../../common/Messages/InStudioQueue';
import ShowEndedMessage from '../../common/Messages/ShowEnded';
import RemovedMessage from '../../common/Messages/RemovedMessage';
import AlreadyConnectedMessage from '../../common/Messages/AlreadyConnectedMessage';
import WaitingMessage from '../../common/Messages/Waiting';
import EndEventMessage from '../../common/Messages/EndEventMessage';
import Storage from '../../utils/storage';
import Deferred from '../../utils/deferred';
import { reloadBrowser, isIpad, isSafari, isMobile, redirect } from '../../utils/browser-util';
import swal from '@sweetalert/with-react';
import ParticipantActions from './ParticipantActions';
import {
  EVENT_SUPPORT,
  WALL_PRESENTER,
  OFF_WALL_ADMIN,
  OFF_WALL_PARTICIPANT,
  OBSERVER,
  OFF_WALL_ROLES,
  ON_WALL_ROLES,
  IN_SCREENING,
  IN_SCREENING_QUEUE,
  IN_STUDIO_QUEUE,
  IN_BREAKOUT_ROOM,
  ON_WALL,
  ON_AIR,
  REMOVED,
  ARCHIVED,
  ALREADY_CONNECTED,
  COMPLETED,
  ONBOARDING,
  WAITING_FOR_SERVERS,
  VENUE_PARTICIPANT,
  VENUE_OFF_WALL,
} from '../../utils/user-util';
import Logo from '../../assets/pando-logo.png';
import Spinner from '../../common/Spinner';
import OffWallParticipantModal from './OffWallParticipantModal';
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut';
import addReportingEvent from '../../utils/reporting-event';
import OffWallStream from './OffWallStream';
import BroadcastStream from './BroadcastStream';
import HtmlWidget from '../../common/HtmlWidget';
import StringUtil from '../../utils/string-util';
import Heartbeat from '../../utils/heartbeat';
import ImageCache from '../../utils/image-cache';
import HtmlContainer from '../../common/HtmlContainer';
import './ConnectScreen.css';

const localStorage = Storage.getLocalStorage();
const photoTakenDeferred = new Deferred();

let rtcClient = new RTCClient();

export default function Connect() {
  const urlParams = new URLSearchParams(window.location.search);
  const autoConnect = urlParams.get('auto-connect');
  const isOffWallTestClient = urlParams.get('isOffWallTestClient');
  const isPreview = urlParams.get('isPreview');

  const formRef = useRef();
  const _stateRef = useRef(null);
  const _remoteAudioUnmuted = useRef(null);

  const [participantData, setParticipantData] = useState(null);
  const [connected, setConnected] = useState(false);
  const [status, setStatus] = useState(null);
  const [queuePosition, setQueuePosition] = useState(0);
  const [queueSize, setQueueSize] = useState(0);
  const [showId, setShowId] = useState(true);
  const [photoTaken, setPhotoTaken] = useState(autoConnect ? true : false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [sendFeedToStudioQueue, setSendFeedToStudioQueue] = useState(false);
  const [clearResponse, setClearResponse] = useState(false);
  const [enablePPtControl, setEnablePPTControl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOffWall, setIsOffWall] = useState(null);
  const [isObserver, setIsObserver] = useState(null);
  const [isVenueParticipant, setIsVenueParticipant] = useState(null);
  const [enableShowWall, setEnableShowWall] = useState(false);
  const [enableShareScreen, setEnableShareScreen] = useState(false);
  const [platformDetails, setPlatformDetails] = useState(null);
  const [poll, setPoll] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isOnMeetingBreak, setIsOnMeetingBreak] = useState(null);
  const [liveEventId, setLiveEventId] = useState(-1);
  const [offWallHlsStreamUrl, setOffWallHlsStreamUrl] = useState(null);
  const [pptControllerURL, setPptControllerURL] = useState(null);
  const [isOffWallReady, setIsOffWallReady] = useState(false);
  const [eventParticipantId, setEventParticipantId] = useState(null);
  const [isBroadcast, setIsBroadcast] = useState(false);
  const [showReactions, setShowReactions] = useState(false);
  const [rehearsalModeEnabled, setRehearsalModeEnabled] = useState(true);
  const [isEventEnded, setIsEventEnded] = useState(false);

  const _onEscape = (event) => {
    if (!document.fullscreenElement) {
      _exitFullScreen(event);
    }
  };

  const _exitFullScreen = (event) => {
    event.stopPropagation();
    document.removeEventListener('keydown', _exitFullScreen);
    if (isSafari()) {
      document.removeEventListener('webkitfullscreenchange', _onEscape);
    } else {
      document.removeEventListener('fullscreenchange', _onEscape);
    }
    const { participantData } = _stateRef.current;
    let remoteVideo = ON_WALL_ROLES.includes(participantData.role) ? document.getElementById('remote-video') : document.getElementById('off-wall-player');
    if (remoteVideo && document.fullscreenElement) {
      if (isSafari()) {
        document.webkitExitFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  };

  useKeyboardShortcut(['control', 'alt', 'f'], () => {
    const { status, participantData } = _stateRef.current;
    let remoteVideo = ON_WALL_ROLES.includes(participantData.role) ? document.getElementById('remote-video') : document.getElementById('off-wall-player');

    if ([IN_STUDIO_QUEUE, ON_WALL, ON_AIR].includes(status) && remoteVideo && !document.fullscreenElement) {
      document.addEventListener('keydown', _exitFullScreen);
      if (isSafari()) {
        document.addEventListener('webkitfullscreenchange', _onEscape);
        remoteVideo.webkitRequestFullscreen();
      } else {
        document.addEventListener('fullscreenchange', _onEscape);
        remoteVideo.requestFullscreen();
      }
    }
  });

  // ComponentDidUpdate - Monitor Everything
  useEffect(() => {
    _stateRef.current = { status, participantData };
  });

  // ComponentDidMount - Monitor Nothing
  useEffect(() => {
    const {
      router: {
        location: { state: participantInfo },
      },
    } = store.getState();
    if (!participantInfo) {
      console.log('No participant info found. Redirecting to login page.');
      const { origin, search } = window.location;
      const loginRoute = !!sessionStorage.getItem('venue-login') ? '/venue-login' : '/login';
      const pathname = window.location.pathname.replace('/connect', loginRoute);
      window.location.href = `${origin}${pathname}${search}`;
    }
    console.log('connect participantInfo: ', participantInfo);
    const eventId = participantInfo.event ? participantInfo.event._id : null;

    async function load() {
      const skipAlreadyConnected = localStorage.getItem('skipAlreadyConnected');
      const skipPhotoModal = localStorage.getItem('skipPhotoModal');
      const participantUuid = localStorage.getItem('uuid');
      const event = localStorage.getItem('participantEvent');

      let photoTaken = localStorage.getItem('photoTaken');
      const loginReportingData = localStorage.getItem('loginReportingData');
      const audioInput = localStorage.getItem('audioInput');
      const audioOutput = localStorage.getItem('audioOutput');
      const videoInput = localStorage.getItem('videoInput');
      const pausedState = localStorage.getItem(`paused-${eventId}`);
      const audioEnabled = localStorage.getItem('breakout-room-audio-enabled');
      const videoEnabled = localStorage.getItem('breakout-room-video-enabled');

      localStorage.clear();

      if (loginReportingData || participantUuid || event) {
        localStorage.setItem('uuid', participantUuid);
        localStorage.setItem('participantEvent', event);
        localStorage.setItem('loginReportingData', loginReportingData);
      }

      if (skipAlreadyConnected) localStorage.setItem('skipAlreadyConnected', 'true');
      if (audioInput) localStorage.setItem('audioInput', audioInput);
      if (audioOutput) localStorage.setItem('audioOutput', audioOutput);
      if (videoInput) localStorage.setItem('videoInput', videoInput);
      if (pausedState) localStorage.setItem(`paused-${eventId}`, pausedState);
      if (audioEnabled) localStorage.setItem('breakout-room-audio-enabled', audioEnabled);
      if (videoEnabled) localStorage.setItem('breakout-room-video-enabled', videoEnabled);
      if (skipPhotoModal) localStorage.setItem('skipPhotoModal', 'true');

      await ImageCache.preload(Logo);

      setStudioId(participantInfo.studioId);

      let eventResponse;
      try {
        eventResponse = await post('/event/eventForName', { name: participantInfo.showName });
      } catch (err) {
        swal({
          title: 'Event Not Found',
          text: `Please double check the event name "${participantInfo.showName}" and try again.`,
        });
        return;
      }
      const eventData = eventResponse.data;
      const isBroadcast = eventData.eventType === 'BROADCAST';
      if (isBroadcast) {
        document.title = 'Broadcast Stream';
      } else {
        document.title = 'Pando Stream';
      }
      setRehearsalModeEnabled(eventData.rehearsalMode);

      const isEventEnded = !!eventData.endTime;
      setIsEventEnded(isEventEnded);

      let participantRole = isBroadcast ? OFF_WALL_PARTICIPANT : participantInfo.role;
      if (!!participantInfo.erpId) {
        participantRole = participantInfo.role;
      }

      const { data } = await post('/participant/getUuid', {
        pandoV1UNID: participantInfo.UNID,
        erpId: participantInfo.erpId,
        eventId: eventData._id,
        firstName: participantInfo.firstName,
        lastName: participantInfo.lastName,
        username: participantInfo.userName,
        phone: participantInfo.phone,
        location: participantInfo.location,
        role: participantRole,
        metadata: participantInfo.metadata,
      });
      const { uuid, phone, status, location, token, platform, systemCheck, eventParticipantId } = data;

      if (systemCheck && systemCheck.photoTaken) {
        localStorage.setItem('photoTaken', 'true');
        photoTaken = true;
      } else {
        localStorage.removeItem('photoTaken');
        photoTaken = false;
      }
      setPhotoUrl(data.photoUrl);

      const role = data.role ? data.role : participantInfo.role;
      const isOffWall = OFF_WALL_ROLES.includes(role);
      const isVenueParticipant = role === VENUE_PARTICIPANT;
      setIsBroadcast(isBroadcast);
      setToken(token);
      setIsOffWall(isOffWall);
      setIsObserver(role === OBSERVER);
      setIsVenueParticipant(isVenueParticipant);
      setPptControllerURL(eventData.pptControllerURL);
      setShowReactions(eventData.enableReactions);

      if (isOffWall) {
        setOffWallHlsStreamUrl(eventData.offWallStreamUrl);
      }

      await formRef.current.setupSocketClient(eventData.socketServerUrl, uuid, eventData._id, participantInfo.studioId, token);

      localStorage.setItem('uuid', uuid);
      localStorage.setItem('participantEvent', JSON.stringify(eventData));
      localStorage.setItem('participantRole', role);

      const timerId = setInterval(async () => {
        const getRoleResponse = await get(`/participant/getRole?uuid=${uuid}&eventId=${eventData._id}`);
        const newRole = getRoleResponse.data;
        if (newRole !== role) {
          localStorage.setItem('participantRole', newRole);
          window.location.reload();
        }
      }, 5000);

      const mediaStream = new MediaStream();
      if (!isOffWall && !isBroadcast && !isVenueParticipant) {
        await Promise.all([MediaDeviceUtil.videoStreamInitialized.promise, MediaDeviceUtil.audioStreamInitialized.promise]);
        window.videoStream.getTracks().forEach((track) => {
          mediaStream.addTrack(track);
        });
        window.audioStream.getTracks().forEach((track) => {
          mediaStream.addTrack(track);
        });
      }

      if (isOffWall || autoConnect || photoTaken || isBroadcast || isVenueParticipant) {
        setPhotoTaken(true);
      } else {
        await photoTakenDeferred.promise;
      }

      clearInterval(timerId);

      if (isBroadcast && !SocketClient.socket.connected && !isPreview) {
        await new Promise((resolve) => {
          const intervalId = setInterval(() => {
            if (SocketClient.socket.connected) {
              clearInterval(intervalId);
              resolve();
            } else {
              setLiveEventId(0);
              setStatus(WAITING_FOR_SERVERS);
            }
          }, 1000);
        });
      }

      setLoading(false);
      const alreadyConnected = [IN_SCREENING_QUEUE, IN_STUDIO_QUEUE, ON_WALL, ON_AIR, IN_BREAKOUT_ROOM, IN_SCREENING].includes(status);
      if (alreadyConnected && skipAlreadyConnected !== 'true' && !autoConnect && !isPreview) {
        setStatus(ALREADY_CONNECTED);
        setPlatformDetails(platform);
        setLiveEventId(0);
        setEventParticipantId(eventParticipantId);
      } else {
        if (isEventEnded) {
          setLiveEventId(0);
        } else if (status !== REMOVED) {
          localStorage.removeItem('skipAlreadyConnected');
          const selectedAudioInput = localStorage.getItem('audioInput') ? JSON.parse(localStorage.getItem('audioInput')) : null;
          const selectedAudioOutput = localStorage.getItem('audioOutput') ? JSON.parse(localStorage.getItem('audioOutput')) : null;
          const selectedVideoInput = localStorage.getItem('videoInput') ? JSON.parse(localStorage.getItem('videoInput')) : null;

          let availableDevices = participantInfo.availableDevices;
          if (!availableDevices) {
            availableDevices = await MediaDeviceUtil.getAvailableDevices(false);
          }

          let supportedResolutions = participantInfo.supportedResolutions;
          if (!supportedResolutions) {
            supportedResolutions = MediaDeviceUtil.defaultResolutions;
          }

          let videoWidth = participantInfo.selectedWidth;
          let videoHeight = participantInfo.selectedHeight;

          if (!videoWidth || !videoHeight) {
            const resParts = supportedResolutions[0].split('x');
            videoWidth = parseInt(resParts[0]);
            videoHeight = parseInt(resParts[1]);
          }

          const { firstName, lastName, city, state } = data;
          const metadata = { ...data.metadata, firstName, lastName, city, state };

          await onSubmit(
            {
              uuid: uuid,
              name: data.name,
              country: participantInfo.country,
              videoInput: selectedVideoInput,
              width: videoWidth,
              height: videoHeight,
              audioInput: selectedAudioInput,
              audioOutput: selectedAudioOutput,
              pandoV1UNID: participantInfo.UNID,
              erpId: participantInfo.erpId,
              event: eventData,
              stream: mediaStream,
              availableDevices,
              supportedResolutions,
              location,
              phone,
              role,
              metadata,
            },
            { token, stream: mediaStream, isOffWall, isVenueParticipant },
          );
        } else {
          setStatus(status);
          setLiveEventId(0);
        }
      }
    }
    load();

    return () => {
      if (participantData) {
        SocketClient.leaveRoom(participantData.uuid);
        SocketClient.leaveRoom(`event-${participantData.event._id}`);
      }
      SocketClient.removeAllListeners();
      document.removeEventListener('keypress', _exitFullScreen);
    };
  }, []);

  async function _setPollForEventParticipant(activePoll, eventParticipantId) {
    if (!activePoll) return;
    const pollResponse = await get(`/poll/${activePoll}/show?eventParticipant=${eventParticipantId}`);
    if (pollResponse.data) {
      setPoll(pollResponse.data);
    }
  }

  async function onSubmit(data, { stream, token, isOffWall, isVenueParticipant }) {
    try {
      const { uuid, name, location, event, availableDevices, supportedResolutions, metadata } = data;

      const platform = MediaDeviceUtil.getDeviceDetails();

      const connectResult = await post('/participant/connect', { ...data, platform });
      const {
        screeningQueue,
        studioQueueSize,
        role,
        sendFeedToStudioQueue,
        enablePPTControl,
        enableShowWall,
        enableShareScreen,
        reactionStatus,
        activePoll,
        breakStarted,
      } = connectResult.data;
      const eventParticipantId = connectResult.data._id;

      setParticipantData({ ...data, _id: eventParticipantId, reactionStatus, token, activePoll, breakStarted, sendFeedToStudioQueue, location });
      const currentStatus = connectResult.data.status;

      setStatus(currentStatus);
      setShowId(event.name);

      if (
        activePoll &&
        ([ON_WALL, ON_AIR].includes(currentStatus) ||
          (currentStatus === IN_STUDIO_QUEUE && sendFeedToStudioQueue === true) ||
          OFF_WALL_ROLES.includes(role) ||
          role === VENUE_PARTICIPANT)
      ) {
        _setPollForEventParticipant(activePoll, eventParticipantId);
      }

      if (breakStarted && [IN_STUDIO_QUEUE, ON_WALL, ON_AIR].includes(currentStatus)) {
        _displayMeetingBreakMessage('start');
      }

      if (role === EVENT_SUPPORT || sendFeedToStudioQueue === true) {
        setSendFeedToStudioQueue(true);
      }
      setEnablePPTControl(enablePPTControl === true);
      setEnableShowWall(enableShowWall === true);
      setEnableShareScreen(enableShareScreen === true);

      setQueueSize(studioQueueSize);
      setQueuePosition(screeningQueue.indexOf(uuid) + 1);

      localStorage.setItem('uuid', uuid);
      localStorage.setItem('participantName', name);
      localStorage.setItem('participantLocation', location);
      localStorage.setItem('participantEvent', JSON.stringify(event));

      if (event.eventType === 'PANDO') {
        if (!isOffWall && !isVenueParticipant) {
          if (connectResult.data.breakoutRoomStarted && connectResult.data.status === IN_BREAKOUT_ROOM) {
            const breakoutRoomToken = connectResult.data.breakoutRoomToken;
            MediaDeviceUtil.resetPromises();
            const {
              router: {
                location: { state: participantInfo, search },
              },
            } = store.getState();
            toast.dismiss();
            store.dispatch(push({ pathname: `/breakout-room`, search: `${search}&accessToken=${breakoutRoomToken}`, state: participantInfo }));
          } else {
            _rtcClientConnect(stream, {
              uuid,
              name,
              location,
              availableDevices,
              supportedResolutions,
              eventParticipantId,
              metadata,
            });
          }
          // participant-status-update callback may not be plugged in just yet, so make sure audio muted is set properly based on current status
          if (currentStatus === ON_WALL || currentStatus === ON_AIR) {
            document.getElementById('remote-audio').muted = false;
          } else {
            document.getElementById('remote-audio').muted = true;
          }
        } else {
          const response = await get(`/event/getLiveEventId`);
          if (response.data) {
            setLiveEventId(response.data.liveEventId);
          }
          SocketClient.addListener('event-started', ({ eventId }) => {
            setLiveEventId(eventId);
          });
        }
      } else {
        setLiveEventId(event._id);
      }

      _setupSocketListeners(event.eventType);
    } catch (error) {
      console.log(error);
      setParticipantData(null);
    }
  }

  function _setupSocketListeners(eventType) {
    if (isOffWallTestClient) {
      SocketClient.addListener('load-test-client-action', async ({ action }) => {
        const r = Math.random() * 5000;
        await new Promise((resolve) => setTimeout(resolve, r));

        const { participantData } = _stateRef.current;
        const { uuid, event } = participantData;

        if (action === 'send-chat-message') {
          const message = StringUtil.generateRandomString(100, true);
          await post('/chatMessage', { event: event._id, fromParticipant: uuid, sendTo: 'ADMINS', message });
        } else if (action === 'give-reaction') {
          const reactions = ['RAISE_HAND', 'THUMBS_UP', 'THUMBS_DOWN'];
          const reaction = reactions[Math.floor(Math.random() * reactions.length)];
          console.log(`Sending reaction: ${reaction}`);
          if (reaction === 'RAISE_HAND') {
            SocketClient.emitClientAction('set-bg-color', { bgColor: '#1163e9' });
          } else if (reaction === 'THUMBS_UP') {
            SocketClient.emitClientAction('set-bg-color', { bgColor: '#277635' });
          } else if (reaction === 'THUMBS_DOWN') {
            SocketClient.emitClientAction('set-bg-color', { bgColor: '#BA1B2B' });
          }

          await post('/participant/reaction', { event: event._id, uuid, reaction });
        }
      });
    } else if (eventType === 'PANDO') {
      SocketClient.addListener('send-feed-to-studio-queue', (data) => {
        const { participantData } = _stateRef.current;
        setSendFeedToStudioQueue(participantData.role === EVENT_SUPPORT || data.sendFeedToStudioQueue);
      });

      SocketClient.addListener('rehearsal-mode-enabled', ({ rehearsalModeEnabled }) => {
        setRehearsalModeEnabled(rehearsalModeEnabled);
      });

      SocketClient.addListener('participant-queues', ({ screeningQueue, studioQueueSize }) => {
        const { participantData } = _stateRef.current;
        const { uuid } = participantData;
        if (screeningQueue.includes(uuid)) {
          const position = screeningQueue.indexOf(uuid) + 1;
          setQueuePosition(position);
        }
        setQueueSize(studioQueueSize);
      });

      SocketClient.addListener('participant-update', async ({ nextStatus, nextRole, eventId, sendFeedToStudioQueue }) => {
        const prevStatus = _stateRef.current.status;
        const { participantData } = _stateRef.current;
        const prevRole = participantData.role;
        console.log('participant-update', prevStatus, nextStatus, prevRole, nextRole);
        if (eventId === participantData.event._id) {
          if (prevStatus !== nextStatus) {
            if (nextStatus === IN_SCREENING) {
              setTimeout(() => {
                setStatus(nextStatus);
              }, 350);
            } else {
              setStatus(nextStatus);
            }

            if ([IN_SCREENING, IN_SCREENING_QUEUE].includes(prevStatus) && nextStatus === IN_STUDIO_QUEUE) {
              if (participantData.activePoll && sendFeedToStudioQueue) {
                _setPollForEventParticipant(participantData.activePoll, participantData._id);
              }
              if (participantData.breakStarted) {
                _displayMeetingBreakMessage('start');
              }
            } else if (nextStatus === IN_SCREENING_QUEUE) {
              setPoll(null);
            } else if (nextStatus === COMPLETED) {
              let eventData;
              try {
                const { data } = await get(`/event/${eventId}`);
                eventData = data;
              } catch (err) {
                console.error(err);
              }
              const wait = Math.random() * 7000 + 3000;
              await swal({
                buttons: {},
                className: 'swal-custom-content',
                content: <EndEventMessage wait={wait} hasRedirectUrl={!!eventData.redirectUrl} />,
                closeOnClickOutside: false,
                closeOnEsc: false,
              });
              _cleanUp();
              if (eventData.redirectUrl) {
                redirect(eventData.redirectUrl, eventData.appendAttendeeInformation, true, eventData.erpAccountId);
              }
            } else if (nextStatus === REMOVED || nextStatus === ARCHIVED) {
              _cleanUp();
            } else if (nextStatus === ONBOARDING) {
              const isPaused = localStorage.getItem(`paused-${participantData.event._id}`);
              if (isPaused) {
                SocketClient.emitClientAction('unpause', {});
              }
              reloadBrowser(false);
            }
            const { isOpen } = swal.getState();
            if ((nextStatus === IN_SCREENING_QUEUE || nextStatus === ARCHIVED) && isOpen) {
              swal.close();
            }
          }
          if (prevRole !== nextRole) {
            localStorage.setItem('participantRole', nextRole);
            if (
              (OFF_WALL_ROLES.includes(prevRole) && ON_WALL_ROLES.includes(nextRole)) ||
              (ON_WALL_ROLES.includes(prevRole) && OFF_WALL_ROLES.includes(nextRole))
            ) {
              reloadBrowser(false);
            } else {
              _updateParticipantData({ role: nextRole });
              if (ON_WALL_ROLES.includes(nextRole)) {
                const { enablePPTControl, enableShowWall, enableShareScreen } = participantData.event;
                setSendFeedToStudioQueue(nextRole === EVENT_SUPPORT || sendFeedToStudioQueue === true);
                setEnablePPTControl(enablePPTControl === true);
                setEnableShowWall(enableShowWall === true);
                setEnableShareScreen(enableShareScreen === true);
              } else {
                setIsObserver(nextRole === OBSERVER);
              }
            }
          }
        }
      });

      SocketClient.addListener('change-off-wall-to-on-wall', async ({ role }) => {
        const { participantData } = _stateRef.current;
        const eventId = participantData.event._id;
        const uuid = participantData.uuid;

        try {
          await swal({
            buttons: {},
            closeOnClickOutside: false,
            closeOnEsc: false,
            className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
            content: (
              <OffWallParticipantModal
                title='Change role'
                role={role}
                buttonLabel='Accept'
                onAccept={async ({ city, state, phoneNumber }) => {
                  const location = `${city}, ${state}`;
                  await put('/eventParticipant/roleChange', {
                    event: eventId,
                    participant: uuid,
                    fromRole: participantData.role,
                    toRole: role,
                    status: 1,
                  });
                  await put(`/participant/${participantData.uuid}`, { phone: phoneNumber, location });
                  await put(`/eventParticipant/${participantData._id}`, { role });
                  swal.close();
                }}
                onDecline={async () => {
                  await put('/eventParticipant/roleChange', {
                    event: eventId,
                    participant: uuid,
                    fromRole: participantData.role,
                    toRole: role,
                    status: 0,
                  });
                  swal.close();
                }}
                participantData={participantData}
              />
            ),
          });
        } catch (err) {
          console.error(err);
        }
      });

      SocketClient.addListener('breakout-start', ({ token }) => {
        rtcClient.cleanup();
        rtcClient.disconnect();
        MediaDeviceUtil.resetPromises();
        const {
          router: {
            location: { state: participantInfo, search },
          },
        } = store.getState();
        toast.dismiss();
        store.dispatch(push({ pathname: `/breakout-room`, search: `${search}&accessToken=${token}`, state: participantInfo }));
      });

      SocketClient.addListener('clear-all-audience-responses', () => {
        setClearResponse(true);
      });

      SocketClient.addListener('clear-single-audience-response', () => {
        setClearResponse(true);
      });

      SocketClient.addListener('enable-ppt-control', ({ value }) => {
        const { participantData } = _stateRef.current;
        participantData.event.enablePPTControl = value;
        _updateParticipantData({ event: participantData.event });
        setEnablePPTControl(!!value);
      });

      SocketClient.addListener('enable-show-wall', ({ value }) => {
        const { participantData } = _stateRef.current;
        participantData.event.enableShowWall = value;
        _updateParticipantData({ event: participantData.event });
        setEnableShowWall(!!value);
      });

      SocketClient.addListener('enable-share-screen', ({ value }) => {
        const { participantData } = _stateRef.current;
        participantData.event.enableShareScreen = value;
        _updateParticipantData({ event: participantData.event });
        setEnableShareScreen(!!value);
      });

      SocketClient.addListener('wall-support-staff-talking', () => {
        const remoteAudio = document.getElementById('remote-audio');
        if (remoteAudio && remoteAudio.muted) {
          remoteAudio.muted = false;
          _remoteAudioUnmuted.current = true;
        }
      });

      SocketClient.addListener('wall-support-staff-muted', () => {
        const remoteAudio = document.getElementById('remote-audio');
        if (remoteAudio && _remoteAudioUnmuted.current === true) {
          remoteAudio.muted = true;
          _remoteAudioUnmuted.current = false;
        }
      });

      SocketClient.addListener('meeting-break', ({ value }) => {
        _updateParticipantData({ breakStarted: value === 'start' });

        const { status } = _stateRef.current;
        if ([IN_STUDIO_QUEUE, ON_WALL, ON_AIR].includes(status)) {
          _displayMeetingBreakMessage(value);
        }
      });

      SocketClient.addListener('direct-connect-available', (isAvailable) => {
        if (!isAvailable) {
          swal({
            title: 'Pando Meeting',
            text: 'Please stand by. You will be reconnected momentarily.',
            className: isIpad ? 'swal-break-ipad' : 'swal-break',
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }
      });

      SocketClient.addListener('reset-photo', ({ photoUrl }) => {
        setPhotoUrl(photoUrl);
      });
    } else {
      SocketClient.addListener('participant-update', async ({ nextStatus, nextRole, eventId }) => {
        const prevStatus = _stateRef.current.status;
        const { participantData } = _stateRef.current;
        const prevRole = participantData.role;
        console.log('participant-update', prevStatus, nextStatus, prevRole, nextRole);
        if (eventId === participantData.event._id) {
          if (prevStatus !== nextStatus) {
            setStatus(nextStatus);
            if (nextStatus === COMPLETED) {
              _cleanUp();
              try {
                const { data: eventData } = await get(`/event/${eventId}`);
                if (eventData.redirectUrl) {
                  redirect(eventData.redirectUrl, eventData.appendAttendeeInformation, true, eventData.erpAccountId);
                }
              } catch (err) {
                console.error(err);
              }
            } else if (nextStatus === REMOVED || nextStatus === ARCHIVED) {
              _cleanUp();
            }
          }
        }
      });
    }

    SocketClient.addListener('show-poll', (data) => {
      const { participantData, status } = _stateRef.current;
      if (
        !data.existingResponses.includes(participantData._id) &&
        ([ON_WALL, ON_AIR].includes(status) ||
          (status === IN_STUDIO_QUEUE && participantData.sendFeedToStudioQueue === true) ||
          OFF_WALL_ROLES.includes(participantData.role) ||
          participantData.role === VENUE_PARTICIPANT)
      ) {
        setPoll(data.poll);
      }
      _updateParticipantData({ activePoll: data.poll._id });
    });

    SocketClient.addListener('close-poll', () => {
      setPoll(null);
      _updateParticipantData({ activePoll: null });
    });

    SocketClient.addListener('refresh-browser', () => {
      const {
        router: {
          location: { state: participantInfo, search },
        },
      } = store.getState();
      let searchParams = new URLSearchParams(search);
      searchParams.delete('auto-connect');
      store.dispatch(push({ pathname: '/connect', search: `${searchParams.toString()}&auto-connect=true`, state: { ...participantInfo } }));
      window.location.reload();
    });

    SocketClient.addListener('enable-reactions', ({ value }) => {
      setShowReactions(value);
    });
  }

  function _cleanUp() {
    rtcClient.cleanup();
    rtcClient.disconnect();
    if (window.videoStream) {
      window.videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (window.audioStream) {
      window.audioStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    MediaDeviceUtil.resetPromises();
    SocketClient.removeAllListeners();
    SocketClient.disconnect();
    Heartbeat.stop();

    const event = localStorage.getItem('participantEvent') && JSON.parse(localStorage.getItem('participantEvent'));
    const eventId = event ? event._id : null;
    localStorage.removeItem(`paused-${eventId}`);

    localStorage.removeItem('photoTaken');
    localStorage.removeItem('breakout-room-audio-enabled');
    localStorage.removeItem('breakout-room-video-enabled');
  }

  async function _rtcClientConnect(stream, data, isOffWall) {
    if (isOffWall) return;
    await new Promise(async (resolve) => {
      const response = await get(`/event/getLiveEventId`);
      if (response.data) {
        setLiveEventId(response.data.liveEventId);
        resolve();
      }
      SocketClient.addListener('event-started', ({ eventId }) => {
        setLiveEventId(eventId);
        resolve();
      });
    });

    const { uuid, name, location, eventParticipantId, metadata } = data;
    rtcClient.connect({
      name,
      location,
      uuid,
      stream,
      eventParticipantId,
      metadata,
    });
    setConnected(true);

    SocketClient.reconnectWebRTC = new Deferred();
    SocketClient.reconnectWebRTC.promise.then(() => {
      if (_stateRef.current.status !== IN_BREAKOUT_ROOM) {
        console.log('Reconnecting WebRTC connection');
        rtcClient.disconnect();
        _rtcClientConnect(stream, data);
      }
    });
  }

  async function _displayMeetingBreakMessage(value) {
    const _isOnBreak = value === 'start';
    if (_isOnBreak) {
      swal({
        title: 'Pando Meeting Break',
        text: 'The Pando meeting is paused for a break. When the break has ended, you will be prompted to click Continue to rejoin the meeting',
        className: isIpad ? 'swal-break-ipad' : 'swal-break',
        button: null,
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      const { isOpen } = swal.getState();
      if (isOpen) {
        swal.close();
      }
      await swal({
        title: 'Pando Meeting Break',
        text: 'The Pando meeting has returned from break. Click Continue now to rejoin the meeting',
        button: 'Continue',
        className: isIpad ? 'swal-break-ipad' : 'swal-break',
      });
    }
    setIsOnMeetingBreak(_isOnBreak);
  }

  async function _switchTrack(type, track) {
    const result = await rtcClient.switchTracks(type, track);
    return result;
  }

  async function _savePhoto(data) {
    setPhotoTaken(true);
    setPhotoUrl(data.photoUrl);
    photoTakenDeferred.resolve();
    try {
      const {
        router: {
          location: { state: participantInfo },
        },
      } = store.getState();
      await put(`/eventParticipant/${participantInfo.eventParticipantId}`, { 'systemCheck.photoTaken': true });
    } catch (error) {
      console.log(error);
    }
  }

  function _updateParticipantData(data) {
    const { participantData } = _stateRef.current;
    setParticipantData({ ...participantData, ...data });

    if (data.hasOwnProperty('name')) {
      const nameParts = data.name.split(' ');
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(' ');
      const state = store.getState();
      store.dispatch(replace({ ...state.router.location, state: { ...state.router.location.state, firstName, lastName } }));
    }
  }

  async function _handleVisibilityChange() {
    const { participantData } = _stateRef.current;
    if (!participantData) return;

    const { event, uuid, _id } = participantData;

    setIsVisible(!isVisible);
    if (isVisible) addReportingEvent('PAGE_HIDDEN', event._id, uuid);
    else addReportingEvent('PAGE_VISIBLE', event._id, uuid);

    try {
      await put(`/eventParticipant/${_id}`, { browserMinimized: isVisible });
    } catch (error) {
      console.log(error);
    }
  }

  const showHeader = participantData && participantData.event && participantData.event.header.enabled;
  const showFooter = participantData && participantData.event && participantData.event.footer.enabled;
  const headerHtmlLocked = participantData && participantData.event && participantData.event.header.isHtmlLocked;
  const footerHtmlLocked = participantData && participantData.event && participantData.event.footer.isHtmlLocked;
  const htmlWidget = participantData && participantData.event && participantData.event.htmlWidget;
  const showHtmlWidget = htmlWidget && htmlWidget.enabled;

  let messageBox = null;
  let remoteVideo = document.getElementById('remote-video');
  let showParticipantActions =
    participantData &&
    (participantData.role === OFF_WALL_ADMIN ||
      participantData.role === OFF_WALL_PARTICIPANT ||
      participantData.role === VENUE_OFF_WALL ||
      participantData.role !== OBSERVER);

  let videoOpacity = (participantData && liveEventId === participantData.event._id) || status === IN_SCREENING ? 1 : 0;
  let audioMuted = (participantData && liveEventId === participantData.event._id) || status === IN_SCREENING ? false : true;

  if (liveEventId !== -1) {
    if (status === ON_WALL || status === ON_AIR) {
      showParticipantActions = true;
      videoOpacity = 1;
      audioMuted = false;
    } else {
      if (status === IN_SCREENING_QUEUE) {
        videoOpacity = 0;
        audioMuted = true;
        showParticipantActions =
          participantData &&
          (participantData.role === OFF_WALL_ADMIN || participantData.role === OFF_WALL_PARTICIPANT || participantData.role === VENUE_OFF_WALL)
            ? true
            : false;
        messageBox = <InScreeningQueueMessage show={showId} position={queuePosition} />;
      } else if (status === IN_STUDIO_QUEUE) {
        if (sendFeedToStudioQueue) {
          if (remoteVideo) {
            videoOpacity = 1;
            audioMuted = false;
          }
          messageBox = null;
          showParticipantActions = true;
        } else {
          if (remoteVideo) {
            videoOpacity = 0;
            audioMuted = true;
          }
          showParticipantActions = participantData && [OFF_WALL_ADMIN, OFF_WALL_PARTICIPANT, VENUE_OFF_WALL].includes(participantData.role) ? true : false;
          messageBox = <InStudioQueueMessage show={showId} queueSize={queueSize} />;
        }
      } else if (status === ARCHIVED || status === COMPLETED) {
        videoOpacity = 0;
        audioMuted = true;
        messageBox = <ShowEndedMessage show={showId} participantName={participantData && participantData.name} />;
        showParticipantActions = false;
      } else if (status === IN_SCREENING) {
        videoOpacity = 1;
        audioMuted = false;
        showParticipantActions = false;
      } else if (status === REMOVED) {
        videoOpacity = 0;
        audioMuted = true;
        showParticipantActions = false;
        messageBox = <RemovedMessage show={showId} />;
      } else if (status === ALREADY_CONNECTED) {
        videoOpacity = 0;
        audioMuted = true;
        showParticipantActions = false;
        messageBox = <AlreadyConnectedMessage show={showId} platform={platformDetails} eventParticipantId={eventParticipantId} />;
      }
    }

    const rehearsalModeRoles = [EVENT_SUPPORT, WALL_PRESENTER, OFF_WALL_ADMIN];
    const waitForRehearsal = participantData && !rehearsalModeRoles.includes(participantData.role) && rehearsalModeEnabled;
    const isEventLive = participantData && liveEventId === participantData.event._id;

    if (![IN_SCREENING_QUEUE, ALREADY_CONNECTED, ARCHIVED, COMPLETED].includes(status) && (waitForRehearsal || !isEventLive)) {
      if (status !== IN_SCREENING) {
        videoOpacity = 0;
        audioMuted = true;
      }
      messageBox = (
        <WaitingMessage
          showName={showId}
          waitingForServers={false}
          waitForRehearsal={waitForRehearsal}
          isEventLive={isEventLive}
          isBroadcast={isBroadcast}
          isEventEnded={isEventEnded}
        />
      );
    }
  }

  const _isMobile = isMobile();
  const disableInteractions = [REMOVED, ARCHIVED, COMPLETED].includes(status);

  return (
    <>
      <PageVisibility onChange={_handleVisibilityChange} />
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, height: '100%' }}>
          <Spinner />
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', pointerEvents: disableInteractions ? 'none' : 'all' }}>
        {showHeader && (
          <HtmlContainer
            htmlLocked={headerHtmlLocked}
            html={participantData.event.header.html}
            backgroundColor={participantData.event.header.bgColor}
            height={participantData.event.header.height}
          />
        )}
        <div
          className='connect-screen'
          style={{ alignItems: !photoTaken ? 'center' : 'inherit', flexDirection: _isMobile ? 'row-reverse' : undefined, position: 'relative' }}
        >
          <ConnectForm
            ref={formRef}
            connected={connected}
            onSwitchTrack={_switchTrack}
            status={status}
            photoUrl={photoUrl}
            photoTaken={photoTaken}
            onAcceptPhoto={_savePhoto}
            onChangeInput={_updateParticipantData}
            participantData={participantData}
            isOffWall={isOffWall}
            isObserver={isObserver}
            isBroadcast={isBroadcast}
            poll={poll}
            onClosePoll={() => setPoll(null)}
            isOnMeetingBreak={isOnMeetingBreak}
            isOffWallReady={isOffWallReady}
            isVenueParticipant={isVenueParticipant}
          >
            {isVenueParticipant && participantData && (
              <ParticipantActions
                showReactionButtons={true}
                enablePPTControl={false}
                enableShowWall={false}
                enableShareScreen={false}
                clearResponse={clearResponse}
                onAction={() => setClearResponse(false)}
                role={participantData.role}
                event={participantData.event}
                participantData={participantData}
                token={participantData.token}
                pptControllerURL={pptControllerURL}
                enableReactions={showReactions}
                isVenueParticipant={isVenueParticipant}
              />
            )}
          </ConnectForm>
          {photoTaken && !isVenueParticipant && (
            <div id='stream-container' className={_isMobile ? 'content mobile' : 'content'}>
              {!_isMobile && showHtmlWidget && (
                <HtmlWidget
                  height={`${htmlWidget.height}${htmlWidget.heightUnits}`}
                  width={`${htmlWidget.width}${htmlWidget.widthUnits}`}
                  backgroundColor={htmlWidget.bgColor}
                  pinTo={htmlWidget.pinTo}
                  spacing={htmlWidget.spacing}
                  html={htmlWidget.html}
                />
              )}
              {(status === REMOVED || status === ALREADY_CONNECTED || status === ARCHIVED || isEventEnded) && (
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>{messageBox && <>{messageBox}</>}</div>
              )}
              {status !== REMOVED && status !== ALREADY_CONNECTED && status !== ARCHIVED && !isEventEnded && (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  {participantData && !isOffWall && !isBroadcast && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100% - 71px)' }}>
                      <div className='media' style={{ paddingRight: 25 }}>
                        <audio id='remote-audio' muted={audioMuted} />
                        <video id='remote-video' style={{ opacity: videoOpacity }} disablePictureInPicture />
                      </div>
                      {messageBox && <>{messageBox}</>}
                    </div>
                  )}
                  {((isBroadcast && status === WAITING_FOR_SERVERS) ||
                    (participantData && [OFF_WALL_PARTICIPANT, VENUE_OFF_WALL, OBSERVER].includes(participantData.role) && rehearsalModeEnabled) ||
                    (isOffWall && [REMOVED, COMPLETED, ARCHIVED].includes(status))) && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100% - 71px)' }}>
                      {messageBox && <>{messageBox}</>}
                    </div>
                  )}
                  {participantData &&
                    !isBroadcast &&
                    isOffWall &&
                    (!rehearsalModeEnabled || participantData.role === OFF_WALL_ADMIN) &&
                    ![REMOVED, COMPLETED, ARCHIVED].includes(status) && (
                      <OffWallStream
                        url={offWallHlsStreamUrl}
                        onEnterMeeting={() => setIsOffWallReady(true)}
                        showHtmlWidget={showHtmlWidget}
                        htmlWidget={htmlWidget}
                      />
                    )}
                  {participantData && isBroadcast && ![REMOVED, COMPLETED, ARCHIVED].includes(status) && (
                    <BroadcastStream
                      url={offWallHlsStreamUrl}
                      onEnterMeeting={() => setIsOffWallReady(true)}
                      showHtmlWidget={showHtmlWidget}
                      htmlWidget={htmlWidget}
                      participantData={participantData}
                    />
                  )}
                  {!isBroadcast && participantData && (
                    <ParticipantActions
                      showReactionButtons={showParticipantActions}
                      enablePPTControl={enablePPtControl}
                      enableShowWall={enableShowWall}
                      enableShareScreen={enableShareScreen}
                      clearResponse={clearResponse}
                      onAction={() => setClearResponse(false)}
                      role={participantData.role}
                      event={participantData.event}
                      participantData={participantData}
                      token={participantData.token}
                      pptControllerURL={pptControllerURL}
                      enableReactions={showReactions}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        {showFooter && (
          <HtmlContainer
            htmlLocked={footerHtmlLocked}
            html={participantData.event.footer.html}
            backgroundColor={participantData.event.footer.bgColor}
            height={participantData.event.footer.height}
          >
            {participantData.event.footer.showPandoLogo && !isBroadcast && (
              <div
                style={{
                  backgroundColor: participantData.event.footer.bgColor,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: 170,
                  height: participantData.event.footer.height,
                }}
              >
                <img src={Logo} style={{ marginRight: 20, width: 'auto', height: 35 }} />
              </div>
            )}
          </HtmlContainer>
        )}
      </div>
    </>
  );
}
