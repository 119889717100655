import React from 'react';
import '../../common/Messages/Messages.css';
import OffWallParticipantForm from '../../common/OffWallParticipantForm';

function OffWallParticipantModal({ onAccept, onDecline, vertical, role, participantData }) {
  const _onAccept = ({ city, state, phoneNumber }) => {
    if (state.length > 0 && city.length > 0 && phoneNumber.length > 0) {
      onAccept && onAccept({ city, state, phoneNumber });
    }
  };

  return (
    <div className={vertical ? 'vertical-message-box' : 'horizontal-message-box'}>
      <div className='content' style={{ marginTop: 0, height: 'auto', background: 'none' }}>
        <div className='left' style={{ margin: 0 }}>
          <OffWallParticipantForm onAccept={_onAccept} onDecline={onDecline} role={role} participantData={participantData} uiVersion='role-change' />
        </div>
      </div>
    </div>
  );
}

export default OffWallParticipantModal;
