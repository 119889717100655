import React from 'react';
import NewMessageIcon from '../../../assets/new_icons/icon-new-message.svg';

export default function CustomNotification() {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <img src={NewMessageIcon} width={40} alt='' />
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
        <div style={{ fontSize: 15, marginBottom: 5 }}>Chat Message</div>
        <div style={{ fontSize: 12, color: 'rgba(255,255,255,0.6)' }}>
          <span style={{ marginRight: 4 }}>You have received a chat</span>
          <span style={{ color: '#70C34E' }}>Click here to view</span>
        </div>
      </div>
    </div>
  );
}
