import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';

function InfiniteScroll({ items, renderItem, containerClassName, pageSize = 25, threshold = 100, style, onContextMenu }, ref) {
  const _containerRef = useRef(null);

  const [paginatedItems, setPaginatedItems] = useState([]);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

  const _scrollToBottom = () => {
    if (_containerRef.current) _containerRef.current.scrollTop = _containerRef.current.scrollHeight;
  };

  const _clear = () => {
    setPaginatedItems([]);
  };

  useImperativeHandle(ref, () => ({
    scrollToBottom: _scrollToBottom,
    clear: _clear,
  }));

  useEffect(() => {
    if (items.length === 0) {
      _clear();
    } else {
      const { scrollTop, scrollHeight, clientHeight } = _containerRef.current;
      const scrollAtBottom = Math.round(scrollTop) === Math.round(scrollHeight - clientHeight);
      if (scrollAtBottom) {
        const _paginatedItems = items.slice(Math.max(0, items.length - pageSize), items.length);
        setPaginatedItems(_paginatedItems);
        setShouldScrollToBottom(true);
      } else {
        const firstMessage = paginatedItems[0];
        const firstMessageIndex = items.findIndex((m) => m._id === firstMessage._id);

        const lastMessage = paginatedItems[paginatedItems.length - 1];
        const lastMessageIndex = items.findIndex((m) => m._id === lastMessage._id);

        const _paginatedItems = items.slice(firstMessageIndex, lastMessageIndex + pageSize);
        setPaginatedItems(_paginatedItems);
        setShouldScrollToBottom(false);
      }
    }
  }, [items]);

  useEffect(() => {
    if (shouldScrollToBottom) _scrollToBottom();
  }, [paginatedItems, shouldScrollToBottom]);

  const _handleScroll = useCallback(() => {
    if (_containerRef.current && _containerRef.current.scrollTop < threshold) {
      const firstMessage = paginatedItems[0];
      const firstMessageIndex = items.findIndex((m) => m._id === firstMessage._id);
      if (firstMessageIndex !== -1) {
        const _messagesToPrepend = items.slice(Math.max(0, firstMessageIndex - pageSize), firstMessageIndex);
        setPaginatedItems((prevPaginatedItems) => [..._messagesToPrepend, ...prevPaginatedItems]);
        setShouldScrollToBottom(false);
      }
    }
  }, [items, paginatedItems]);

  return (
    <div ref={_containerRef} className={containerClassName} onScroll={_handleScroll} style={style} onContextMenu={onContextMenu}>
      {paginatedItems.map((item, index) => renderItem(item, index))}
    </div>
  );
}

export default forwardRef(InfiniteScroll);
