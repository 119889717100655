import React from 'react';
import Removed from '../../assets/removed_from_meeting.svg';
import useWindowResize from '../../hooks/useWindowResize';
import { getMessageBoxTransform } from '../../utils/browser-util';
import './Messages.css';

function RemovedMessage({ show }) {
  const { height } = useWindowResize();
  return (
    <div className='message-box' style={getMessageBoxTransform(height)}>
      <span className='show-id'>{show}</span>
      <img src={Removed} alt='Done' />
      <div className='message-footer'>
        <span className='top' style={{ color: '#fff', fontSize: 20, marginBottom: 10 }}>
          Removed
        </span>
        <span className='bottom' style={{ textAlign: 'center' }}>
          You have been removed from the show.
        </span>
      </div>
    </div>
  );
}

export default RemovedMessage;
