import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import PrivateChatTab from './PrivateChatTab';
import ContextMenu from '../../../common/ContextMenu';
import StringUtil from '../../../utils/string-util';
import { GENERAL_CHAT_TAB } from './Chat';

export default function ChatTabs({ chats, activeChat, highlightedChatTabs, onTabChange, onTabClose, hideAttendeeDetails }) {
  const [privateTabs, setPrivateTabs] = useState([]);
  const [visibleTabs, setVisibleTabs] = useState([]);
  const [showMoreTabContextMenu, setShowMoreTabContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (chats.length > 0) {
      const _privateChats = chats.filter((c) => c.id !== GENERAL_CHAT_TAB.id);
      const _visibleTabs = _privateChats.slice(0, 2);
      setPrivateTabs(_privateChats);
      setVisibleTabs(_visibleTabs);
    }
  }, [chats]);

  const _onMoreTabContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMoreTabContextMenu(true);
    setContextMenuPosition({ x: e.pageX, y: e.pageY });
  };

  const _onContextMenuItemSelection = async (option) => {
    if (option) {
      const _privateChats = chats.filter((c) => c.id !== GENERAL_CHAT_TAB.id);
      const selectedTabIndex = _privateChats.findIndex((pc) => pc.id === option.value.id);
      if (selectedTabIndex !== -1) {
        [_privateChats[0], _privateChats[selectedTabIndex]] = [_privateChats[selectedTabIndex], _privateChats[0]];
        const _visibleTabs = _privateChats.slice(0, 2);
        setPrivateTabs(_privateChats);
        setVisibleTabs(_visibleTabs);
        onTabChange(option.value);
      }
    }
  };

  const _hideContextMenu = () => {
    setShowMoreTabContextMenu(false);
    setContextMenuPosition({ x: 0, y: 0 });
  };

  const _getContextMenuItems = () => {
    const items = [];
    const visibleTabsIds = visibleTabs.map((vt) => vt.id);
    const hiddenTabs = privateTabs.filter((pt) => !visibleTabsIds.includes(pt.id));
    hiddenTabs.forEach((ht) => {
      items.push({ value: ht, label: ht.label });
    });
    return items;
  };

  const _onCloseChat = async (tab) => {
    try {
      onTabClose && onTabClose(tab);
    } catch (err) {
      console.error(err);
    }
  };

  const _getClasses = (tabId, isPrivate = false) => {
    const _classes = ['tab'];
    if (isPrivate) {
      _classes.push('private');
    }
    if (activeChat && activeChat.id === tabId) {
      _classes.push('active');
    }
    if (highlightedChatTabs.length > 0) {
      const index = highlightedChatTabs.findIndex((hc) => hc.id === tabId);
      if (index !== -1) {
        _classes.push('highlight');
      }
    }
    return _classes.join(' ');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', borderTop: '2px solid #1e2730' }}>
      {showMoreTabContextMenu === true && (
        <ContextMenu position={contextMenuPosition} items={_getContextMenuItems()} onClose={_hideContextMenu} onItemSelected={_onContextMenuItemSelection} />
      )}
      <div className='chat-tabs'>
        {chats.length > 0 && (
          <div className={_getClasses(chats[0].id)} onClick={() => onTabChange(chats[0])} style={{ marginLeft: 5 }}>
            {chats[0].label}
          </div>
        )}
        {visibleTabs.map((pc, i) => {
          return (
            <PrivateChatTab
              key={`chat-tab-${pc.id}-${i}`}
              item={pc}
              classes={_getClasses(pc.id, true)}
              onClick={onTabChange}
              onClose={_onCloseChat}
              hideAttendeeDetails={hideAttendeeDetails}
            />
          );
        })}
      </div>
      {privateTabs.length > 2 && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 5 }}>
          <button
            onClick={_onMoreTabContextMenu}
            className='more-tab'
            style={{
              border: 0,
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            <span>More</span>
            <FontAwesomeIcon icon={faCaretDown} color='#c1c1c1' />
          </button>
        </div>
      )}
    </div>
  );
}
