import React, { useRef, useEffect, useState, useCallback } from 'react';
import { debounce } from 'throttle-debounce';
import RemoteParticipant from './RemoteParticipant';
import useResizeObserver from '../../hooks/useResizeObserver';

let GRID_TEMPLATE = {};
let nextGridIndex = 1;
for (let rows = 1; rows <= 7; rows++) {
  for (let columns = 1; columns <= rows; columns++) {
    while (nextGridIndex <= rows * columns) {
      GRID_TEMPLATE[nextGridIndex++] = { rows, columns };
    }
  }
}

export default function GridView({
  participants,
  onRemoteScreenshareTrack,
  onVideoTrackStateChange,
  onAudioTrackStateChange,
  remoteTracksState,
  showSharedContent,
  hideAttendeeDetails,
}) {
  const gridRef = useRef(null);
  const itemMargin = 2;
  const gridMargin = 20;
  const totalParticipants = participants.length;

  const [itemWidth, setItemWidth] = useState(0);
  const [itemHeight, setItemHeight] = useState(0);

  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const debouncedFn = useRef(debounce(100, setDimension));

  const onGridAreaResize = useCallback(
    ({ width, height }) => {
      if (gridRef.current) {
        debouncedFn.current({ width, height });
      }
    },
    [gridRef],
  );
  useResizeObserver(gridRef.current, onGridAreaResize);

  const calculateItemSize = useCallback(() => {
    if (!gridRef.current || totalParticipants === 0) {
      return;
    }
    const { width: gridContainerWidth, height: gridContainerHeight } = dimension;

    let itemWidth = 0;
    let itemHeight = 0;

    if (showSharedContent) {
      gridRef.current.style.width = '180px';

      itemWidth = 160;
      itemHeight = 90;
    } else {
      const grid = GRID_TEMPLATE[totalParticipants];
      if (!grid) {
        return;
      }
      gridRef.current.style.width = '100%';
      const columns = grid.columns;
      const rows = grid.rows;

      itemWidth = Math.round((gridContainerWidth - gridMargin * 2) / columns) - itemMargin * 2;
      itemHeight = Math.round(itemWidth * (9 / 16));

      if (itemHeight * rows > gridContainerHeight - gridMargin * 2) {
        itemHeight = Math.round((gridContainerHeight - gridMargin * 2) / rows) - itemMargin * 2;
        itemWidth = Math.round(itemHeight * (16 / 9));
      }
    }
    setItemWidth(itemWidth);
    setItemHeight(itemHeight);
  }, [dimension, totalParticipants, showSharedContent]);

  useEffect(() => {
    if (gridRef.current) {
      const { width, height } = gridRef.current.getBoundingClientRect();
      setDimension({ width, height });
    }
  }, []);

  useEffect(() => {
    calculateItemSize();
  }, [calculateItemSize]);

  const grid = GRID_TEMPLATE[totalParticipants];
  const maxWidth = totalParticipants > 0 ? (itemWidth + itemMargin * 2) * grid.columns : 'auto';

  return (
    <div
      ref={gridRef}
      className='remote-videos-container'
      style={{
        width: showSharedContent ? 320 : '100%',
      }}
    >
      <div
        className='custom-scrollbar'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: showSharedContent ? 'flex-start' : 'center',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          overflowY: showSharedContent ? 'auto' : 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 5,
            overflow: 'hidden',
            maxWidth,
          }}
        >
          {participants.map((p) => {
            return (
              <RemoteParticipant
                key={p.sid}
                participant={p}
                width={itemWidth}
                height={itemHeight}
                margin={itemMargin}
                onRemoteScreenshareTrack={onRemoteScreenshareTrack}
                onVideoTrackStateChange={onVideoTrackStateChange}
                onAudioTrackStateChange={onAudioTrackStateChange}
                remoteTracksState={remoteTracksState[p.sid]}
                showSharedContent={showSharedContent}
                hideAttendeeDetails={hideAttendeeDetails}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
