import React from 'react';
import { format } from 'date-fns';
import StringUtil from '../../../utils/string-util';
import CheckMark from './CheckMark';

export default function ChatMessage({ from, message, messageTime, sendTo, isAdmin, type, acknowledged, enableCheckMark, onCheckMarkClick }) {
  return (
    <div className='chat-message'>
      <div className='container'>
        <div className='initials'>{StringUtil.getInitials(from)}</div>
        <div>
          <div className='message-info'>
            <small>{`${from} - ${format(new Date(messageTime), 'p')}`}</small>
            {sendTo === 'EVERYONE' && isAdmin && <small style={{ color: '#3abb79', marginLeft: 5 }}>to All</small>}
          </div>
          <div className='message-text' style={{ color: type ? (type === 'ERROR' ? 'rgb(200 20 30)' : '#287737') : '#fff', wordBreak: 'break-word' }}>
            <span style={{ marginRight: 4 }}>{message}</span>
            <CheckMark
              enabled={enableCheckMark}
              value={acknowledged}
              tooltip={acknowledged ? 'Acknowledged' : 'Mark as acknowledged'}
              onClick={onCheckMarkClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
