import React, { useEffect, useRef, useState } from 'react';
import AlreadyLoggedIn from '../../assets/already-logged-in.svg';
import useWindowResize from '../../hooks/useWindowResize';
import { getMessageBoxTransform } from '../../utils/browser-util';
import { ARCHIVED } from '../../utils/user-util';
import { put } from '../../services/api';
import './Messages.css';

function AlreadyConnectedMessage({ show, platform, eventParticipantId }) {
  const timer = useRef(null);
  const { height } = useWindowResize();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    (async () => {
      try {
        if (!eventParticipantId) return;
        await new Promise((resolve) => {
          timer.current = setInterval(() => {
            setCountdown((prev) => {
              if (prev === 0) {
                clearInterval(timer.current);
                resolve();
                return prev;
              }
              return prev - 1;
            });
          }, 1000);
        });
        await put(`/eventParticipant/${eventParticipantId}`, { status: ARCHIVED });
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    })();
  }, [eventParticipantId]);

  return (
    <div className='message-box' style={getMessageBoxTransform(height)}>
      <span className='show-id'>{show}</span>
      <img src={AlreadyLoggedIn} alt='' />
      <div className='message-footer' style={{ height: 230 }}>
        <span className='bottom' style={{ textAlign: 'center', marginBottom: 0 }}>
          You can only log in once.
          <br />
          {countdown ? (
            <>
              You will be logged out from the other device/browser in {countdown} seconds.{' '}
              <span
                style={{ textDecoration: 'underline', color: '#D4363B', cursor: 'pointer' }}
                onClick={() => {
                  clearInterval(timer.current);
                  setCountdown(null);
                }}
              >
                Cancel
              </span>
              <br />
            </>
          ) : null}
          <br />
          You are currently logged in on another device/browser.
          <br />
          <br />
          {`${platform && platform.osName} ${platform && platform.osVersion}`}
          <br />
          {`${platform && platform.browserName} ${platform && platform.browserVersion}`}
        </span>
      </div>
    </div>
  );
}

export default AlreadyConnectedMessage;
