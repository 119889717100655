import React from 'react';
import './SpeedTest.css';

export default function ProgressBar({ title, value, customStyle }) {
  return (
    <div className='widget' style={customStyle}>
      <div className='title'>{title}</div>
      <div className='control'>
        <div className='progress-container'>
          <div className='progress-bar'>
            <div className='progress' style={value ? { left: `${value}%` } : {}} />
          </div>
        </div>
      </div>
    </div>
  );
}
