import React, { useState } from 'react';
import ContextMenu from '../../common/ContextMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import StringUtil from '../../utils/string-util';

export default function ParticipantListItem({
  participant,
  remoteTracksState,
  participants,
  isHost,
  isPresenter,
  currentPresenter,
  currentHost,
  onMakePresenter,
  onMakeParticipant,
  hideAttendeeDetails,
}) {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const onContextMenuHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setContextMenuPosition({ x: e.pageX, y: e.pageY });
    setShowContextMenu(true);
  };

  const hideContextMenu = () => {
    setContextMenuPosition({ x: 0, y: 0 });
    setShowContextMenu(false);
  };

  const _getContextMenuItemSelectionHandler = (item) => (option) => {
    const remoteParticipant = participants.find((p) => p.sid === item.sid);
    if (!option.disabled) {
      switch (option.value) {
        case 1:
          onMakePresenter(remoteParticipant);
          break;
        case 2:
          onMakeParticipant(remoteParticipant);
          break;
        default:
          break;
      }
    }
  };

  const _getContextMenuItems = ({ sid }) => {
    const menuItems = [];
    if (currentPresenter === sid) {
      menuItems.push({ value: 2, label: 'Make participant' });
    } else {
      menuItems.push({ value: 1, label: 'Make presenter' });
    }

    return menuItems;
  };

  const { sid } = participant;
  const audioEnabled = remoteTracksState ? remoteTracksState.audio : false;
  const videoEnabled = remoteTracksState ? remoteTracksState.video : false;
  const contextMenuEnabled = isHost && sid !== currentHost && (!isPresenter || currentPresenter === sid);
  const iconStyle = { margin: '0 5px' };

  const name = StringUtil.getDisplayName(participant.identity, hideAttendeeDetails);

  return (
    <div
      key={sid}
      style={{
        color: '#a9aaab',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 40,
        minHeight: 40,
        padding: '0 8px',
      }}
      onContextMenu={onContextMenuHandler}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <span style={{ marginRight: 4 }}>{name}</span>
        {currentHost === sid ? <span>{` (Host)`}</span> : null}
        {currentPresenter === sid ? <span>{` (Presenter)`}</span> : null}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {audioEnabled ? (
          <FontAwesomeIcon style={iconStyle} icon={faMicrophone} color='#a9aaab' />
        ) : (
          <FontAwesomeIcon style={iconStyle} icon={faMicrophoneSlash} color='#a9aaab' />
        )}
        {videoEnabled ? (
          <FontAwesomeIcon style={iconStyle} icon={faVideo} color='#a9aaab' />
        ) : (
          <FontAwesomeIcon style={iconStyle} icon={faVideoSlash} color='#a9aaab' />
        )}
      </div>
      {contextMenuEnabled && showContextMenu === true ? (
        <ContextMenu
          position={contextMenuPosition}
          items={_getContextMenuItems(participant)}
          onClose={hideContextMenu}
          onItemSelected={_getContextMenuItemSelectionHandler(participant)}
        />
      ) : null}
    </div>
  );
}
