export default class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = (err) => {
        reject(err);
      };
      this.resolve = (res) => {
        resolve(res);
      };
    });
  }
}
