import React, { useEffect, useState } from 'react';
import Storage from '../utils/storage';

const localStorage = Storage.getLocalStorage();

const CachedImage = ({ src, alt }) => {
  const [isCached, setIsCached] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const cacheImage = async () => {
      try {
        const cachedImage = localStorage.getItem(src);
        if (cachedImage) {
          setIsCached(true);
          setImageUrl(cachedImage);
        } else {
          const response = await fetch(src);
          const blob = await response.blob();
          const blobUrl = URL.createObjectURL(blob);
          localStorage.setItem(src, blobUrl);
          setIsCached(true);
          setImageUrl(blobUrl);
        }
      } catch (error) {
        console.error(error);
      }
    };

    cacheImage();
  }, [src]);

  useEffect(() => {
    const validateImageUrl = () => {
      const img = new Image();
      img.onload = () => {
        if (img.complete && img.naturalWidth === 0) {
          setImageUrl('');
          localStorage.removeItem(src);
        }
      };
      img.src = imageUrl;
    };

    if (imageUrl !== '') {
      validateImageUrl();
    }

    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, [imageUrl, src]);

  return <img src={isCached ? imageUrl : null} alt={alt} />;
};

export default CachedImage;
