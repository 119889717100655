import React from 'react';
import EnterMeetingIcon from '../../assets/enter-meeting.svg';
import Button from '../Button';
import './Messages.css';

function EnterMeeting({ onClick }) {
  return (
    <div className='horizontal-message-box' style={{ flex: 'unset' }}>
      <div className='content' style={{ flexDirection: 'row', margin: 0 }}>
        <div className='left'>
          <div className='text' style={{ textAlign: 'center', fontSize: 14 }}>
            Please click the button below to enter the meeting.{' '}
            <Button type='primary' text='Enter Meeting' onClick={onClick} containerStyle={{ marginTop: 20, transform: 'scale(0.75)' }} />
          </div>
        </div>
        <div className='right'>
          <img src={EnterMeetingIcon} alt='' />
        </div>
      </div>
    </div>
  );
}

export default EnterMeeting;
