class ArrayUtil {
  static sort(arr, sortBy) {
    const sorted = arr.sort((a, b) => {
      let p1Str = a[sortBy.value];
      let p2Str = b[sortBy.value];
      if (sortBy.value === 'name') {
        p1Str = `${a.firstName} ${a.lastName}`.trim();
        p2Str = `${b.firstName} ${b.lastName}`.trim();
      }
      if (sortBy.dir === 'desc') {
        if (p1Str < p2Str) {
          return -1;
        }
        if (p1Str > p2Str) {
          return 1;
        }
        return 0;
      } else {
        if (p1Str > p2Str) {
          return -1;
        }
        if (p1Str < p2Str) {
          return 1;
        }
        return 0;
      }
    });

    const onAirParticipants = sorted.filter((s) => s.status === 'ON_AIR');
    if (onAirParticipants.length > 0) {
      const offAirParticipants = sorted.filter((s) => s.status !== 'ON_AIR');
      return onAirParticipants.concat(offAirParticipants);
    } else {
      return sorted;
    }
  }
}

export default ArrayUtil;
