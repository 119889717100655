import React from 'react';
import Button from '../../common/Button';
import PandoLogo from '../../assets/pando-logo.png';
import Background from '../../assets/background.png';

export default function LandingPage({ onButtonClick }) {
  return (
    <div
      className='landing-page'
      style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
    >
      <div className='flex-columns'>
        <div className='logo-container'>
          <img src={PandoLogo} alt='logo' height={100} />
        </div>
        <div className='content'>
          <h1>Welcome to Pando</h1>
          <h3>A truly unique and interactive hybrid meeting experience!</h3>
          <p>
            Please take a moment to complete the onboarding process by clicking the button below.
            <br />
            <br />
            If you need assistance at any point, please press the "Get Help" button to connect with a technician. We hope you enjoy your Pando experience!
          </p>
          <div className='button-container'>
            <Button type='primary' text='Begin Onboarding' containerStyle={{ padding: '2px 40px', margin: 0 }} onClick={onButtonClick} />
          </div>
        </div>
      </div>
    </div>
  );
}
