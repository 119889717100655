import React from 'react';

const TextButton = (props) => {
  const { onText, offText, value, onChange, tooltip, onColor, customClass } = props;

  const _onClickHandler = (event) => {
    event.stopPropagation();
    onChange && onChange({ value: !value });
  };

  const className = value === false ? `icon-button ${customClass} off` : `icon-button ${customClass} on`;

  return (
    <div className={className} onClick={_onClickHandler} role='none' tabIndex='-1' onKeyUp={() => {}}>
      {value === false ? (
        <span className='text-button'>{offText}</span>
      ) : (
        <span className='text-button' style={{ color: onColor || '#FFF' }}>
          {onText}
        </span>
      )}
      {tooltip ? <span className='tooltip'>{tooltip}</span> : null}
    </div>
  );
};

export default TextButton;
