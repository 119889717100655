import { post } from '../services/api';
import { store } from '../store';

export default async function addReportingEvent(type, event, uuid, data) {
  const {
    router: {
      location: { state: participantInfo },
    },
  } = store.getState();

  const body = {
    type,
    unid: participantInfo.UNID,
    event,
    uuid,
    data,
  };
  post(`/v1/report`, body);
}
