import React from 'react';
import PropTypes from 'prop-types';

import onIcon from '../../assets/checkbox_on.svg';
import offIcon from '../../assets/checkbox_off.svg';
import './Checkbox.css';

const Checkbox = ({ label, value, onChange, disabled, style }) => {
  const _handleClick = () => {
    if (disabled) return;
    onChange(!value);
  };

  const checkboxIcon = value ? onIcon : offIcon;
  return (
    <div
      className='checkbox'
      onClick={_handleClick}
      role='none'
      tabIndex='-1'
      onKeyUp={() => {}}
      style={{ ...style, opacity: disabled ? 0.5 : 1, cursor: disabled ? 'not-allowed' : 'pointer' }}
    >
      <img className='checkbox-icon' src={checkboxIcon} alt='checkmark' />
      <div className='checkbox-label'>{label || ''}</div>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

Checkbox.defaultProps = {
  label: undefined,
  value: false,
  disabled: false,
  style: {},
};

export default Checkbox;
