import React, { PureComponent } from 'react';
import Onboarding from './Onboarding';
import MediaDeviceUtil from '../../utils/media-device-util';

export default class OnboardingContainer extends PureComponent {
  constructor(props) {
    super(props);
    const { origin, search } = window.location;
    this.state = {
      notesData: props.location.state,
      loginLink: `${origin}/login${search}`,
    };
    if (!MediaDeviceUtil.notesData) {
      window.location.href = this.state.loginLink;
    }
    const { event } = props.location.state;
    const eventId = event._id;
    localStorage.removeItem(`paused-${eventId}`);
    localStorage.removeItem('breakout-room-audio-enabled');
    localStorage.removeItem('breakout-room-video-enabled');
  }

  render() {
    return <Onboarding {...this.props} notesData={this.state.notesData} loginLink={this.state.loginLink} />;
  }
}
