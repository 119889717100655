import React, { forwardRef, useRef, useEffect } from 'react';

const ScreenShareVideo = forwardRef(({ showSharedContent }, ref) => {
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current && showSharedContent) {
      containerRef.current.style.left = '190px';
    }
  }, [showSharedContent]);

  return (
    <div ref={containerRef} className='screen-share-container'>
      <video ref={ref} playsInline autoPlay style={{ width: '100%', height: '100%' }} />
    </div>
  );
});

export default ScreenShareVideo;
