import React from 'react';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import Button from '../../common/Button';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';

const styles = {
  button: {
    padding: 0,
    fontSize: 14,
    margin: 0,
    height: 32,
    width: 37,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.8)',
    border: '1px solid #ddd',
    marginLeft: 3,
  },
};

function DownloadNotesButton({ event, editorState }) {
  const _downloadNotes = () => {
    const element = document.createElement('a');
    const file = new Blob([draftToHtml(convertToRaw(editorState.getCurrentContent()))], { type: 'text/html' });
    element.href = URL.createObjectURL(file);
    element.download = `${event.name}_notes.html`;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <Button
      type='secondary'
      iconType='FontAwesome'
      icon={faFileDownload}
      iconStyle={{ color: '#303030' }}
      onClick={_downloadNotes}
      containerStyle={styles.button}
      title='Download'
    />
  );
}

export default DownloadNotesButton;
