import React, { useEffect, useState } from 'react';
import ImageCache from '../../utils/image-cache';

export default function ParticipantPhoto({ photoUrl }) {
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    (async () => {
      if (photoUrl) {
        const url = new URL(photoUrl);
        const imgData = await ImageCache.get(url.pathname.substring(1));
        setImgSrc(imgData);
      }
    })();
  }, [photoUrl]);

  return (
    <div className='photo' style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}>
      <img src={imgSrc} alt='particpant' style={{ position: 'relative', width: '100%' }} />
    </div>
  );
}
