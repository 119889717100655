import React, { PureComponent } from 'react';

class PresentationArea extends PureComponent {
  _presentationAreaRef = React.createRef();
  _videoContainerRef = React.createRef();

  componentDidMount() {
    const { screenTrack } = this.props;
    const video = document.getElementById('screen-share-video');
    video.srcObject = screenTrack;
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.remountVideo !== this.props.remountVideo && this.props.remountVideo === true) {
      this._remountVideo();
    }
  }

  _remountVideo = () => {
    if (!this._unmounted) {
      const { current } = this._videoContainerRef;
      const oldVideo = document.getElementById('screen-share-video');
      if (oldVideo) {
        current.removeChild(oldVideo);
      }
      const video = document.createElement('video');
      video.id = 'screen-share-video';
      video.srcObject = this.props.screenTrack;
      video.autoplay = true;
      current.appendChild(video);
      video.play();
      this.props.afterRemount();
    }
  };

  render() {
    return (
      <div ref={this._presentationAreaRef} className='presentation-area'>
        <div ref={this._videoContainerRef} className='screen-track'>
          <video id='screen-share-video' autoPlay></video>
        </div>
      </div>
    );
  }
}

export default PresentationArea;
