class EventEmitter {
  static _events = {};

  static on(name, callback) {
    if (!EventEmitter._events[name]) {
      EventEmitter._events[name] = [];
    }
    EventEmitter._events[name].push(callback);
  }

  static removeListener(name, callback) {
    if (EventEmitter._events[name]) {
      const index = EventEmitter._events[name].indexOf(callback);
      if (index !== -1) {
        EventEmitter._events[name].splice(index, 1);
      }
      if (EventEmitter._events[name].length === 0) delete EventEmitter._events[name];
    }
  }

  static emit(name, data) {
    if (EventEmitter._events[name]) {
      EventEmitter._events[name].forEach((callback) => callback(data));
    }
  }
}

export default EventEmitter;
