import React from 'react';
import thumbsUpIcon from '../../../assets/new_icons/thumb-up-solid.svg';
import thumbsDownIcon from '../../../assets/new_icons/thumb-down-solid.svg';
import raiseHandIcon from '../../../assets/new_icons/raise-hand-solid.svg';
import onAirIcon from '../../../assets/new_icons/mic-on.svg';
import StringUtil from '../../../utils/string-util';

export default function AttendeeListItem({ name, reaction, status, highlight }) {
  let icon = null;
  if (reaction) {
    switch (reaction) {
      case 'RAISE_HAND':
        icon = raiseHandIcon;
        break;
      case 'THUMBS_UP':
        icon = thumbsUpIcon;
        break;
      case 'THUMBS_DOWN':
        icon = thumbsDownIcon;
        break;
    }
  }

  return (
    <div className={highlight ? 'attendee-list-item highlighted-bold' : 'attendee-list-item'}>
      <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
        <div className='initials'>{StringUtil.getInitials(name)}</div>
        <span className='name'>{name}</span>
      </div>
      <div className='status'>
        {icon && <img src={icon} width={20} height={20} />}
        {status === 'ON_AIR' && <img src={onAirIcon} width={20} height={20} />}
      </div>
    </div>
  );
}
