import React, { useEffect, useState, useRef } from 'react';
import swal from '@sweetalert/with-react';
import { debounce } from 'throttle-debounce';
import { OFF_WALL_PARTICIPANT } from '../../utils/user-util';
import { post, put, setStudioId, setToken } from '../../services/api';
import { getNotesUser, verifyNotesData } from '../../utils/notes-util';
import { redirect, isIpad, isMobile } from '../../utils/browser-util';
import HtmlContainer from '../../common/HtmlContainer';
import HtmlWidget from '../../common/HtmlWidget';
import VideoJS from '../../common/VideoJS';
import './ArchivePlayer.css';

export default function ArchivePlayerPage() {
  const timerRef = useRef(null);
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const debouncedHandleResize = useRef(null);

  const reportingEventData = useRef(null);
  const lastPlaybackEndedEvent = useRef(null);
  const htmlWidgetRef = useRef(null);

  const [archivePlaybackUrl, setArchivePlaybackUrl] = useState('');
  const [eventData, setEventData] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [videoPadding, setVideoPadding] = useState({});
  const [videoJsLoaded, setVideoJsLoaded] = useState(false);

  useEffect(() => {
    debouncedHandleResize.current = debounce(250, () => {
      if (!htmlWidgetRef.current || !playerContainerRef.current) {
        return;
      }

      let htmlWidget = htmlWidgetRef.current;
      let showHtmlWidget = htmlWidget.enabled;

      if (showHtmlWidget && playerContainerRef.current) {
        let widgetWidth = htmlWidget.width;
        if (htmlWidget.widthUnits === '%' && playerContainerRef.current) {
          widgetWidth = (playerContainerRef.current.offsetWidth * widgetWidth) / 100;
        }
        let _videoPadding;
        if (htmlWidget.pinTo.indexOf('-left') !== -1) {
          _videoPadding = {
            paddingLeft: widgetWidth + htmlWidget.spacing.left + 15,
          };
        } else {
          _videoPadding = {
            paddingRight: widgetWidth + htmlWidget.spacing.right + 15,
          };
        }
        setVideoPadding(_videoPadding);
      }
    });

    const onOrientationChange = () => {
      setTimeout(debouncedHandleResize.current, 1000);
    };

    window.addEventListener('resize', debouncedHandleResize.current);
    if (isIpad || isMobile()) {
      window.addEventListener('orientationchange', onOrientationChange);
    }

    async function fetchData() {
      const { search } = window.location;
      const historyState = window.history.state || {};
      const urlParams = new URLSearchParams(search);
      const erpToken = urlParams.get('token') || historyState['token'];

      let erpData;
      if (erpToken) {
        try {
          const { data } = await post('/auth/verifyToken', { token: erpToken });
          erpData = data;
        } catch (e) {
          console.error(e);
          swal({
            title: 'Error',
            text: 'Invalid token',
          });
          return;
        }
      }

      urlParams.forEach((value, key) => {
        historyState[key] = value;
      });
      window.history.replaceState(historyState, '', window.location.pathname);
      const db = historyState['db'];
      const unid = historyState['unid'];
      const studioId = historyState['studioId'];
      const showName = erpData ? erpData.showName : historyState['ShowName'];
      const email = erpData ? erpData.email : historyState['email'];
      setStudioId(studioId);

      let verifiedData;
      if (!erpData) {
        const { data: notesData } = await getNotesUser(db, unid);
        verifiedData = verifyNotesData(notesData, search, true);
      } else {
        verifiedData = erpData;
      }

      const { data: eventData } = await post('/event/eventForName', { name: showName });
      setEventData(eventData);

      const { data: participantData } = await post('/participant/getUuid', {
        pandoV1UNID: unid,
        erpId: verifiedData.erpId,
        eventId: eventData._id,
        firstName: verifiedData.firstName,
        lastName: verifiedData.lastName,
        username: verifiedData.username,
        phone: verifiedData.phone,
        location: verifiedData.location,
        role: OFF_WALL_PARTICIPANT,
        isArchiveParticipant: true,
        email,
      });
      const { token } = participantData;

      setToken(token);
      setArchivePlaybackUrl(eventData.archivedMedia.url);

      reportingEventData.current = {
        event: eventData._id,
        participant: participantData.uuid,
      };
      htmlWidgetRef.current = eventData.htmlWidget;

      debouncedHandleResize.current();
    }

    fetchData();

    return () => {
      clearInterval(timerRef.current);
      window.removeEventListener('resize', debouncedHandleResize.current);
      if (isIpad || isMobile()) {
        window.removeEventListener('orientationchange', onOrientationChange);
      }
    };
  }, []);

  useEffect(() => {
    if (!videoJsLoaded || archivePlaybackUrl.length === 0) {
      return;
    }
    playerRef.current._stream();
  }, [videoJsLoaded, archivePlaybackUrl]);

  const _onLoad = () => {
    setVideoJsLoaded(true);
  };

  const updatePlaybackProgress = async () => {
    try {
      if (!lastPlaybackEndedEvent.current) {
        return;
      }
      put(`/report/reportingEvent/${lastPlaybackEndedEvent.current._id}`, {
        ...reportingEventData.current,
        reportingEventType: 'ARCHIVED_MEDIA_PLAYBACK_ENDED',
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onPlaying = async () => {
    try {
      if (!sessionStorage.getItem('playing-set')) {
        sessionStorage.setItem('playing-set', 'true');

        await post('/report/reportingEvent', { ...reportingEventData.current, reportingEventType: 'ARCHIVED_MEDIA_PLAYBACK_STARTED' });
        const response = await post('/report/reportingEvent', { ...reportingEventData.current, reportingEventType: 'ARCHIVED_MEDIA_PLAYBACK_ENDED' });
        lastPlaybackEndedEvent.current = response.data;

        timerRef.current = setInterval(() => {
          updatePlaybackProgress();
        }, 5000);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onPlaybackEnded = async () => {
    clearInterval(timerRef.current);
    updatePlaybackProgress();
    const res = await swal({
      title: 'Program Ended',
      text: 'Would you like to restart or complete the program?',
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        cancel: 'Restart',
        confirm: 'Complete',
      },
    });
    if (res) {
      const showName = window.history.state['ShowName'];
      const { data: eventData } = await post('/event/eventForName', { name: showName });
      if (eventData.redirectUrl) {
        redirect(eventData.redirectUrl, eventData.appendAttendeeInformation, false);
      }
    } else {
      playerRef.current._stream();
    }
  };

  const onIpadExitFullScreen = async (orientation) => {
    if (orientation === 'portrait') {
      await swal({
        title: 'Please Refresh',
        text: 'We have detected an issue with fullscreen mode on your device. Please refresh the page to continue.',
        buttons: {
          cancel: 'Refresh',
        },
        dangerMode: true,
      });
      window.location.reload();
    }
  };

  let showHeader;
  let showFooter;
  let headerHtmlLocked;
  let footerHtmlLocked;
  let htmlWidget;
  let showHtmlWidget;

  if (eventData) {
    showHeader = eventData.header.enabled;
    showFooter = eventData.footer.enabled;
    headerHtmlLocked = eventData.header.isHtmlLocked;
    footerHtmlLocked = eventData.footer.isHtmlLocked;
    htmlWidget = eventData.htmlWidget;
    showHtmlWidget = htmlWidget.enabled;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {showHeader && (
        <HtmlContainer htmlLocked={headerHtmlLocked} html={eventData.header.html} backgroundColor={eventData.header.bgColor} height={eventData.header.height} />
      )}
      <div ref={playerContainerRef} className='player-container'>
        {showHtmlWidget && (
          <HtmlWidget
            height={`${htmlWidget.height}${htmlWidget.heightUnits}`}
            width={`${htmlWidget.width}${htmlWidget.widthUnits}`}
            backgroundColor={htmlWidget.bgColor}
            pinTo={htmlWidget.pinTo}
            spacing={htmlWidget.spacing}
            html={htmlWidget.html}
            ignoreIsMobile={true}
          />
        )}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: showVideo ? 1 : 0,
            ...videoPadding,
          }}
        >
          <div
            className='video-container'
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              aspectRatio: '16 / 9',
            }}
          >
            <VideoJS
              ref={playerRef}
              url={archivePlaybackUrl}
              onLoad={_onLoad}
              onReady={() => setShowVideo(true)}
              onPlaying={onPlaying}
              onEnded={onPlaybackEnded}
              onIpadExitFullScreen={onIpadExitFullScreen}
            />
          </div>
        </div>
      </div>
      {showFooter && (
        <HtmlContainer htmlLocked={footerHtmlLocked} html={eventData.footer.html} backgroundColor={eventData.footer.bgColor} height={eventData.footer.height} />
      )}
    </div>
  );
}
