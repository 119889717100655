import React from 'react';
import Logo from '../../assets/pando-logo.png';
import OffWallStreamUnavailableIcon from '../../assets/stream-unavailable.svg';
import useWindowResize from '../../hooks/useWindowResize';
import { getMessageBoxTransform } from '../../utils/browser-util';
import './Messages.css';

function OffWallStreamUnavailable() {
  const { height } = useWindowResize();
  const style = getMessageBoxTransform(height);
  return (
    <div className='horizontal-message-box' style={{ ...style, padding: 30, flex: 'unset' }}>
      <div className='content' style={{ flexDirection: 'row', margin: 0 }}>
        <div className='left'>
          <div className='text'>
            <div style={{ textAlign: 'center' }}>Stream is currently unavailable</div>
          </div>
        </div>
        <div className='right'>
          <img src={OffWallStreamUnavailableIcon} alt='' style={{ minHeight: 160 }} />
        </div>
      </div>
    </div>
  );
}

export default OffWallStreamUnavailable;
