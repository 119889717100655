import React, { useState, useEffect, useCallback } from 'react';
import { put } from '../services/api';
import Input from './Input';

const styles = {
  inputLabel: { width: 65, minHeight: 27, display: 'flex', alignItems: 'center', fontSize: 14 },
  inputContainer: { margin: 5, height: 48 },
  input: { marginLeft: 0, width: 160 },
  text: {
    fontSize: 14,
    color: '#ddd',
    marginTop: 10,
    marginBottom: 20,
    textAlign: 'left',
    padding: '0 25px',
  },
  title: {
    color: '#ddd',
    marginTop: 10,
    marginBottom: 20,
    fontSize: 17,
    fontWeight: 'bold',
    textAlign: 'center',
  },
};

export default function OffWallParticipantForm({ participantData, onAccept, onDecline, uiVersion, role }) {
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showInputFields, setShowInputFields] = useState(false);

  const [cityError, setCityError] = useState('');
  const [stateError, setStateError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  useEffect(() => {
    if (participantData) {
      const { location, phone } = participantData;
      let _city = '';
      let _state = '';
      let _phoneNumber = '';
      if (location) {
        const parts = location.split(',');
        _city = parts[0] || '';
        _state = parts[1] || '';
      }
      if (phone) {
        _phoneNumber = phone;
      }
      setCity(_city);
      setState(_state);
      setPhoneNumber(_phoneNumber);
      if (_city.length === 0 || _state.length === 0 || _phoneNumber.length === 0) {
        setShowInputFields(true);
      } else if (uiVersion === 'onboarding') {
        onAccept && onAccept({ city: _city, state: _state, phoneNumber: _phoneNumber });
      }
    }
  }, []);

  const _onAccept = useCallback(async () => {
    if (city.length === 0) {
      setCityError('City is required');
    }

    if (state.length === 0) {
      setStateError('State is required');
    }

    if (phoneNumber.length === 0) {
      setPhoneNumberError('Phone is required');
    }

    if (state.length > 0 && city.length > 0 && phoneNumber.length > 0) {
      const { uuid } = participantData;
      await put(`/participant/${uuid}`, { phone: phoneNumber, city, state });
      onAccept && onAccept({ city, state, phoneNumber });
    }
  }, [city, state, phoneNumber, participantData, onAccept]);

  const handleInputChange = useCallback((e) => {
    const { value, name } = e.target;
    if (name === 'city') {
      setCityError('');
      setCity(value);
    } else if (name === 'state') {
      setStateError('');
      setState(value);
    } else {
      setPhoneNumberError('');
      setPhoneNumber(value);
    }
  }, []);

  return (
    <>
      {uiVersion === 'role-change' && (
        <>
          <div style={styles.title}>Change Role</div>
          <div style={styles.text}>
            {`The Pando Administrator would like you to become a ${role}. Your Camera and Microphone will be enabled so you can interact on the Pando Wall. ${
              showInputFields ? 'Please enter your location and contact information for today.' : ''
            }`}
          </div>
        </>
      )}
      {uiVersion === 'onboarding' && (
        <div style={{ ...styles.text, textAlign: 'center', fontSize: 14 }}>{`Please enter your location and contact information for today.`}</div>
      )}
      {showInputFields && (
        <div style={{ textAlign: 'center', margin: '15px 0 10px 0' }}>
          <Input
            type='text'
            name='city'
            label='City'
            value={city}
            onChange={handleInputChange}
            labelStyle={styles.inputLabel}
            style={styles.input}
            containerStyle={styles.inputContainer}
            hasError={cityError.length > 0}
            errorMessage={cityError}
          />
          <Input
            type='text'
            name='state'
            label='State'
            value={state}
            onChange={handleInputChange}
            labelStyle={styles.inputLabel}
            style={styles.input}
            containerStyle={styles.inputContainer}
            hasError={stateError.length > 0}
            errorMessage={stateError}
          />
          <Input
            type='text'
            name='phone'
            label='Phone'
            value={phoneNumber}
            onChange={handleInputChange}
            labelStyle={styles.inputLabel}
            style={styles.input}
            containerStyle={styles.inputContainer}
            hasError={phoneNumberError.length > 0}
            errorMessage={phoneNumberError}
          />
        </div>
      )}
      {uiVersion === 'onboarding' && showInputFields && (
        <button
          onClick={_onAccept}
          style={{ backgroundColor: '#287737', border: 0, outline: 'none', color: '#fff', borderRadius: 2, marginLeft: 10, fontSize: 14, padding: '8px 24px' }}
        >
          Submit
        </button>
      )}
      {uiVersion === 'role-change' && (
        <div style={{ marginTop: 20 }}>
          <button className='cancel' onClick={onDecline} style={{ fontSize: 14, padding: '8px 24px' }}>
            Decline
          </button>
          <button onClick={_onAccept} style={{ marginLeft: 10, fontSize: 14, padding: '8px 24px' }}>
            Accept
          </button>
        </div>
      )}
    </>
  );
}
