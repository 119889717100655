import { get } from '../services/api';
import Storage from './storage';

const localStorage = Storage.getLocalStorage();

const cache = {};

export default class ImageCache {
  static async get(url) {
    if (!cache[url]) {
      cache[url] = new Promise(async (resolve, reject) => {
        try {
          const file = await get(`/file/?path=${url}&t=${Date.now()}`, null, 'arraybuffer');
          const image = btoa(new Uint8Array(file.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
          resolve(`data:image;base64,${image}`);
        } catch (error) {
          reject(error);
        }
      });
    }
    const base64Img = await cache[url];
    return base64Img;
  }

  static remove(url) {
    delete cache[url];
  }

  static async preload(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      localStorage.setItem(url, blobUrl);
    } catch (error) {
      console.error(error);
    }
  }
}
