export const EVENT_SUPPORT = 'Event Support';
export const WALL_PARTICIPANT = 'Wall Participant';
export const WALL_PRESENTER = 'Wall Presenter';
export const OFF_WALL_PARTICIPANT = 'Off-wall Participant';
export const OFF_WALL_ADMIN = 'Off-wall Admin'; //admin
export const OBSERVER = 'Observer';
export const VENUE_OFF_WALL = 'Venue Off-wall';
export const VENUE_ON_WALL = 'Venue On-wall';
export const VENUE_PARTICIPANT = 'Venue Participant';

export const OFF_WALL_ROLES = [OFF_WALL_ADMIN, OFF_WALL_PARTICIPANT, OBSERVER, VENUE_OFF_WALL];
export const ON_WALL_ROLES = [EVENT_SUPPORT, WALL_PARTICIPANT, WALL_PRESENTER, VENUE_ON_WALL];

export const NOT_CONNECTED = 'NOT_CONNECTED';
export const IN_SCREENING_QUEUE = 'IN_SCREENING_QUEUE';
export const IN_SCREENING = 'IN_SCREENING';
export const IN_STUDIO_QUEUE = 'IN_STUDIO_QUEUE';
export const ON_WALL = 'ON_WALL';
export const ON_AIR = 'ON_AIR';
export const IN_BREAKOUT_ROOM = 'IN_BREAKOUT_ROOM';
export const ARCHIVED = 'ARCHIVED';
export const COMPLETED = 'COMPLETED';
export const REMOVED = 'REMOVED';
export const ONBOARDING = 'ONBOARDING';
export const ALREADY_CONNECTED = 'ALREADY_CONNECTED';
export const WAITING_FOR_SERVERS = 'WAITING_FOR_SERVERS';
