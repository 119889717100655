import React from 'react';
import Done from '../../assets/you_are_done.svg';
import useWindowResize from '../../hooks/useWindowResize';
import { getMessageBoxTransform } from '../../utils/browser-util';
import './Messages.css';

function ShowEnded({ show, participantName }) {
  const { height } = useWindowResize();
  return (
    <div className='message-box' style={getMessageBoxTransform(height)}>
      <span className='show-id'>{show}</span>
      <span className='title'>Great Job</span>
      <span className='name'>{participantName}</span>
      <img src={Done} alt='Done' />
      <div className='message-footer'>
        <span className='top' style={{ color: '#fff', fontSize: 20, marginBottom: 10 }}>
          You Are Now Done
        </span>
        <span className='bottom' style={{ textAlign: 'center' }}>
          Thank You For Being Part Of Our Show!
        </span>
      </div>
    </div>
  );
}

export default ShowEnded;
