import React from 'react';
import ParticipantListItem from './ParticipantListItem';

export default function ParticipantList({
  participants,
  remoteTracksState,
  isHost,
  isPresenter,
  currentPresenter,
  currentHost,
  onMakePresenter,
  onMakeParticipant,
  hideAttendeeDetails,
  style,
}) {
  return (
    <div className='custom-scrollbar' style={{ flex: 1, overflowX: 'hidden', overflowY: 'auto', ...style }}>
      {participants
        .sort((a, b) => (a.identity > b.identity ? 1 : -1))
        .map((participant) => {
          return (
            <ParticipantListItem
              key={participant.sid}
              participant={participant}
              remoteTracksState={remoteTracksState[participant.sid]}
              isHost={isHost}
              isPresenter={isPresenter}
              currentPresenter={currentPresenter}
              currentHost={currentHost}
              onMakePresenter={onMakePresenter}
              onMakeParticipant={onMakeParticipant}
              participants={participants}
              hideAttendeeDetails={hideAttendeeDetails}
            />
          );
        })}
    </div>
  );
}
