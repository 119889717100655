import React, { useState } from 'react';
import thumbsUpIcon from '../../../assets/new_icons/thumb-up-solid.svg';
import thumbsDownIcon from '../../../assets/new_icons/thumb-down-solid.svg';
import raiseHandIcon from '../../../assets/new_icons/raise-hand-solid.svg';
import onAirIcon from '../../../assets/new_icons/mic-on.svg';
import ContextMenu from '../../../common/ContextMenu';
import { getContextMenuItems } from '../../../utils/context-menu';

export default function EventSupportAttendeeListItem({ id, uuid, name, role, reaction, status, highlight, onStartPrivateChat }) {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  const _onContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowContextMenu(true);
    setContextMenuPosition({ x: e.pageX, y: e.pageY });
  };

  const _onContextMenuItemSelection = async (option, subOption) => {
    switch (option.value) {
      case 1:
        onStartPrivateChat && onStartPrivateChat();
        break;
      default:
        break;
    }
  };

  const _hideContextMenu = () => {
    setShowContextMenu(false);
    setContextMenuPosition({ x: 0, y: 0 });
  };

  let icon = null;
  if (reaction) {
    switch (reaction) {
      case 'RAISE_HAND':
        icon = raiseHandIcon;
        break;
      case 'THUMBS_UP':
        icon = thumbsUpIcon;
        break;
      case 'THUMBS_DOWN':
        icon = thumbsDownIcon;
        break;
      default:
        icon = null;
        break;
    }
  }
  return (
    <div className={highlight ? 'table-row highlighted' : 'table-row'} onContextMenu={_onContextMenu}>
      {showContextMenu === true && (
        <ContextMenu
          position={contextMenuPosition}
          items={getContextMenuItems(uuid, role)}
          onClose={_hideContextMenu}
          onItemSelected={_onContextMenuItemSelection}
        />
      )}
      <div className='column' style={{ flex: 0.6 }}>
        <span className='name'>{name}</span>
        <div className='status'>
          {icon && <img src={icon} width={15} height={15} alt='' />}
          {status === 'ON_AIR' && <img src={onAirIcon} width={15} height={15} alt='' />}
        </div>
      </div>
      <div className='column' style={{ flex: 0.4 }}>
        {role}
      </div>
    </div>
  );
}
