import React, { useEffect, useState } from 'react';
import Logo from '../../assets/pando-logo.png';
import CachedImage from '../CachedImage';
import { isMobile } from '../../utils/browser-util';
import './Messages.css';

function CustomMessage({ title, body, buttonLabel, icon, renderIcon, onClick, vertical, footerElement, showLogo }) {
  const [messageBoxWidth, setMessageBoxWidth] = useState(500);

  useEffect(() => {
    const _applyCustomStyle = () => {
      if (isMobile()) {
        const orientation = sessionStorage.getItem('orientation');
        const swalCustomContent = document.getElementsByClassName('swal-custom-content')[0];
        if (orientation === 'landscape') {
          swalCustomContent.style.width = '560px';
          setMessageBoxWidth(500);
        } else {
          swalCustomContent.style.width = 'auto';
          setMessageBoxWidth(300);
        }
      }
    };
    _applyCustomStyle();

    const _onOrientationChange = () => {
      setTimeout(_applyCustomStyle, 1000);
    };
    window.addEventListener('orientationchange', _onOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', _onOrientationChange);
    };
  }, []);

  return (
    <div className={vertical ? 'vertical-message-box' : 'horizontal-message-box'} style={{ width: messageBoxWidth }}>
      {showLogo && (
        <div className='header'>
          <CachedImage src={Logo} alt='Pando Branches' />
        </div>
      )}
      <div className='content' style={{ marginTop: showLogo === false ? 0 : null }}>
        <div className='left'>
          <div className='text'>
            <div>{title}</div>
            <div
              style={{
                fontSize: 14,
                color: '#797878',
                marginTop: 10,
                marginBottom: 5,
                textAlign: 'left',
              }}
            >
              {body}
            </div>
          </div>
          {buttonLabel && <button onClick={onClick}>{buttonLabel}</button>}
        </div>
        {renderIcon
          ? renderIcon()
          : icon && (
              <div className='right'>
                <CachedImage src={icon} alt='icon' />
              </div>
            )}
      </div>
      {footerElement && <div style={{ color: '#fff', marginTop: 20, whiteSpace: 'pre-line', fontSize: 14 }}>{footerElement}</div>}
    </div>
  );
}

export default CustomMessage;
