import React from 'react';
import PropTypes from 'prop-types';
import { isIpad } from '../../utils/browser-util';
import './CustomRadio.css';

function CustomRadio({ label, name, value, checked, onChange }) {
  let style = {};
  if (isIpad) {
    style = {
      position: 'relative',
      top: '-1.2rem',
    };
  }

  return (
    <label className='cr' style={style}>
      <span>{label}</span>
      <input type='radio' name={name} value={value} checked={checked} onChange={onChange} />
      <div className='cr-input' />
    </label>
  );
}

CustomRadio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CustomRadio;
