import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactionButton from './ReactionButton';
import thumbsUpIcon from '../../assets/new_icons/thumb-up.svg';
import thumbsUpAltIcon from '../../assets/new_icons/thumb-up-line.svg';
import thumbsDownIcon from '../../assets/new_icons/thumb-down.svg';
import thumbsDownAltIcon from '../../assets/new_icons/thumb-down-line.svg';
import raiseHandIcon from '../../assets/new_icons/raise-hand.svg';
import raiseHandAltIcon from '../../assets/new_icons/raise-hand-line.svg';
import expandIcon from '../../assets/new_icons/expand.svg';
import collapseIcon from '../../assets/new_icons/collapse.svg';
import PresenterControls from './PresenterControls';
import SocketClient from '../../utils/socket-client';
import { post } from '../../services/api';
import { store } from '../../store';
import { setOffWallReactionStatus, setIsOffWallAttendeeListOpen } from '../../store/actions';
import { WALL_PRESENTER, OFF_WALL_ADMIN, OFF_WALL_PARTICIPANT, EVENT_SUPPORT, OBSERVER, ON_WALL_ROLES, VENUE_OFF_WALL } from '../../utils/user-util';
import { isSafari } from '../../utils/browser-util';

export default function ParticipantActions({
  showReactionButtons,
  clearResponse,
  onAction,
  enablePPTControl,
  participantData,
  role,
  enableShowWall,
  enableShareScreen,
  token,
  pptControllerURL,
  isBroadcast,
  enableReactions,
  isVenueParticipant,
}) {
  const [raiseHand, setRaiseHand] = useState(false);
  const [thumbsUp, setThumbsUp] = useState(false);
  const [thumbsDown, setThumbsDown] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const _reactionsStateRef = useRef(null);
  const isOffWallAttendeeListOpen = useSelector((state) => state.app.isOffWallAttendeeListOpen);

  useEffect(() => {
    if (participantData.reactionStatus) {
      switch (participantData.reactionStatus) {
        case 'RAISE_HAND':
          setRaiseHand(true);
          break;
        case 'THUMBS_UP':
          setThumbsUp(true);
          break;
        case 'THUMBS_DOWN':
          setThumbsDown(true);
          break;
      }
    }
    return () => {
      const { raiseHand, thumbsDown, thumbsUp } = _reactionsStateRef.current;
      if (raiseHand || thumbsDown || thumbsUp) SocketClient.emitClientAction('set-bg-color', { bgColor: 'rgba(0,0,0,0.8)' });
    };
  }, []);

  useEffect(() => {
    if (clearResponse === true) {
      setRaiseHand(false);
      setThumbsUp(false);
      setThumbsDown(false);
      SocketClient.emitClientAction('set-bg-color', { bgColor: 'rgba(0,0,0,0.8)' });
      if (role === OFF_WALL_PARTICIPANT || role === OFF_WALL_ADMIN || role === VENUE_OFF_WALL) {
        store.dispatch(setOffWallReactionStatus(null));
      }
    }
  }, [clearResponse]);

  useEffect(() => {
    _reactionsStateRef.current = { raiseHand, thumbsDown, thumbsUp };
  }, [raiseHand, thumbsDown, thumbsUp]);

  const _updateParticipantReaction = async (reaction) => {
    try {
      const { event, uuid } = participantData;
      await post('/participant/reaction', { event: event._id, uuid, reaction });
    } catch (err) {
      console.error(err);
    }
  };

  const _onEscape = () => {
    if (!document.fullscreenElement) {
      setFullScreen(false);
    }
  };

  const _onButtonClick = (action) => {
    let state;
    if (action === 'setRaiseHand') {
      state = !raiseHand;
      setRaiseHand(state);
      setThumbsUp(false);
      setThumbsDown(false);
      SocketClient.emitClientAction('set-bg-color', { bgColor: state ? '#1163e9' : 'rgba(0,0,0,0.8)' });
      _updateParticipantReaction(state ? 'RAISE_HAND' : null);
      onAction();
    } else if (action === 'setThumbsUp') {
      state = !thumbsUp;
      setThumbsUp(state);
      setRaiseHand(false);
      setThumbsDown(false);
      SocketClient.emitClientAction('set-bg-color', { bgColor: state ? '#277635' : 'rgba(0,0,0,0.8)' });
      _updateParticipantReaction(state ? 'THUMBS_UP' : null);
      if (role === OFF_WALL_PARTICIPANT || role === OFF_WALL_ADMIN || role === VENUE_OFF_WALL) {
        store.dispatch(setOffWallReactionStatus(state ? 'THUMBS_UP' : null));
      }
      onAction();
    } else if (action === 'setThumbsDown') {
      state = !thumbsDown;
      setThumbsDown(state);
      setThumbsUp(false);
      setRaiseHand(false);
      SocketClient.emitClientAction('set-bg-color', { bgColor: state ? '#BA1B2B' : 'rgba(0,0,0,0.8)' });
      _updateParticipantReaction(state ? 'THUMBS_DOWN' : null);
      if (role === OFF_WALL_PARTICIPANT || role === OFF_WALL_ADMIN || role === VENUE_OFF_WALL) {
        store.dispatch(setOffWallReactionStatus(state ? 'THUMBS_DOWN' : null));
      }
      onAction();
    } else if (action === 'setFullScreen') {
      state = !fullScreen;
      if (state === true) {
        if (document.body.requestFullscreen) {
          document.body.requestFullscreen();
        } else if (document.body.webkitRequestFullscreen) {
          document.body.webkitRequestFullscreen();
        } else if (document.body.mozRequestFullScreen) {
          document.body.mozRequestFullScreen();
        } else if (document.body.msRequestFullscreen) {
          document.body.msRequestFullscreen();
        }
        if (isSafari()) {
          document.addEventListener('webkitfullscreenchange', _onEscape);
        } else {
          document.addEventListener('fullscreenchange', _onEscape);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
      setFullScreen(state);
    }
  };

  function _toggleOffWallAttendeeListModal() {
    store.dispatch(setIsOffWallAttendeeListOpen(!isOffWallAttendeeListOpen));
  }

  const isIOS = /iPad|iPhone|iPod/.test(window.navigator.platform) || (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1);

  return (
    <div className='reaction-buttons-container' style={{ display: showReactionButtons ? 'flex' : 'none' }}>
      {ON_WALL_ROLES.includes(role) && (
        <ReactionButton
          action='setRaiseHand'
          value={raiseHand}
          hoverColor='#1ca5db'
          offIcon={raiseHandAltIcon}
          onIcon={raiseHandIcon}
          onClick={_onButtonClick}
          iconStyle={{ left: -1 }}
          display={enableReactions}
          tooltip={raiseHand ? 'Lower Hand' : 'Raise Hand'}
        />
      )}
      {role !== OBSERVER && (
        <>
          <ReactionButton
            action='setThumbsUp'
            value={thumbsUp}
            hoverColor='#277635'
            offIcon={thumbsUpAltIcon}
            onIcon={thumbsUpIcon}
            onClick={_onButtonClick}
            display={enableReactions}
            tooltip={thumbsUp ? 'Remove Thumbs Up' : 'Thumbs Up'}
          />
          <ReactionButton
            action='setThumbsDown'
            value={thumbsDown}
            hoverColor='#711a20'
            offIcon={thumbsDownAltIcon}
            onIcon={thumbsDownIcon}
            onClick={_onButtonClick}
            iconStyle={{ top: 1 }}
            display={enableReactions}
            tooltip={thumbsDown ? 'Remove Thumbs Down' : 'Thumbs Down'}
          />
        </>
      )}
      {!isIOS && !isVenueParticipant && (
        <ReactionButton
          action='setFullScreen'
          value={fullScreen}
          hoverColor='#4e6379'
          changeIconOnHover={false}
          offIcon={expandIcon}
          onIcon={collapseIcon}
          onClick={_onButtonClick}
          iconStyle={{ width: 27, left: 1 }}
          tooltip={fullScreen ? 'Exit Full Screen' : 'Full Screen'}
        />
      )}
      {(role === WALL_PRESENTER || role === EVENT_SUPPORT) && (
        <PresenterControls
          enablePPTControl={enablePPTControl}
          enableShowWall={enableShowWall}
          enableShareScreen={enableShareScreen}
          event={participantData.event}
          participantUuid={participantData.uuid}
          token={token}
          pptControllerURL={pptControllerURL}
        />
      )}
      {(role === OFF_WALL_ADMIN || role === EVENT_SUPPORT) && (
        <div className='presenter-control'>
          <button style={{ width: '100%' }} onClick={_toggleOffWallAttendeeListModal}>
            <span style={{ fontSize: 14, color: '#a9aaab' }}>Off-Wall List</span>
          </button>
        </div>
      )}
    </div>
  );
}
