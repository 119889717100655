import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function ContextMenuItem({ item, onSelectItem, subMenuLeftPosition }) {
  const [hover, setHover] = useState(false);

  const { danger, disabled, fixed } = item;
  let classes = 'context-menu-item';
  if (danger) {
    classes += ' danger';
  }
  if (disabled) {
    classes += ' disabled';
  }
  if (fixed) {
    classes += ' fixed';
  }

  return (
    <div className={classes} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={(e) => onSelectItem(e, item)}>
      <>
        {item.label}
        {item.icon}
      </>
      {item.options && <FontAwesomeIcon icon={faChevronRight} color='#c1c1c1' />}
      {item.options && hover && (
        <div className='context-menu-item-options' style={{ top: 0, left: subMenuLeftPosition, maxHeight: 375, overflowY: 'auto' }}>
          {item.options.map((opt, index) => {
            let _classes = 'context-menu-item';
            if (opt.danger) {
              _classes += ' danger';
            }
            if (opt.disabled) {
              _classes += ' disabled';
            }
            if (opt.fixed) {
              _classes += ' fixed';
            }
            return (
              <div className={_classes} key={`menu-item-option-${item.value}-${index}`} onClick={(e) => onSelectItem(e, item, opt)}>
                {opt.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
