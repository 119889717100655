import swal from 'sweetalert';
import queryString from 'query-string';
import Storage from './storage';
import { post, setStudioId } from '../services/api';

const localStorage = Storage.getLocalStorage();

class Heartbeat {
  static async start() {
    const connectionAllowed = await Heartbeat.check();
    if (connectionAllowed) {
      Heartbeat._interval = setInterval(() => {
        localStorage.setItem('lastHeartbeat', Date.now());
      }, 1000);
    }
    return connectionAllowed;
  }

  static stop() {
    clearInterval(Heartbeat._interval);
  }

  static async check() {
    let lastHeartbeat = localStorage.getItem('lastHeartbeat');
    if (lastHeartbeat && Date.now() - lastHeartbeat < 3000) {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      lastHeartbeat = localStorage.getItem('lastHeartbeat');
      if (lastHeartbeat && Date.now() - lastHeartbeat < 3000) {
        const res = await swal({
          title: 'Already Connected',
          text: 'You can only log in once per browser. You are already logged in using this browser.\n\nWould you like to proceed here instead?',
          closeOnClickOutside: false,
          closeOnEsc: false,
          buttons: {
            cancel: 'Cancel',
            confirm: 'Proceed',
          },
        });
        if (res) {
          try {
            const { search } = window.location;
            const params = queryString.parse(search);
            const { unid, ShowName, studioId } = params;

            setStudioId(studioId);
            await post(`/participant/logoutOtherDevices`, { pandoV1UNID: unid, showName: ShowName });
            window.location.reload();
          } catch (error) {
            console.error(error);
          }
        }
        return false;
      }
    }
    return true;
  }
}

export default Heartbeat;
