let inMemoryCache = {};

// Possibly a legacy issue with iFrame
class LocalStorage {
  static getItem = (name) => inMemoryCache[name];
  static setItem = (name, value) => (inMemoryCache[name] = value);
  static removeItem = (name) => delete inMemoryCache[name];
  static clear = () => (inMemoryCache = {});
}

class Storage {
  static getLocalStorage = () => {
    try {
      window.localStorage.getItem('test');
      return window.localStorage;
    } catch (error) {
      console.log('Could not access local storage. Creating in memory replacement.');
      return LocalStorage;
    }
  };
}

export default Storage;
