import React, { useEffect, useState } from 'react';
import RTC from '../../utils/rtc-common';
import uuidv4 from 'uuid/v4';
import nextIcon from '../../assets/new_icons/PPT-next.svg';
import prevIcon from '../../assets/new_icons/PPT-prev.svg';
import nextActiveIcon from '../../assets/new_icons/PPT-next-active.svg';
import prevActiveIcon from '../../assets/new_icons/PPT-prev-active.svg';
import wallIcon from '../../assets/new_icons/wall-icon.svg';
import shareScreenIcon from '../../assets/share-screen.svg';
import { get, post } from '../../services/api';
import { isIpad } from '../../utils/browser-util';

let timer;

export default function PresenterControls({ enablePPTControl, event, participantUuid, enableShowWall, enableShareScreen, token, pptControllerURL }) {
  const [remoteControlConnected, setRemoteControlConnected] = useState(false);
  const [prevPPTActive, setPrevPPTActive] = useState(false);
  const [nextPPTActive, setNextPPTActive] = useState(false);

  const _sendPPTControlAction = (action) => {
    if (pptControllerURL) {
      return get(`${pptControllerURL}/controller?meetingId=pando&action=${action}`);
    }
  };

  const _checkRemoteControl = () => {
    timer = setInterval(async () => {
      try {
        const response = await get(`/event/${event._id}`);
        const { isPPTControllerRunning } = response.data;
        setRemoteControlConnected(isPPTControllerRunning);
      } catch (error) {
        console.error(error);
      }
    }, 3000);
  };

  const _onPrevBtnClick = async () => {
    try {
      setPrevPPTActive(true);
      setTimeout(() => {
        setPrevPPTActive(false);
      }, 500);
      await _sendPPTControlAction('prev');
    } catch (error) {
      console.error(error);
    }
  };

  const _onNextBtnClick = async () => {
    try {
      setNextPPTActive(true);
      setTimeout(() => {
        setNextPPTActive(false);
      }, 500);
      await _sendPPTControlAction('next');
    } catch (error) {
      console.error(error);
    }
  };

  const _showWallClick = async () => {
    try {
      const { _id, socketServerUrl, studioWall } = event;
      const url = new URL(socketServerUrl);
      const socketServer = url.hostname.split('.')[0];
      window.open(
        `/wall?socketServer=${socketServer}&eventId=${_id}&studioId=${studioWall}&authToken=${token}&heartbeat=false`,
        'Wall',
        'width=1280,height=720,toolbar=0,menubar=0,location=0',
      );
    } catch (error) {
      console.error(error);
    }
  };

  const _shareScreen = async () => {
    try {
      const { _id } = event;
      const stream = await window.navigator.mediaDevices.getDisplayMedia({
        video: {
          width: { ideal: 1280, max: 1280 },
          height: { ideal: 720, max: 720 },
        },
      });
      const uuid = uuidv4();
      const rtc = new RTC(`screen-share-${uuid}-client-send`);

      const pc = await rtc.setup();
      pc.addStream(stream);

      // Wait long enough for the backend to spin up it's RTC client
      await new Promise((resolve, reject) => {
        let attemptCount = 0;
        const timerId = setInterval(async () => {
          if (attemptCount++ < 5) {
            rtc.sendMessage({ type: 'state', value: 'CONFIG_COMPLETED' });
            console.log('Waiting for READY_FOR_OFFER');
            const { type, value } = await rtc.getMessage('state');
            if (type === 'state' && value === 'READY_FOR_OFFER') {
              clearInterval(timerId);
              resolve();
            }
          } else {
            clearInterval(timerId);
            reject('Unable to get a response from the RTC Server.');
          }
        }, 1000);
      });

      rtc.startClient();
      await post(`/event/screenshare`, { eventId: _id, participantUuid, screenShareUuid: uuid, action: 'enable' });
      const screenShareTrack = stream.getTracks().find((t) => t.kind === 'video');
      screenShareTrack.onended = async () => {
        rtc.stop();
        await post(`/event/screenshare`, { eventId: _id, participantUuid, screenShareUuid: uuid, action: 'disable' });
      };
    } catch (error) {
      // TODO: Show error message on mac
      console.log(error);
    }
  };

  useEffect(() => {
    _checkRemoteControl();
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  const marginLeft = (enablePPTControl && remoteControlConnected) || (!isIpad && enableShowWall) ? 70 : 0;

  return (
    <div style={{ display: 'flex', marginLeft }}>
      {enablePPTControl && remoteControlConnected && (
        <div className='presenter-control'>
          <button style={{ marginLeft: 5 }} onClick={_onPrevBtnClick}>
            <img src={prevIcon} width={30} height={30} alt='' />
            <img src={prevActiveIcon} width={30} height={30} style={{ transition: 'opacity 0.25s', position: 'absolute', opacity: prevPPTActive ? 1 : 0 }} />
          </button>
          <div className='label'>PPT</div>
          <button style={{ marginRight: 5 }} onClick={_onNextBtnClick}>
            <img src={nextIcon} width={30} height={30} alt='' />
            <img src={nextActiveIcon} width={30} height={30} style={{ transition: 'opacity 0.25s', position: 'absolute', opacity: nextPPTActive ? 1 : 0 }} />
          </button>
        </div>
      )}
      {!isIpad && enableShowWall && (
        <div className='presenter-control'>
          <button style={{ width: '100%' }} onClick={_showWallClick}>
            <img src={wallIcon} width={30} height={30} alt='' />
            <span style={{ fontSize: 14, color: '#a9aaab', marginLeft: 12 }}>Show Wall</span>
          </button>
        </div>
      )}
      {!isIpad && enableShareScreen && (
        <div className='presenter-control' style={{ width: 160 }}>
          <button style={{ width: '100%' }} onClick={_shareScreen}>
            <img src={shareScreenIcon} width={30} height={30} alt='' />
            <span style={{ fontSize: 14, color: '#a9aaab', marginLeft: 10 }}>Share Screen</span>
          </button>
        </div>
      )}
    </div>
  );
}
