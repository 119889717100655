import React, { useState, useRef } from 'react';
import SuccessIcon from '../../assets/system-check-icons/icon-check-completed.svg';
import Button from '../../common/Button';
import Spinner from '../../common/Spinner';
import OffWallParticipantForm from '../../common/OffWallParticipantForm';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#1E2A36',
    padding: '40px',
    width: 400,
  },
};

export default function SuccessMessage({ onContinue, notesData }) {
  const stateRef = useRef();
  const [disableContinue, setDisableContinue] = useState(false);
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);

  const _onClick = () => {
    setDisableContinue(true);
    onContinue(stateRef.current);
  };

  const _onSubmit = ({ city, state, phoneNumber }) => {
    if (state.length > 0 && city.length > 0 && phoneNumber.length > 0) {
      stateRef.current = { city, state, phoneNumber };
      setShowConfirmScreen(true);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <img src={SuccessIcon} alt={''} width={160} />
        <h4 style={{ color: '#45B77A', marginTop: 10 }}>System Check Completed</h4>

        {!showConfirmScreen && <OffWallParticipantForm onAccept={_onSubmit} participantData={notesData} uiVersion='onboarding' />}

        {showConfirmScreen && (
          <>
            <div style={{ textAlign: 'left', fontSize: 14 }}>
              Please make note of the following:
              <ul style={{ listStyle: 'inherit', paddingInlineStart: 17 }}>
                <li>Your camera will be visible at all times when you are not paused. Once you are onboarded you may pause at any time.</li>
                <li>Your audio (mute/unmute) will be managed by the Pando team. When you are "ON AIR" you're mic is live.</li>
              </ul>
            </div>
            {!disableContinue && <Button type='primary' text='Confirm and Continue' containerStyle={{ marginTop: 25 }} onClick={_onClick} />}
            {disableContinue && (
              <div style={{ marginTop: 15, transform: 'scale(0.7)' }}>
                <Spinner />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
