import React, { useEffect, useRef, useState } from 'react';
import uuidv4 from 'uuid/v4';
import SocketClient from '../../utils/socket-client';
import RTCStream from '../../utils/rtc-stream';
import SpeedTest from '../../common/SpeedTest';
import Spinner from '../../common/Spinner';

export default function Wall() {
  const _videoRef = useRef(null);
  const _ref = useRef(null);

  const [speedTestSuccess, setSpeedTestSuccess] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    return () => {
      _closeWallStream();
    };
  }, []);

  useEffect(() => {
    if (speedTestSuccess === true) {
      setShowSpinner(true);
      _videoRef.current.onloadeddata = () => {
        setShowSpinner(false);
      };
    }
  }, [speedTestSuccess]);

  const _closeWallStream = () => {
    RTCStream.close(_ref.current);
  };

  const _onSpeedTestSuccess = async () => {
    setSpeedTestSuccess(true);

    const uuid = uuidv4();
    const roomName = `${uuid}-client-recv`;
    SocketClient.emit('get-wall-stream', { uuid });

    const rtc = await RTCStream.get(roomName, _videoRef.current);
    _ref.current = { rtc };
  };

  return (
    <div className='wall-stream-container'>
      {speedTestSuccess && showSpinner && <Spinner />}
      <video ref={_videoRef} autoPlay muted style={{ maxWidth: '95%', display: speedTestSuccess && !showSpinner ? 'block' : 'none' }} />
      <SpeedTest onSuccess={_onSpeedTestSuccess} />
    </div>
  );
}
