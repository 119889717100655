import React, { useState } from 'react';
import ContextMenu from '../../../common/ContextMenu';
import { getContextMenuItems } from '../../../utils/context-menu';

export default function OffWallAttendeeListItem({ id, uuid, name, role, reaction, status, onStartPrivateChat }) {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  const _onContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowContextMenu(true);
    setContextMenuPosition({ x: e.pageX, y: e.pageY });
  };

  const _onContextMenuItemSelection = async (option, subOption) => {
    switch (option.value) {
      case 1:
        onStartPrivateChat && onStartPrivateChat();
        break;
      default:
        break;
    }
  };

  const _hideContextMenu = () => {
    setShowContextMenu(false);
    setContextMenuPosition({ x: 0, y: 0 });
  };

  return (
    <div className={'table-row'} onContextMenu={_onContextMenu}>
      {showContextMenu === true && (
        <ContextMenu
          position={contextMenuPosition}
          items={getContextMenuItems(uuid, role)}
          onClose={_hideContextMenu}
          onItemSelected={_onContextMenuItemSelection}
        />
      )}
      <div className='column'>
        <span className='name'>{name}</span>
      </div>
      <div className='column'>{role}</div>
    </div>
  );
}
