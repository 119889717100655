import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './rootReducer';

export const history = createBrowserHistory();

export const store = createStore(
  createRootReducer(history),
  compose(applyMiddleware(routerMiddleware(history)))
);
