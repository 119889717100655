import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function CheckMark({ value, enabled, tooltip, onClick }) {
  const activeColor = '#70C34E';
  const inactiveColor = '#888888';

  const content = (
    <>
      {tooltip && <span className='tooltip top'>{tooltip}</span>}
      <FontAwesomeIcon
        icon={faCheck}
        color={value === true ? activeColor : inactiveColor}
        size='xs'
        style={{ padding: 2, position: 'relative', top: -2, fontSize: '0.7em' }}
      />
    </>
  );

  if (value === true) {
    return (
      <span className='check-mark visible' style={{ borderColor: activeColor }}>
        {content}
      </span>
    );
  }

  return enabled ? (
    <span className='check-mark' style={{ borderColor: inactiveColor }} onClick={onClick}>
      {content}
    </span>
  ) : null;
}
