import React, { PureComponent } from 'react';
import { post } from '../../services/api';
import ParticipantPhoto from './ParticipantPhoto';
import Spinner from '../../common/Spinner';

export default class RemoteMedia extends PureComponent {
  _videoContainerRef = React.createRef();
  _remoteFeed = undefined;
  _lastBitrate = 0;

  state = {
    active: false,
  };

  componentDidMount() {
    this._name = this.props.data.display.split('/')[0];
    this._uuid = this.props.data.display.split('/')[1];
    this._connect();

    this._reconnectTimerId = setInterval(async () => {
      if (this._isBadConnection() && !this._reconnecting) {
        const { event } = this.props.credentials;
        const { data } = await post('/breakoutRoom/participantPresent', { uuid: this._uuid, event: event._id });
        if (data.result) {
          this._reconnecting = true;
          const wait = 5000 + Math.floor(Math.random() * 15000);
          setTimeout(() => {
            if (this._isBadConnection()) {
              console.log('Possible bad connection, black video, Reconnecting...', this._name);
              this._remoteFeed.detach({
                success: () => {
                  this._connect();
                  this._reconnecting = false;
                },
                error: (error) => {
                  console.error(error);
                  this._connect();
                  this._reconnecting = false;
                },
              });
            } else {
              this._reconnecting = false;
            }
          }, wait);
        } else {
          console.log('Possible bad connection, black video, Participant not in breakout room.', this._name);
        }
      }
    }, 15000);
  }

  _isBadConnection() {
    if (this._remoteFeed && this._remoteFeed.webrtcStuff.pc) {
      const connState = this._remoteFeed.webrtcStuff.pc.connectionState;
      const states = ['closed', 'failed'];
      if (states.includes(connState)) {
        return true;
      }
    }
    return (
      !this._unmounted &&
      this._lastBitrate === 0 &&
      this._remoteFeed &&
      (!this._remoteFeed.webrtcStuff.pc || (this._remoteFeed.webrtcStuff.pc && this._remoteFeed.webrtcStuff.pc.connectionState === 'connected'))
    );
  }

  _connect() {
    if (this._unmounted) return;

    const { janusInstance, data } = this.props;
    const opaqueId = `remote-feed-${window.Janus.randomString(12)}`;

    janusInstance.attach({
      plugin: 'janus.plugin.videoroom',
      opaqueId: opaqueId,
      success: (handle) => {
        this._remoteFeed = handle;
        this._remoteFeed.simulcastStarted = false;

        clearInterval(this._statsTimerId);
        this._statsTimerId = setInterval(() => {
          if (handle.webrtcStuff.pc) {
            this._getStats(handle.webrtcStuff.pc, 'recv', 'video');
            if (this._lastBitrate > 0 && this.state.active === false) {
              this.setState({ active: true });
            }
          } else {
            this._lastBitrate = 0;
          }
        }, 3000);

        const subscribePayload = {
          request: 'join',
          room: data.room,
          ptype: 'subscriber',
          feed: data.id,
          private_id: data.private_id,
        };

        this._remoteFeed.videoCodec = data.video_codec;
        this._remoteFeed.send({ message: subscribePayload });
      },
      error: (error) => {
        console.error(error);
      },
      onmessage: (msg, jsep) => {
        const event = msg.videoroom;
        if (msg.error) {
          console.error(msg.error);
        } else if (event) {
          if (event === 'attached') {
            this._remoteFeed.rfid = msg.id;
            this._remoteFeed.rfdisplay = msg.display;
          }
        }
        if (jsep) {
          this._remoteFeed.createAnswer({
            jsep,
            media: { audioSend: false, videoSend: false },
            success: (jsep) => {
              this._remoteFeed.send({ message: { request: 'start', room: data.room }, jsep: jsep });
            },
            error: (error) => {
              console.error('WebRTC error:', error);
            },
          });
        }
      },
      onremotestream: (stream) => {
        if (!this._unmounted) {
          this._remoteStream = stream;
          const video = document.getElementById(`remote-video-${data.id}`);
          const audioOutput = localStorage.getItem('audioOutput') ? JSON.parse(localStorage.getItem('audioOutput')) : null;
          if (audioOutput) {
            video.setSinkId(audioOutput.deviceId);
          }
          video.srcObject = this._remoteStream;
        }
      },
      webrtcState: function (on) {
        if (this._remoteFeed) {
          console.log('Janus says this WebRTC PeerConnection (feed #' + this._remoteFeed.rfindex + ') is ' + (on ? 'up' : 'down') + ' now');
        }
      },
      oncleanup: () => {
        if (this._remoteStream) {
          this._remoteStream.getTracks().forEach((t) => t.stop());
        }
      },
    });
  }

  _getStats = async (pc, type, kind) => {
    let comm;
    let lastResult;

    const reportType = type === 'send' ? 'outbound-rtp' : type === 'recv' ? 'inbound-rtp' : null;
    const bytesType = type === 'send' ? 'bytesSent' : type === 'recv' ? 'bytesReceived' : null;

    if (!comm) {
      const comms = type === 'send' ? pc.getSender() : type === 'recv' ? pc.getReceivers() : null;
      if (comms && comms.length > 0) {
        comm = comms.find((s) => s.track.kind === kind);
      }
      if (!comm) return;
    }

    const stats = await comm.getStats();
    stats.forEach((report) => {
      let bytes;
      if (report.type === reportType) {
        if (report.isRemote) {
          return;
        }
        const now = report.timestamp;
        bytes = report[bytesType];
        if (this._prevStats && this._prevStats.has(report.id)) {
          this._lastBitrate = parseInt((8 * (bytes - this._prevStats.get(report.id)[bytesType])) / (now - this._prevStats.get(report.id).timestamp), 10);
        }
      }
    });
    this._prevStats = stats;
  };

  componentWillUnmount() {
    if (this._remoteFeed) {
      this._remoteFeed.detach();
    }
    if (this._remoteStream) {
      this._remoteStream.getTracks().forEach((t) => t.stop());
    }
    clearInterval(this._statsTimerId);
    clearInterval(this._reconnectTimerId);
    this._unmounted = true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.remountVideo !== this.props.remountVideo && this.props.remountVideo === true) {
      this._remountVideo();
    }
  }

  _remountVideo = () => {
    if (!this._unmounted) {
      const { current } = this._videoContainerRef;
      const oldVideo = document.getElementById(`remote-video-${this.props.data.id}`);
      if (oldVideo) {
        current.removeChild(oldVideo);
      }
      const video = document.createElement('video');
      video.id = `remote-video-${this.props.data.id}`;
      video.srcObject = this._remoteStream;
      video.autoplay = true;
      current.appendChild(video);
      video.play();
      this.props.afterRemount();
    }
  };

  render() {
    const { width, height, data, style, screenShareActive } = this.props;
    let size = {};
    if (width && height) {
      size = { width, height };
    }
    return (
      <div style={style}>
        <div
          ref={this._videoContainerRef}
          className='webcam-preview'
          style={{ ...size, fontSize: screenShareActive ? width / 7 : width / 14, position: 'relative' }}
        >
          <div className='name'>{data.display.split('/')[0]}</div>
          <video className='remote-media-video' id={`remote-video-${data.id}`} autoPlay></video>
          {this.state.active === false && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transform: 'scale(0.7)',
              }}
            >
              <Spinner />
            </div>
          )}
          {data.videoEnabled === false && data.photoUrl && <ParticipantPhoto photoUrl={data.photoUrl} />}
        </div>
      </div>
    );
  }
}
