import React, { useState } from 'react';
import ContextMenu from '../../../common/ContextMenu';
import StringUtil from '../../../utils/string-util';

export default function PrivateChatTab({ item, classes, onClose, onClick, hideAttendeeDetails }) {
  const [showPrivateChatMenu, setShowPrivateChatMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  const _onPrivateChatContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPrivateChatMenu(true);
    setContextMenuPosition({ x: e.pageX, y: e.pageY });
  };

  const _onContextMenuItemSelection = async (option) => {
    if (option) {
      if (option.value === 'close') {
        onClose && onClose(item);
      }
    }
  };

  const _hideContextMenu = () => {
    setShowPrivateChatMenu(false);
    setContextMenuPosition({ x: 0, y: 0 });
  };

  const name = StringUtil.getDisplayName(item.label, hideAttendeeDetails);
  return (
    <>
      {showPrivateChatMenu === true && (
        <ContextMenu
          position={contextMenuPosition}
          items={[{ value: 'close', label: 'Close', danger: true }]}
          onClose={_hideContextMenu}
          onItemSelected={_onContextMenuItemSelection}
        />
      )}
      <div className={classes} onClick={() => onClick(item)} style={{ marginLeft: 5 }} onContextMenu={_onPrivateChatContextMenu}>
        <span>{name}</span>
      </div>
    </>
  );
}
