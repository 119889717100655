class StringUtil {
  static generateRandomString(length, breakWords = false) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      if (breakWords && i % 5 === 0 && i !== 0) {
        result += ' ';
      } else {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
    }
    return result;
  }

  static getInitials(fullName) {
    if (fullName) {
      const parts = fullName
        .trim()
        .split(' ')
        .filter((p) => p.length);
      if (parts.length === 1) {
        return `${parts[0][0].toUpperCase()}`;
      }
      const name = parts[0];
      const lastName = parts[1];
      return `${name[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    }
    return '';
  }

  static ordinalSuffix(i) {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + 'st';
    }
    if (j === 2 && k !== 12) {
      return i + 'nd';
    }
    if (j === 3 && k !== 13) {
      return i + 'rd';
    }
    return i !== 0 ? i + 'th' : '';
  }

  static removeSpecialCharacters(str) {
    // Removing special characters rendering in windows
    return str.trim().replace(/[^\w\s]/gi, '');
  }

  static getDisplayName(name, hideDetails) {
    if (hideDetails) {
      return name.split(' ')[0];
    }
    return name;
  }
}

export default StringUtil;
