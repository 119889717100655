import { useCallback, useEffect, useState } from 'react';
import TwilioVideo from 'twilio-video';

export default function useTwilioRoom() {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [localParticipant, setLocalParticipant] = useState(null);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);

  const [screenShareEnabled, setScreenShareEnabled] = useState(false);
  const [screenshareTrack, setScreenshareTrack] = useState(null);

  const resetState = useCallback(() => {
    setRoom(null);
    setParticipants([]);
    setLocalParticipant(null);
    setVideoEnabled(true);
    setAudioEnabled(true);
    setScreenShareEnabled(false);
    setScreenshareTrack(null);
  }, []);

  const handleParticipantConnected = (participant) => {
    setParticipants((prevParticipants) => [...prevParticipants, participant]);
  };

  const handleParticipantDisconnected = (participant) => {
    setParticipants((prevParticipants) => prevParticipants.filter((p) => p.sid !== participant.sid));
  };

  const connect = useCallback(({ token, options }) => {
    TwilioVideo.connect(token, options).then(
      (room) => {
        console.log(`Successfully joined Room: ${room.name}`, room);
        setRoom(room);
        setLocalParticipant(room.localParticipant);

        room.participants.forEach((participant) => {
          setParticipants((prevParticipants) => [...prevParticipants, participant]);
        });

        room.on('participantConnected', handleParticipantConnected);
        room.on('participantDisconnected', handleParticipantDisconnected);

        window.addEventListener('beforeunload', disconnect);
        window.addEventListener('pagehide', disconnect);

        room.once('disconnected', () => {
          window.removeEventListener('beforeunload', disconnect);
          window.removeEventListener('pagehide', disconnect);
        });
      },
      (error) => {
        console.error(`Unable to connect to Room: ${error.message}`);
      },
    );
    return () => {
      if (room) {
        room.off('participantConnected', handleParticipantConnected);
        room.off('participantDisconnected', handleParticipantDisconnected);
        window.removeEventListener('beforeunload', disconnect);
        window.removeEventListener('pagehide', disconnect);
      }
    };
  }, []);

  const toggleVideo = useCallback(() => {
    if (localParticipant) {
      if (videoEnabled) {
        localParticipant.videoTracks.forEach((publication) => {
          publication.track.disable();
        });
        setVideoEnabled(false);
      } else {
        localParticipant.videoTracks.forEach((publication) => {
          publication.track.enable();
        });
        setVideoEnabled(true);
      }
    }
  }, [localParticipant, videoEnabled]);

  const toggleAudio = useCallback(() => {
    if (localParticipant) {
      if (audioEnabled) {
        localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable();
        });
        setAudioEnabled(false);
      } else {
        localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable();
        });
        setAudioEnabled(true);
      }
    }
  }, [localParticipant, audioEnabled]);

  const toggleScreenShare = useCallback(async () => {
    if (localParticipant) {
      if (screenShareEnabled) {
        localParticipant.unpublishTrack(screenshareTrack);
        screenshareTrack.stop();
        screenshareTrack.detach();
        setScreenShareEnabled(false);
        setScreenshareTrack(null);
      } else {
        const stream = await navigator.mediaDevices.getDisplayMedia({ video: { frameRate: 15 } });
        const track = new TwilioVideo.LocalVideoTrack(stream.getTracks()[0], { name: 'screenshare' });

        track.mediaStreamTrack.onended = () => {
          localParticipant.unpublishTrack(track);
          setScreenShareEnabled(false);
          setScreenshareTrack(null);
        };

        localParticipant.publishTrack(track);
        setScreenShareEnabled(true);
        setScreenshareTrack(track);
      }
    }
  }, [localParticipant, screenShareEnabled, screenshareTrack]);

  const disconnect = useCallback(() => {
    if (room) {
      room.localParticipant.tracks.forEach((publication) => {
        const track = publication.track;
        track.stop();
        track.detach();
      });
      room.disconnect();
    }
  }, [room]);

  return {
    room,
    participants,
    localParticipant,
    toggleAudio,
    toggleVideo,
    videoEnabled,
    audioEnabled,
    toggleScreenShare,
    screenshareTrack,
    screenShareEnabled,
    connect,
    disconnect,
    resetState,
  };
}
