import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountUp, faSortAmountDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { post } from '../../../services/api';
import { OFF_WALL_ROLES } from '../../../utils/user-util';
import Button from '../../../common/Button';
import Input from '../../../common/Input';
import SocketClient from '../../../utils/socket-client';
import ArrayUtil from '../../../utils/array-util';
import PaginatedItems from './PaginatedItems';
import '../AttendeeList/AttendeeList.css';
import './OffWallAttendeeList.css';

const offWallParticipantFailStatuses = ['COMPLETED', 'ARCHIVED', 'NOT_CONNECTED', 'REMOVED'];

export default class OffWallAttendeeList extends PureComponent {
  state = {
    attendees: [],
    sortedAttendees: [],
    filteredAttendees: [],
    sortBy: {
      value: 'name',
      dir: 'desc',
    },
    searchInput: '',
  };
  async componentDidMount() {
    const event = JSON.parse(localStorage.getItem('participantEvent'));
    try {
      const response = await post('/participant/list', { event, roles: OFF_WALL_ROLES });
      this.setState({ attendees: response.data }, this._sortList);
    } catch (error) {
      console.log(error);
    }
    SocketClient.joinRoom(`event-${event._id}:off-wall-participants`);
    SocketClient.addListener('off-wall-list-update', (p) => {
      const { attendees } = this.state;
      const index = attendees.findIndex((a) => a.uuid === p.uuid);
      if (index !== -1) {
        const cloned = [...attendees];
        if (offWallParticipantFailStatuses.includes(p.status) || !OFF_WALL_ROLES.includes(p.role)) {
          cloned.splice(index, 1);
          this.setState({ attendees: cloned }, this._sortList);
        } else {
          cloned[index] = p;
          this.setState({ attendees: cloned }, this._sortList);
        }
      } else {
        const joined = attendees.concat(p);
        this.setState({ attendees: joined }, this._sortList);
      }
    });
  }

  componentWillUnmount() {
    const event = JSON.parse(localStorage.getItem('participantEvent'));
    SocketClient.leaveRoom(`event-${event._id}:off-wall-participants`);
    SocketClient.removeListener('off-wall-list-update');
  }

  _sortList = (isButtonPress) => {
    const { sortBy, attendees } = this.state;
    const sorted = ArrayUtil.sort(attendees, sortBy);
    this.setState({ sortedAttendees: sorted }, () => this._filter(null, isButtonPress));
  };

  _filter = async (evt, isButtonPress) => {
    const searchInput = evt ? evt.target.value : this.state.searchInput;
    const { sortedAttendees } = this.state;
    let filteredAttendees = sortedAttendees;
    if (searchInput.length > 0) {
      filteredAttendees = sortedAttendees.filter((a) => {
        const name = `${a.firstName} ${a.lastName}`.trim().toLowerCase();
        return name.includes(searchInput.toLowerCase());
      });
    }
    if (isButtonPress) {
      this.setState({ searchInput, filteredAttendees: [] }, () => this.setState({ filteredAttendees }));
    } else {
      this.setState({ searchInput, filteredAttendees });
    }
  };

  _toggleSort = (columnName) => {
    const { sortBy } = this.state;
    if (sortBy.value === columnName) {
      sortBy.dir = sortBy.dir === 'asc' ? 'desc' : 'asc';
    } else {
      sortBy.dir = 'desc';
    }
    sortBy.value = columnName;
    this.setState({ sortBy: { ...sortBy } }, () => this._sortList(true));
  };

  _onStartPrivateChat = (participant) => {
    this.props.onStartPrivateChat && this.props.onStartPrivateChat(participant);
  };

  render() {
    const { filteredAttendees, searchInput, sortBy } = this.state;
    return (
      <>
        <div style={{ position: 'relative' }}>
          <Input type='text' name='search' style={{ width: 430, paddingRight: 20 }} onChange={this._filter} value={searchInput} />
          <FontAwesomeIcon icon={faSearch} size='sm' color='#999999' style={{ position: 'absolute', top: 5, right: 5 }} />
        </div>
        <div className='event-support-attendee-list'>
          <div className='list-header'>
            <div className='column' onClick={() => this._toggleSort('name')}>
              <span>Name</span>
              <FontAwesomeIcon icon={sortBy.value === 'name' && sortBy.dir === 'asc' ? faSortAmountDown : faSortAmountUp} size='sm' color='#999999' />
            </div>
            <div className='column' onClick={() => this._toggleSort('role')}>
              <span>Role</span>
              <FontAwesomeIcon icon={sortBy.value === 'role' && sortBy.dir === 'asc' ? faSortAmountDown : faSortAmountUp} size='sm' color='#999999' />
            </div>
          </div>
          <PaginatedItems itemsPerPage={10} items={filteredAttendees} onStartPrivateChat={this._onStartPrivateChat} />
        </div>
        <div style={{ position: 'absolute', right: 10, bottom: 10 }}>
          <Button
            type={'secondary'}
            text='Close'
            onClick={this.props.onClose}
            containerStyle={{ height: 31, width: 160, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          />
        </div>
      </>
    );
  }
}
