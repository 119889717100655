import queryString from 'query-string';
import swal from '@sweetalert/with-react';
import Storage from './storage';
import { get } from '../services/api';
import { EVENT_SUPPORT, WALL_PARTICIPANT, OFF_WALL_PARTICIPANT, OBSERVER, WALL_PRESENTER, OFF_WALL_ADMIN } from './user-util';

const { REACT_APP_BACKEND_URL } = process.env;

const localStorage = Storage.getLocalStorage();

export const getNotesUser = async (db, unid) => await get(`${REACT_APP_BACKEND_URL}/getNotesUser/${db}/query?Open&UNID=${unid}`);

export const getRoleName = (roleName_number) => {
  switch (roleName_number) {
    case '1':
      return EVENT_SUPPORT;
    case '4':
      return WALL_PARTICIPANT;
    case '5':
      return OFF_WALL_PARTICIPANT;
    case '6':
      return OBSERVER;
    case '7':
      return WALL_PRESENTER;
    case '8':
      return OFF_WALL_ADMIN;
    default:
      return 'NONE';
  }
};

export const verifyNotesData = (data, searchStr, ignoreMeetingId = false) => {
  let verifiedData = {};
  verifiedData.attendName = data.screenName || data.attendName;
  verifiedData.username = data.username;

  let spacePos = verifiedData.attendName.indexOf(' ');
  if (spacePos >= 1) {
    verifiedData.firstName = verifiedData.attendName.substr(0, spacePos);
    verifiedData.lastName = verifiedData.attendName.substr(spacePos + 1);
    if (verifiedData.lastName === '') verifiedData.lastName = ' ';
  } else {
    verifiedData.firstName = verifiedData.attendName;
    verifiedData.lastName = ' ';
  }
  verifiedData.location = data.attendLocation;
  verifiedData.phone = data.attendPhone;

  verifiedData.role = null;
  if (data.userRole) {
    verifiedData.role = getRoleName(data.userRole);
  }

  // Append the showName and roomId/meetingId from the query string
  const params = queryString.parse(searchStr);
  verifiedData.showName = params.ShowName;
  verifiedData.UNID = params.unid;
  verifiedData.studioId = params.studioId;
  verifiedData.meetingId = params.roomId;
  verifiedData.meetingDb = params.db;
  verifiedData.isOffWallTestClient = params.isOffWallTestClient;

  let notValid = !verifiedData.firstName || !verifiedData.lastName || !verifiedData.role || (!ignoreMeetingId && !verifiedData.meetingId);

  if (verifiedData.role !== OFF_WALL_ADMIN && verifiedData.role !== OFF_WALL_PARTICIPANT) {
    notValid = notValid && (!verifiedData.location || !verifiedData.phone);
  }
  if (!data.allowedIn) {
    swal({
      title: 'Not Allowed',
      text: 'You are not allowed to attend this program.',
    });
  } else if (notValid) {
    swal({
      title: 'Missing Data',
      text: 'Please contact support for further assistance.',
    });
  } else {
    const data = {
      type: 'login',
      showName: verifiedData.showName,
      meetingId: verifiedData.meetingId,
      meetingDb: verifiedData.meetingDb,
      username: verifiedData.username,
      unid: verifiedData.UNID,
      role: verifiedData.role,
      attendName: verifiedData.attendName,
      attendLocation: verifiedData.location,
      attendPhone: verifiedData.phone,
    };
    // Needs to be set in local storage to support browser refresh on connect
    localStorage.setItem('loginReportingData', JSON.stringify(data));
    return verifiedData;
  }
};
