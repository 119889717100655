import React, { PureComponent } from 'react';

import isInsideContainer from './isInsideContainer';

import SelectList from './SelectList';
import SelectListOptions from './SelectListOptions';

import './Select.css';

// https://medium.com/@Tnodes/creating-an-accessible-custom-select-component-in-react-982f2422fb9c
class Select extends PureComponent {
  state = {
    optionsOpened: false,
    focusedOption: undefined,
  };

  _containerRef = React.createRef();

  _selectRef = React.createRef();

  _optionRefs = [];

  _clearOptionRefs = () => {
    this._optionRefs = [];
  };

  _handleOptionSelected = (option) => {
    const { onChange } = this.props;
    onChange && onChange(option);
  };

  _close = () => {
    this.setState(
      {
        optionsOpened: false,
      },
      () => {
        document.body.removeEventListener('mousedown', this._handleOutsideClick);
      },
    );
  };

  _handleOutsideClick = (event) => {
    if (isInsideContainer(event.target, this._containerRef.current)) return;
    this._close();
  };

  _openOptions = () => {
    const { optionsOpened } = this.state;
    this.setState(
      {
        optionsOpened: !optionsOpened,
        focusedOption: document.activeElement.id,
      },
      () => {
        const { _optionRefs } = this;
        _optionRefs[0] && _optionRefs[0].focus();
        document.body.addEventListener('mousedown', this._handleOutsideClick);
      },
    );
  };

  _handleOpenOptions = (event) => {
    const { disabled } = this.props;
    switch (event.type) {
      case 'click':
        !disabled && this._openOptions();
        break;
      case 'keydown':
        if (event.key === 'Enter' || event.key === ' ') {
          !disabled && this._openOptions();
        }
        break;
      default:
    }
  };

  _handleOptionsEvents = (option, index, event) => {
    const { listLabel } = this.props;
    switch (event.type) {
      case 'click':
        const { optionsOpened } = this.state;
        this.setState(
          {
            optionsOpened: !optionsOpened,
          },
          () => {
            this._handleOptionSelected(option);
          },
        );
        break;
      case 'keydown':
        if (event.key === 'Enter' || event.key === ' ') {
          const { optionsOpened } = this.state;
          this.setState(
            {
              optionsOpened: !optionsOpened,
            },
            () => {
              this._handleOptionSelected(option);
              this._selectRef.current.focus();
            },
          );
        }
        if (event.key === 'ArrowUp') {
          event.preventDefault();
          const prev = index - 1;
          if (prev >= 0) {
            this._optionRefs[prev].focus();
            this.setState({ focusedOption: document.activeElement.id });
          }
        }
        if (event.key === 'ArrowDown') {
          event.preventDefault();
          const next = index + 1;
          if (next < this._optionRefs.length) {
            this._optionRefs[next].focus();
            this.setState({ focusedOption: document.activeElement.id });
          }
        }
        if (event.key === 'Escape') {
          this._close();
          this._selectRef.current.focus();
        }
        break;
      default:
    }
  };

  _setOptionRefs = (element) => {
    if (element !== null) {
      this._optionRefs.push(element);
    }
  };

  render() {
    const { optionsOpened, focusedOption } = this.state;
    const { listKey, listLabel, selected, containerStyle, small, disabled, currentOption } = this.props;
    let disabledStyle = {};
    if (disabled) {
      disabledStyle = {
        opacity: '0.5',
        pointerEvents: 'none',
        userSelect: 'none',
      };
    }

    let options = null;
    if (optionsOpened === true) {
      const { list } = this.props;
      options = (
        <SelectListOptions
          setOptionRef={this._setOptionRefs}
          options={list}
          listKey={listKey}
          listLabel={listLabel}
          focusedOption={focusedOption}
          selected={selected}
          handleOptionsEvents={this._handleOptionsEvents}
          small={small}
        />
      );
    } else {
      this._clearOptionRefs();
    }

    return (
      <div ref={this._containerRef} className='select-container' style={{ ...disabledStyle, ...containerStyle }}>
        <SelectList
          handleOpenOptions={this._handleOpenOptions}
          openOptions={optionsOpened}
          selectRef={this._selectRef}
          currentOption={currentOption}
          small={small}
        />
        {options}
      </div>
    );
  }
}

export default Select;
