import React, { Component } from 'react';
import { debounce } from 'throttle-debounce';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { get, put } from '../../services/api';
import DownloadNotesButton from './DownloadNotesButton';
import editorTooltips from '../../utils/editor-tooltips';
import TabHeader from './Chat/TabHeader';
import '../../css/Wysiwyg.css';

export default class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
    this._debouncedUpdateNotesOnServer = debounce(1000, this._updateNotesOnServer);
  }

  async componentDidMount() {
    try {
      const { participantData } = this.props;
      const { data } = await get(`/eventParticipantNotes?eventParticipantId=${participantData._id}`);
      const contentBlock = htmlToDraft(data);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({ editorState });
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show) {
      this.editor.focus();
    }
  }

  _setEditorReference = (ref) => {
    if (ref) {
      this.editor = ref;
      this.editor.focus();
    }
  };

  _onEditorStateChange = (state) => {
    this.setState({ editorState: state });
    this._debouncedUpdateNotesOnServer();
  };

  _updateNotesOnServer = () => {
    const { participantData } = this.props;
    const { editorState } = this.state;
    const notes = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    put('/eventParticipantNotes', {
      eventParticipantId: participantData._id,
      notes,
    }).catch(console.error);
  };

  render() {
    const { show, event } = this.props;
    const { editorState } = this.state;
    return (
      <div style={{ display: show ? 'flex' : 'none', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: 1, height: '100%' }}>
          <TabHeader isPrivateChat={false} text='Please download your notes before the program ends. Unsaved notes might not be retrievable.' />
          <Editor
            editorRef={this._setEditorReference}
            editorState={editorState}
            stripPastedStyles={true}
            wrapperClassName='wysiwyg-wrapper'
            editorClassName='custom-scrollbar'
            toolbarClassName='toolbar-class'
            toolbarStyle={{}}
            editorStyle={{ fontFamily: 'Arial', color: 'white', fontSize: 13, padding: 10 }}
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'history'],
              inline: {
                options: ['bold', 'italic', 'underline'],
              },
            }}
            onEditorStateChange={this._onEditorStateChange}
            toolbarCustomButtons={[<DownloadNotesButton event={event} editorState={editorState} />]}
            localization={{
              translations: editorTooltips,
            }}
          />
        </div>
      </div>
    );
  }
}
