import React, { PureComponent } from 'react';
import { debounce } from 'throttle-debounce';
import RemoteMedia from './RemoteMedia';

let GRID_TEMPLATE = {};

let nextGridIndex = 1;
for (let rows = 1; rows <= 5; rows++) {
  for (let columns = 1; columns <= rows; columns++) {
    while (nextGridIndex <= rows * columns) {
      GRID_TEMPLATE[nextGridIndex++] = { rows, columns };
    }
  }
}

class ParticipantsGrid extends PureComponent {
  _gridContainerRef = React.createRef();
  _itemMargin = 2;
  _gridMargin = 20;

  state = {
    loaded: false,
    maxWidth: 0,
    itemWidth: 0,
    itemHeight: 0,
  };

  componentDidMount() {
    this.debouncedUpdateMaxWidth = debounce(250, this._updateMaxWidth);
    window.addEventListener('resize', this.debouncedUpdateMaxWidth);
    this.setState({
      loaded: true,
      maxWidth: window.innerWidth - 320,
      maxHeight: window.innerHeight,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedUpdateMaxWidth);
  }

  componentDidUpdate(prevProps) {
    const { collapsed, transitionEnded } = this.props;
    if (prevProps.collapsed !== collapsed) {
      this._updateMaxWidth();
    }
    if (prevProps.transitionEnded !== transitionEnded) {
      this._updateMaxWidth();
      this.props.onResetTransitionStatus();
    }
  }

  _updateMaxWidth = () => {
    this.setState({
      maxWidth: this.props.hideSideBar ? window.innerWidth - 20 : window.innerWidth - 320,
      maxHeight: window.innerHeight,
    });
  };

  _createRemoteMedia = (remoteParticipant, width, height) => {
    let w = width - this._itemMargin * 2;
    let h = height - this._itemMargin * 2;
    const { janusInstance, credentials } = this.props;
    return (
      <RemoteMedia
        key={remoteParticipant.id}
        credentials={credentials}
        janusInstance={janusInstance}
        width={w}
        height={h}
        data={remoteParticipant}
        remountVideo={this.props.remountVideo}
        afterRemount={this.props.afterRemount}
        style={{ margin: this._itemMargin, background: 'black' }}
        screenShareActive={this.props.screenShareActive}
      />
    );
  };

  _renderGrid = () => {
    const { participants, collapsed } = this.props;
    const connectedParticipants = participants.length;

    if (connectedParticipants > 0 && this._gridContainerRef.current !== null) {
      let width = 0;
      let height = 0;

      if (collapsed) {
        const columns = connectedParticipants <= 5 ? 1 : 2;
        this._gridContainerRef.current.style.width = columns === 1 ? '15%' : '30%';

        const { offsetWidth, offsetHeight } = this._gridContainerRef.current;

        width = Math.round((offsetWidth - this._gridMargin) / columns);
        height = Math.round(width * (3 / 4));

        const maxRows = Math.ceil(connectedParticipants / columns);
        const itemsHeight = height * maxRows;

        if (itemsHeight > offsetHeight - this._gridMargin) {
          height = Math.round((offsetHeight - this._gridMargin) / maxRows);
          width = Math.round(height * (4 / 3));
        }

        this.setState({ itemWidth: width, itemHeight: height });

        return participants.map((participant) => {
          return this._createRemoteMedia(participant, width, height);
        });
      } else {
        this._gridContainerRef.current.style.width = '100%';
        let { offsetHeight } = this._gridContainerRef.current;
        const grid = GRID_TEMPLATE[connectedParticipants];
        width = Math.round((this.state.maxWidth - this._gridMargin) / grid.columns);
        height = Math.round(width * (3 / 4));

        if (height * grid.rows > offsetHeight - this._gridMargin) {
          height = Math.round((offsetHeight - this._gridMargin) / grid.rows);
          width = Math.round(height * (4 / 3));
        }

        this.setState({ itemWidth: width, itemHeight: height });

        return participants.map((participant) => {
          return this._createRemoteMedia(participant, width, height);
        });
      }
    } else if (this._gridContainerRef.current !== null) {
      if (collapsed) {
        this._gridContainerRef.current.style.width = `${0}px`;
      } else {
        this._gridContainerRef.current.style.width = '100%';
      }
    }
    return null;
  };

  render() {
    const { itemWidth } = this.state;
    const { participants } = this.props;
    const grid = GRID_TEMPLATE[participants.length];
    const maxWidth = participants.length > 0 ? (itemWidth + this._itemMargin * 2) * grid.columns : 'auto';

    return (
      <div ref={this._gridContainerRef} className='participants-grid'>
        <div className='grid-layout' style={{ justifyContent: this.props.collapsed ? 'flex-start' : 'center', overflow: 'hidden', maxWidth }}>
          {this._renderGrid()}
        </div>
      </div>
    );
  }
}

export default ParticipantsGrid;
