import React, { PureComponent } from 'react';
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';
import swal from '@sweetalert/with-react';
import Button from '../../common/Button';
import QuestionIcon from '../../assets/icon-question-mark-circle.svg';
import GreenCheckIcon from '../../assets/icon-check-circle.svg';
import ErrorCircleIcon from '../../assets/icon-error-circle.svg';
import ChromeIcon from '../../assets/browser-chrome.svg';
import EdgeIcon from '../../assets/browser-edge.svg';
import FirefoxIcon from '../../assets/browser-firefox.svg';
import IeIcon from '../../assets/browser-ie.svg';
import OperaIcon from '../../assets/browser-opera.svg';
import SafariIcon from '../../assets/browser-safari.svg';
import BrowserIcon from '../../common/Icons/BrowserIcon';
import { loadScript, isMobile } from '../../utils/browser-util';

const { REACT_APP_BROADCAST_SYSTEM_CHECK_VIDEO_URL } = process.env;

export default class BroadcastSystemCheck extends PureComponent {
  state = {
    browserSupported: true,
    mediaSupported: false,
    mediaIconState: QuestionIcon,
    browserString: '',
    browserIcon: null,
    yesButtonDisabled: false,
  };

  async componentDidMount() {
    await loadScript('https://player.live-video.net/1.20.0/amazon-ivs-player.min.js', () => {
      if (window.IVSPlayer.isPlayerSupported) {
        this.videoElement = document.getElementById('off-wall-player');
        this.player = window.IVSPlayer.create();
        this.player.attachHTMLVideoElement(this.videoElement);
        this.player.load(REACT_APP_BROADCAST_SYSTEM_CHECK_VIDEO_URL);

        if (isMobile()) {
          this._applyMobileStyles();
          window.addEventListener('orientationchange', () => {
            setTimeout(this._applyMobileStyles, 1000);
          });
        }
      } else {
        console.error('The current browser does not support the Amazon IVS player.');
      }
    });
    this._validateDevice(this.props.location.state);
  }

  _applyMobileStyles() {
    const orientation = sessionStorage.getItem('orientation');
    const body = document.getElementsByTagName('body')[0];
    const rootDiv = document.getElementById('root');

    body.classList.add('scrollbars');
    rootDiv.classList.add('scrollbars');
    if (orientation === 'portrait') {
      rootDiv.style.height = '100%';
      rootDiv.style.width = 'auto';
    } else {
      rootDiv.style.height = 'auto';
      rootDiv.style.width = '100%';
    }

    const systemCheckContainer = document.getElementById('system-check-container');
    const width = orientation === 'portrait' ? window.innerHeight : window.innerWidth;
    const height = orientation === 'portrait' ? window.innerWidth : window.innerHeight;
    const widthScale = width / systemCheckContainer.offsetWidth;
    const heightScale = height / systemCheckContainer.offsetHeight;
    if (widthScale < 1 || heightScale < 1) {
      const scale = Math.min(widthScale, heightScale);
      systemCheckContainer.style.transform = `scale(${scale})`;
    }
  }

  async _validateDevice() {
    let returnString = '';
    let isSupported = false;

    console.log('browserName: ' + browserName);
    console.log('browserVersion: ' + browserVersion);
    console.log('osName: ' + osName);
    console.log('osVersion: ' + osVersion);

    switch (browserName) {
      case 'Chrome':
        returnString = browserName + ' ' + browserVersion;
        this.setState({ browserIcon: ChromeIcon });
        isSupported = true;
        break;
      case 'Safari':
        this.setState({ browserIcon: SafariIcon });
        if (browserVersion >= 13) {
          returnString = browserName + ' ' + browserVersion;
          isSupported = true;
        } else {
          returnString = browserName + ' ' + browserVersion;
          isSupported = false;
        }
        break;
      case 'Mobile Safari':
        this.setState({ browserIcon: SafariIcon });
        if (browserVersion >= 13) {
          returnString = browserName + ' ' + browserVersion;
          isSupported = true;
        } else {
          returnString = browserName + ' ' + browserVersion;
          isSupported = false;
        }
        break;
      case 'Edge':
        this.setState({ browserIcon: EdgeIcon });
        if (browserVersion >= 80) {
          returnString = browserName + ' ' + browserVersion;
          isSupported = true;
        } else {
          returnString = browserName + ' ' + browserVersion;
          isSupported = false;
        }
        break;
      case 'Firefox':
        returnString = browserName + ' ' + browserVersion;
        this.setState({ browserIcon: FirefoxIcon });
        isSupported = true;
        break;
      case 'IE':
        returnString = browserName + ' ' + browserVersion;
        this.setState({ browserIcon: IeIcon });
        isSupported = false;
        break;
      case 'Opera':
        returnString = browserName + ' ' + browserVersion;
        this.setState({ browserIcon: OperaIcon });
        isSupported = false;
        break;
      default:
        returnString = browserName + ' ' + browserVersion;
        isSupported = false;
        break;
    }

    if (isSupported == true) {
      this.setState({ browserSupported: true, browserString: returnString });
    } else {
      this.setState({ browserSupported: false, browserString: returnString });
      swal({
        title: 'Browser Not Supported',
        text: returnString,
      });
    }
  }

  confirmYes = () => {
    this.setState({ mediaSupported: true, mediaIconState: GreenCheckIcon });
    const { browserSupported } = this.state;
    if (browserSupported == true) {
      swal({
        title: 'System Check Confirmation',
        text: 'You have passed the system check',
      });
    } else {
      swal({
        title: 'Warning',
        text: 'Browser not supported',
      });
    }
  };

  confirmNo = () => {
    this.setState({ mediaSupported: false, mediaIconState: ErrorCircleIcon });
    swal({
      title: 'Warning',
      text: 'One or more system check components have failed. Please refer to your meeting confirmation email or connection instructions for help desk support contact information.',
    });
  };

  render() {
    const { browserSupported, browserString, mediaSupported, browserIcon, mediaIconState, yesButtonDisabled } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#181f26',
          overflow: 'auto',
        }}
      >
        <div id='system-check-container'>
          <div
            style={{
              padding: '30px 40px',
              margin: '30px 0px',
              backgroundColor: 'rgb( 31, 43, 55)',
              color: '#fff',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', verticalAlign: 'top', alignItems: 'center', justifyContent: 'space-between' }}>
              <h3 style={{ verticalAlign: 'top' }}>Broadcast System Check</h3>
            </div>
            <div>
              <table>
                <tbody>
                  <tr style={{ backgroundColor: '#24313F' }}>
                    <td style={{ verticalAlign: 'top', padding: 10, fontSize: 12 }}>Browser Check</td>
                    <td style={{ verticalAlign: 'top', padding: 10, textAlign: 'center' }}>
                      {browserSupported ? <img src={GreenCheckIcon} alt={''} width={24} /> : <img src={ErrorCircleIcon} alt={''} width={24} />}
                    </td>
                    <td style={{ verticalAlign: 'top', padding: 10 }}>
                      <div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                          <BrowserIcon icon={browserIcon} name={browserString} />
                        </div>
                        <div style={{ fontSize: 13, marginTop: 25 }}>Supported browsers for the purposes of a green check are: </div>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10 }}>
                          <BrowserIcon icon={ChromeIcon} name='Google Chrome' />
                          <BrowserIcon icon={SafariIcon} name='Safari' />
                          <BrowserIcon icon={EdgeIcon} name='Microsoft Edge' />
                          <BrowserIcon icon={FirefoxIcon} name='Firefox' />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: '#24313F' }}>
                    <td style={{ verticalAlign: 'top', padding: 10, fontSize: 12 }}>Media Playback Check</td>
                    <td style={{ verticalAlign: 'top', padding: 10, textAlign: 'center' }}>
                      <img src={mediaIconState} alt={''} width={24} />
                    </td>
                    <td>
                      <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                        <div id='videoPresentationContainer' style={{ width: 200 }}>
                          <video id='off-wall-player' muted={false} controls={true} style={{ width: 200 }} playsInline={true} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', paddingLeft: 40 }}>
                          <label style={{ fontSize: 13, marginLeft: 5, marginBottom: 5 }}>
                            Please click the play button on the left.
                            <br /> Can you see and hear the video clip?
                          </label>
                          <div style={{ paddingTop: 10, display: 'flex', flexDirection: 'row' }}>
                            <Button
                              text='Yes'
                              onClick={this.confirmYes}
                              disabled={yesButtonDisabled}
                              containerStyle={{ height: 30, width: 110, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            />
                            <Button
                              type={'secondary'}
                              text='No'
                              onClick={this.confirmNo}
                              containerStyle={{ height: 30, width: 110, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
