import React, { PureComponent } from 'react';
import { loadScript, loadStyle, isIpad } from '../utils/browser-util';

export default class VideoJS extends PureComponent {
  _videoRef = React.createRef();

  async componentDidMount() {
    const { onLoad } = this.props;

    window.HELP_IMPROVE_VIDEOJS = false;

    await loadStyle('/video-js.css');
    await loadScript('/video.min.js');
    await loadScript('https://player.live-video.net/1.20.0/amazon-ivs-videojs-tech.min.js', () => {
      window.registerIVSTech(window.videojs);
      onLoad && onLoad();
    });
  }

  _onPlaying = () => {
    const { onPlaying } = this.props;
    if (this._rebufferingTimer) {
      clearTimeout(this._rebufferingTimer);
    }
    onPlaying && onPlaying();
  };

  _onEnded = () => {
    const { onEnded, loop } = this.props;
    onEnded && onEnded();
    if (loop === true) {
      setTimeout(() => {
        this._stream();
      }, 3000);
    }
  };

  _onError = (e) => {
    const { onError } = this.props;
    onError && onError(e);
    setTimeout(() => {
      this._stream();
    }, 3000);
  };

  _onRebuffering = () => {
    this._rebufferingTimer = setTimeout(() => {
      const playerState = this.player.getIVSPlayer().getState();
      const { PlayerState } = this.player.getIVSEvents();
      const { PLAYING } = PlayerState;
      if (playerState !== PLAYING) {
        this._stream();
      }
    }, 30000);
  };

  _onReady = () => {
    const { onReady } = this.props;
    onReady && onReady();
  };

  _stream = () => {
    this.player = window.videojs(
      this._videoRef.current,
      {
        techOrder: ['AmazonIVS'],
      },
      () => {
        const { PlayerState, PlayerEventType } = this.player.getIVSEvents();
        const { READY, PLAYING, ENDED } = PlayerState;
        const { ERROR, REBUFFERING } = PlayerEventType;

        this.player.getIVSPlayer().removeEventListener(READY, this._onReady);
        this.player.getIVSPlayer().addEventListener(READY, this._onReady);

        this.player.getIVSPlayer().removeEventListener(PLAYING, this._onPlaying);
        this.player.getIVSPlayer().addEventListener(PLAYING, this._onPlaying);

        this.player.getIVSPlayer().removeEventListener(ENDED, this._onEnded);
        this.player.getIVSPlayer().addEventListener(ENDED, this._onEnded);

        this.player.getIVSPlayer().removeEventListener(ERROR, this._onError);
        this.player.getIVSPlayer().addEventListener(ERROR, this._onError);

        this.player.getIVSPlayer().removeEventListener(REBUFFERING, this._onRebuffering);
        this.player.getIVSPlayer().addEventListener(REBUFFERING, this._onRebuffering);

        if (isIpad) {
          this.player.on('fullscreenchange', () => {
            if (!document.webkitIsFullScreen) {
              const orientation = sessionStorage.getItem('orientation');
              this.props.onIpadExitFullScreen && this.props.onIpadExitFullScreen(orientation);
            }
          });
        }

        this.player.src(this.props.url);
        this.player.play();
      },
    );
  };

  render() {
    return (
      <video
        ref={this._videoRef}
        className='video-js vjs-big-play-centered'
        id='off-wall-player'
        style={{ width: '100%', height: '100%', backgroundColor: '#181f26' }}
        onContextMenu={(e) => e.preventDefault()}
        controls
        autoPlay
        playsInline
      />
    );
  }
}
