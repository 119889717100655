import React from 'react';
import StudioQueue from '../../assets/studio_queue.svg';
import useWindowResize from '../../hooks/useWindowResize';
import { getMessageBoxTransform } from '../../utils/browser-util';
import './Messages.css';

function InStudioQueue({ show, queueSize }) {
  const { height } = useWindowResize();
  return (
    <div className='message-box' style={getMessageBoxTransform(height)}>
      <span className='show-id'>{show}</span>
      <img src={StudioQueue} alt='In studio queue' />
      <span className='text green'>
        Please stand by while we assign you to a position on the PANDO wall. Once assigned you will begin receiving audio and video from the studio.
      </span>
      <div className='message-footer'>
        <span className='top'>{queueSize === 1 ? 'There is' : 'There are'}</span>
        <div className='position'>{queueSize}</div>
        <span className='bottom'>{(queueSize === 1 ? 'Guest' : 'Guests') + ' in the Studio Queue'}</span>
      </div>
    </div>
  );
}

export default InStudioQueue;
