import React, { useState, useEffect, useRef } from 'react';
import MediaDeviceUtil from '../../utils/media-device-util';
import GreenCheckIcon from '../../assets/icon-check-circle.svg';
import ErrorCircleIcon from '../../assets/icon-error-circle.svg';
import QuestionMarkCircleIcon from '../../assets/icon-question-mark-circle.svg';
import PlayIcon from '../../assets/system-check-icons/button-play.svg';
import Button from '../../common/Button';
import Select from '../../common/Select';
import ProgressBar from '../../common/SpeedTest/ProgressBar';
import { faCircle, faStop } from '@fortawesome/free-solid-svg-icons';
import { isSafari } from '../../utils/browser-util';

export default function AudioCheck({ title, onSuccess, onFailure, onUpdateProgress }) {
  const _audioRef = useRef(null);
  const _audioStreamRef = useRef(null);

  const _micBarTimerRef = useRef(null);
  const _audioRecordingTimerRef = useRef(null);

  const [validMicrophone, setValidMicrophone] = useState(null);
  const [validSpeaker, setValidSpeaker] = useState(null);
  const [validRecording, setValidRecording] = useState(null);

  const [audioRecord, setAudioRecord] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [micLevel, setMicLevel] = useState(0);

  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState(null);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState(null);

  const [sampleAudioPlayed, setSampleAudioPlayed] = useState(false);
  const [audioRecordingPlayed, setAudioRecordingPlayed] = useState(false);

  const recordingDurationMs = 5000;

  const _getAudioDevices = async () => {
    const availableDevices = await MediaDeviceUtil.getAvailableDevices(false);
    const _inputDevices = availableDevices.filter((device) => device.kind === 'audioinput');
    const _outputDevices = availableDevices.filter((device) => device.kind === 'audiooutput');

    if (selectedAudioInputDevice) {
      const _selectedAudioInputDevice = _inputDevices.find((device) => device.deviceId === selectedAudioInputDevice.deviceId);
      if (!_selectedAudioInputDevice) {
        setSelectedAudioInputDevice(_inputDevices[0]);
      }
    }

    if (selectedAudioOutputDevice) {
      const _selectedAudioOutputDevice = _outputDevices.find((device) => device.deviceId === selectedAudioOutputDevice.deviceId);
      if (!_selectedAudioOutputDevice) {
        setSelectedAudioOutputDevice(_outputDevices[0]);
      }
    }
    setAudioInputDevices(_inputDevices);
    setAudioOutputDevices(_outputDevices);

    return { inputDevices: _inputDevices, outputDevices: _outputDevices };
  };

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', _getAudioDevices);
    (async () => {
      const { inputDevices, outputDevices } = await _getAudioDevices();

      const storedAudioInput = localStorage.getItem('audioInput');
      const storedAudioOutput = localStorage.getItem('audioOutput');

      const defaultAudioOutput = outputDevices.find((dev) => dev.isDefault === true) || outputDevices[0];
      let _selectedAudioOutput = defaultAudioOutput;
      if (storedAudioOutput && storedAudioOutput.length !== 0) {
        const _audioOutputObj = JSON.parse(storedAudioOutput);
        const _audioOutput = outputDevices.find((v) => _audioOutputObj && v.deviceId === _audioOutputObj.deviceId);
        if (_audioOutput) {
          _selectedAudioOutput = _audioOutput;
        }
      }

      const defaultAudioInput = inputDevices.find((dev) => dev.isDefault === true) || inputDevices[0];
      let _selectedAudioInput = defaultAudioInput;
      if (storedAudioInput && storedAudioInput.length !== 0) {
        const _audioInputObj = JSON.parse(storedAudioInput);
        const _audioInput = inputDevices.find((v) => _audioInputObj && v.deviceId === _audioInputObj.deviceId);
        if (_audioInput) {
          _selectedAudioInput = _audioInput;
        }
      }

      setSelectedAudioInputDevice(_selectedAudioInput);
      setSelectedAudioOutputDevice(_selectedAudioOutput);
    })();

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', _getAudioDevices);
      if (_audioRecordingTimerRef.current) {
        clearInterval(_audioRecordingTimerRef.current);
      }
      if (_micBarTimerRef.current) {
        clearInterval(_micBarTimerRef.current);
      }
      if (_audioStreamRef.current) {
        _audioStreamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (audioRecord) {
        audioRecord.pause();
      }
      if (isPlaying) {
        _pauseAudio();
      }
    };
  }, []);

  useEffect(() => {
    if (selectedAudioInputDevice) {
      (async () => {
        try {
          if (_audioStreamRef.current) {
            _audioStreamRef.current.getTracks().forEach((track) => {
              track.stop();
            });
          }
          _audioStreamRef.current = await MediaDeviceUtil.getUserMedia(
            {
              audio: {
                channelCount: 1,
                sampleSize: { exact: 16 },
                autoGainControl: true,
                volume: 1,
                latency: 0,
                deviceId: { exact: selectedAudioInputDevice.deviceId },
              },
            },
            false,
            false,
          );

          localStorage.setItem('audioInput', JSON.stringify(selectedAudioInputDevice));
          _audioRef.current.muted = true;

          const audioContext = new AudioContext();
          const microphone = audioContext.createMediaStreamSource(_audioStreamRef.current);

          const analyser = audioContext.createAnalyser();
          microphone.connect(analyser);
          analyser.fftSize = 512;

          const bufferLength = analyser.frequencyBinCount;
          const dataArray = new Uint8Array(bufferLength);

          clearInterval(_micBarTimerRef.current);
          _micBarTimerRef.current = setInterval(() => {
            analyser.getByteFrequencyData(dataArray);
            const average = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
            setMicLevel(average);
          }, 100);
        } catch (error) {
          console.log('applyAudioInputChange error', error);
        }
      })();
    }
  }, [selectedAudioInputDevice]);

  useEffect(() => {
    if (selectedAudioOutputDevice) {
      try {
        if (_audioRef.current && typeof _audioRef.current.setSinkId === 'function') {
          _audioRef.current.setSinkId(selectedAudioOutputDevice.deviceId);
          localStorage.setItem('audioOutput', JSON.stringify(selectedAudioOutputDevice));
        }
      } catch (error) {
        console.log('applyAudioOutputChange error', error);
      }
    }
  }, [selectedAudioOutputDevice]);

  useEffect(() => {
    if (validRecording === true && validSpeaker === true && validMicrophone === true) {
      onSuccess && onSuccess();
    } else if (validRecording === false || validSpeaker === false || validMicrophone === false) {
      onFailure && onFailure();
    }
  }, [validRecording, validSpeaker, validMicrophone]);

  useEffect(() => {
    if (validMicrophone !== null) onUpdateProgress({ canSeeAudioSignal: validMicrophone });
  }, [validMicrophone]);

  useEffect(() => {
    if (validSpeaker !== null) onUpdateProgress({ canHearTestSound: validSpeaker });
  }, [validSpeaker]);

  useEffect(() => {
    if (validRecording !== null) onUpdateProgress({ canHearSelf: validRecording });
  }, [validRecording]);

  const _playRecording = () => {
    if (audioRecord) {
      _enableAudioStreamTracks(false);

      setAudioRecordingPlayed(true);
      setProgress(0);
      if (!isSafari()) {
        audioRecord.setSinkId(selectedAudioOutputDevice.deviceId);
      }
      audioRecord.play();

      _audioRecordingTimerRef.current = setInterval(() => {
        setProgress((p) => {
          const nextVal = p + 1;
          if (nextVal >= 100) {
            clearInterval(_audioRecordingTimerRef.current);
            _enableAudioStreamTracks();
          }
          return nextVal;
        });
      }, recordingDurationMs / 100);
    }
  };

  const _recordAudio = async () => {
    if (_audioRef.current.currentTime !== 0) {
      _pauseAudio();
    }

    const mediaRecorder = new MediaRecorder(_audioStreamRef.current);
    setProgress(0);
    setIsRecording(true);
    setAudioRecordingPlayed(false);

    mediaRecorder.start();

    setTimeout(() => {
      mediaRecorder.stop();
    }, recordingDurationMs + 250);

    _audioRecordingTimerRef.current = setInterval(() => {
      setProgress((p) => {
        const nextVal = p + 1;
        if (nextVal >= 100) {
          clearInterval(_audioRecordingTimerRef.current);
        }
        return nextVal;
      });
    }, recordingDurationMs / 100);

    const audioChunks = [];
    const _onDataAvailable = (event) => {
      audioChunks.push(event.data);
    };

    const _onStop = () => {
      const audioBlob = new Blob(audioChunks, { type: 'video/mp4' });
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      setAudioRecord(audio);
      setIsRecording(false);
      mediaRecorder.removeEventListener('dataavailable', _onDataAvailable);
      mediaRecorder.removeEventListener('stop', _onStop);
    };

    mediaRecorder.addEventListener('dataavailable', _onDataAvailable);
    mediaRecorder.addEventListener('stop', _onStop);
  };

  const _playAudio = () => {
    _enableAudioStreamTracks(false);
    setSampleAudioPlayed(true);

    if (_audioRef.current && _audioRef.current.paused) {
      setIsPlaying(true);
      _audioRef.current.muted = false;
      _audioRef.current.play();
    } else {
      _pauseAudio();
    }
  };

  const _pauseAudio = () => {
    setIsPlaying(false);
    if (_audioRef.current) {
      _audioRef.current.muted = true;
      _audioRef.current.pause();
      _audioRef.current.currentTime = 0;
    }
    _enableAudioStreamTracks();
  };

  const _enableAudioStreamTracks = (enabled = true) => {
    if (_audioStreamRef.current) {
      _audioStreamRef.current.getTracks().forEach((track) => {
        track.enabled = enabled;
      });
    }
  };

  const _onAudioEnded = () => {
    _enableAudioStreamTracks();
    _audioRef.current.muted = true;
  };

  const recordText = audioRecord ? (
    `When you press the 'Play' button, can you hear the audio?`
  ) : (
    <>
      Please record a 5 second sample and verify it's good.
      <br />
      You can say something like "My name is John Smith and I'm excited to join Pando!".
    </>
  );

  const hideSpeakerDropdown = isSafari();

  return (
    <div style={{ width: '100%' }}>
      <h3>{title}</h3>
      <div>
        <table cellSpacing={3} style={{ width: '100%' }}>
          <tbody>
            <tr style={{ backgroundColor: 'rgb( 31, 43, 55)' }}>
              <td style={{ verticalAlign: 'top', padding: 10, fontSize: 12 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <div style={{ width: 320, marginBottom: 10 }}>
                      <Select
                        list={audioInputDevices}
                        listKey='deviceId'
                        listLabel='label'
                        onChange={(selected) => {
                          setSelectedAudioInputDevice(selected);
                        }}
                        selected={selectedAudioInputDevice && selectedAudioInputDevice.deviceId}
                        currentOption={selectedAudioInputDevice && selectedAudioInputDevice.label}
                        containerStyle={{ width: '100%' }}
                        small
                      />
                    </div>
                    <ProgressBar title='' value={micLevel} />
                  </div>
                  <div style={{ margin: '0 30px' }}>
                    <p>When you talk, can you see the green audio level bar to the left move?</p>
                    <div>
                      <Button
                        type={validMicrophone ? 'primary' : 'secondary'}
                        text='Yes'
                        containerStyle={{ padding: '2px 40px' }}
                        onClick={() => setValidMicrophone(true)}
                      />
                      <Button type='secondary' text='No' containerStyle={{ padding: '2px 40px' }} onClick={() => setValidMicrophone(false)} />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr style={{ display: !validMicrophone ? 'none' : 'table-row', backgroundColor: 'rgb( 31, 43, 55)' }}>
              <td style={{ verticalAlign: 'top', padding: 10, fontSize: 12 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    {!hideSpeakerDropdown && (
                      <div style={{ width: 320 }}>
                        <Select
                          list={audioOutputDevices}
                          listKey='deviceId'
                          listLabel='label'
                          onChange={(selected) => {
                            setSelectedAudioOutputDevice(selected);
                          }}
                          selected={selectedAudioOutputDevice && selectedAudioOutputDevice.deviceId}
                          currentOption={selectedAudioOutputDevice && selectedAudioOutputDevice.label}
                          containerStyle={{ width: '100%' }}
                          small
                        />
                      </div>
                    )}
                    {hideSpeakerDropdown && (
                      <div style={{ width: 320 }}>
                        <Select
                          list={[{ label: 'Default Speaker', deviceId: 'default' }]}
                          listKey='deviceId'
                          listLabel='label'
                          selected={'default'}
                          currentOption={'Default Speaker'}
                          containerStyle={{ width: '100%' }}
                          disabled
                          small
                        />
                      </div>
                    )}
                    <audio ref={_audioRef} src='https://pando-admin.s3.amazonaws.com/public/test-sound.mp3' style={{ opacity: 0 }} onEnded={_onAudioEnded} />
                  </div>
                  <div style={{ margin: '0 30px' }}>
                    <p>When you press 'Play Sample' button, can you hear the sound?</p>
                    <div>
                      <Button
                        type={isPlaying ? 'danger' : 'primary'}
                        icon={isPlaying ? faStop : PlayIcon}
                        iconType={isPlaying ? 'FontAwesome' : 'svg'}
                        iconStyle={{ width: 12, height: 12, marginRight: 15 }}
                        text={isPlaying ? 'Stop Sample' : 'Play Sample'}
                        containerStyle={{ padding: '2px 40px' }}
                        onClick={_playAudio}
                      />
                      <Button
                        type={validSpeaker ? 'primary' : 'secondary'}
                        text='Yes'
                        containerStyle={{ padding: '2px 40px' }}
                        onClick={() => {
                          _pauseAudio();
                          setValidSpeaker(true);
                        }}
                        disabled={!sampleAudioPlayed}
                      />
                      <Button
                        type='secondary'
                        text='No'
                        containerStyle={{ padding: '2px 40px' }}
                        onClick={() => {
                          _pauseAudio();
                          setValidSpeaker(false);
                        }}
                        disabled={!sampleAudioPlayed}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr style={{ display: !validSpeaker ? 'none' : 'table-row', backgroundColor: 'rgb(45, 64, 81)' }}>
              <td style={{ verticalAlign: 'top', padding: 10, fontSize: 12 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: 320, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                      <ProgressBar title='' value={progress} />
                    </div>
                  </div>
                  <div style={{ margin: '0 30px' }}>
                    <p>{recordText}</p>
                    {!audioRecord && (
                      <Button
                        type='danger'
                        icon={faCircle}
                        iconType='FontAwesome'
                        iconStyle={{ fontSize: 9, marginRight: 10 }}
                        text='Record'
                        containerStyle={{ padding: '2px 40px' }}
                        onClick={_recordAudio}
                        disabled={isRecording}
                      />
                    )}

                    {audioRecord && (
                      <div style={{ display: 'flex' }}>
                        {audioRecord && (
                          <Button
                            type='primary'
                            icon={PlayIcon}
                            iconType='svg'
                            iconStyle={{ width: 12, height: 12 }}
                            text='Play'
                            containerStyle={{ padding: '2px 40px' }}
                            onClick={_playRecording}
                          />
                        )}
                        <Button
                          type={validRecording ? 'primary' : 'secondary'}
                          text='Yes'
                          containerStyle={{ padding: '2px 40px' }}
                          disabled={!audioRecordingPlayed}
                          onClick={() => {
                            audioRecord.pause();
                            setValidRecording(true);
                          }}
                        />
                        <Button
                          type='secondary'
                          text='No'
                          containerStyle={{ padding: '2px 40px' }}
                          disabled={!audioRecordingPlayed}
                          onClick={() => {
                            audioRecord.pause();
                            setValidRecording(false);
                            setProgress(0);
                            setAudioRecord(null);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
