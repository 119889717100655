import React from 'react';
import parseHtml from 'html-react-parser';
import StringUtil from '../../../utils/string-util';

function MessageBox({ message, credentials, hideAttendeeDetails }) {
  const _getUserInitials = (identity) => {
    const identityParts = identity.split(' ');
    if (hideAttendeeDetails) {
      return StringUtil.removeSpecialCharacters(`${identityParts[0][0].toUpperCase()}`.trim());
    }
    return StringUtil.removeSpecialCharacters(`${identityParts[0][0].toUpperCase()}${identityParts[1] ? identityParts[1][0].toUpperCase() : ''}`.trim());
  };

  const { user } = message;
  const userInitials = _getUserInitials(user);

  let isOwnMessage = false;
  let messageTextClass = 'text';

  if (user === credentials.identity) {
    isOwnMessage = true;
  }

  function detectLinks(text) {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => `<a href=${url} target='_blank'>${url}</a>`);
  }

  return (
    <div className={isOwnMessage ? 'janus-message-box own' : 'janus-message-box'}>
      <div className='user-avatar'>{userInitials}</div>
      <div className='message'>
        <div className={messageTextClass}>
          <React.Fragment>{parseHtml(detectLinks(message.message))}</React.Fragment>
        </div>
      </div>
    </div>
  );
}

export default MessageBox;
