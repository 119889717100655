import React from 'react';

export default function BrowserIcon({ icon, name }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 110 }}>
      <img src={icon} alt='' style={{ width: 50 }} />
      <div style={{ fontSize: 10, color: '#DDD' }}>{name}</div>
    </div>
  );
}
