import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function CollapseButton({ value, onClick, style }) {
  return (
    <div className='collapse-button' onClick={onClick} style={style}>
      {value === true ? (
        <FontAwesomeIcon icon={faChevronRight} size='sm' color='#1e2a36' />
      ) : (
        <FontAwesomeIcon icon={faChevronLeft} size='sm' color='#1e2a36' />
      )}
    </div>
  );
}
