import React, { useEffect, useState, useRef } from 'react';
import swal from '@sweetalert/with-react';
import Logo from '../../assets/pando_branches_logo.png';
import BreakoutRoomIcon from '../../assets/breakout_room.svg';
import './Messages.css';

function EndBreakoutRoom({ wait }) {
  const _timerId = useRef(null);
  const [secondsLeft, setSecondsLeft] = useState(wait / 1000);

  useEffect(() => {
    _timerId.current = setInterval(() => {
      setSecondsLeft((secondsLeft) => secondsLeft - 1);
    }, 1000);
    return () => {
      clearInterval(_timerId.current);
    };
  }, []);

  useEffect(() => {
    if (secondsLeft <= 0) {
      clearInterval(_timerId.current);
      swal.close();
    }
  }, [secondsLeft]);

  return (
    <div className='horizontal-message-box'>
      <div className='header'>
        <img src={Logo} alt='Pando Branches' />
      </div>
      <div className='content'>
        <div className='left'>
          <div className='text'>
            <div>Ending Breakout Room in {secondsLeft < 0 ? 0 : Math.round(secondsLeft)} seconds.</div>
          </div>
        </div>
        <div className='right'>
          <img src={BreakoutRoomIcon} alt='Breakout room' />
        </div>
      </div>
    </div>
  );
}

export default EndBreakoutRoom;
