import React, { useState, useEffect, forwardRef } from 'react';
import Popover from 'react-popover';
import IconButton from './IconButton';
import TextButton from './TextButton';
import Button from '../../common/Button';
import { isSafari } from '../../utils/browser-util';
import Settings from './Settings';
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash, faShare, faShareSquare, faCog, faListAlt, faComment } from '@fortawesome/free-solid-svg-icons';
import ParticipantPhoto from './ParticipantPhoto';

const LocalMedia = (props, ref) => {
  const {
    name,
    loadTestStream,
    audioEnabled,
    videoEnabled,
    onToggleAudio,
    onToggleVideo,
    onToggleShareScreen,
    onToggleSettings,
    onRemountVideo,
    isHost,
    isPresenter,
    room,
    isSharingScreen,
    settingsOpened,
    showSettings,
    changeDevices,
    participant,
    onRequestSupport,
    supportRequested,
    isTechSupportRoom,
    localMediaReady,
    screenShareActive,
    showParticipantList,
    onToggleParticipantList,
    breakoutStartTime,
    maxRooms,
  } = props;

  const [timer, setTimer] = useState('');

  useEffect(() => {
    const _timer = setInterval(_startTimer, 1000);
    return () => {
      if (_timer) clearInterval(_timer);
    };
  }, []);

  const _startTimer = () => {
    const now = new Date().getTime();
    const start = breakoutStartTime.getTime();
    let diff = Math.round((now - start) / 1000);
    const h = Math.floor(diff / (60 * 60));
    diff -= h * 60 * 60;
    const m = Math.floor(diff / 60);
    diff -= m * 60;
    const s = diff;
    setTimer(`${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`);
  };

  const columnStyle = { display: 'flex', flexDirection: 'column' };
  let canShareScreen;

  if (!isSafari() && (isHost || isPresenter)) {
    canShareScreen = true;
  } else {
    canShareScreen = false;
  }

  if (screenShareActive && !isSharingScreen) {
    canShareScreen = false;
  }

  return (
    <>
      <div className='local-media'>
        {loadTestStream && <div className='local-participant-name'>{name}</div>}
        <video ref={ref} autoPlay playsInline muted></video>
        {videoEnabled === false && participant && participant.photoUrl && <ParticipantPhoto photoUrl={participant.photoUrl} />}
        {localMediaReady && (
          <div className='media-controls'>
            <div className='media'>
              <IconButton onIcon={faMicrophone} offIcon={faMicrophoneSlash} value={audioEnabled} onChange={onToggleAudio} tooltip='Mic' />
              <IconButton onIcon={faVideo} offIcon={faVideoSlash} value={videoEnabled} onChange={onToggleVideo} tooltip='Camera' />
              {canShareScreen ? (
                <IconButton offIcon={faShare} onIcon={faShareSquare} value={isSharingScreen} onChange={onToggleShareScreen} tooltip='Share Screen' />
              ) : null}
              {isTechSupportRoom && isHost ? (
                <IconButton
                  onIcon={faComment}
                  offIcon={faListAlt}
                  value={showParticipantList}
                  onChange={onToggleParticipantList}
                  tooltip={showParticipantList ? 'Show chat' : 'Show participants'}
                />
              ) : null}
              {!loadTestStream && (
                <Popover
                  isOpen={showSettings}
                  body={<Settings participant={participant} onDeviceChange={changeDevices} />}
                  onOuterAction={onToggleSettings}
                  tipSize={6}
                  place='right'
                  style={{ fill: '#1e272f', zIndex: 99999 }}
                >
                  <IconButton onIcon={faCog} offIcon={faCog} value={settingsOpened} onChange={onToggleSettings} tooltip='Device Settings' />
                </Popover>
              )}
              {!isTechSupportRoom && (
                <TextButton
                  onText={'HELP'}
                  offText={'HELP'}
                  customClass={supportRequested ? 'pulse' : null}
                  value={supportRequested}
                  onChange={onRequestSupport}
                  tooltip='Tech Support'
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div style={columnStyle}>
        <div className='room-header'>
          <div>{room.index <= maxRooms ? `Breakout Room ${room.index}` : `Tech Support ${room.index - 15}`}</div>
          <span style={{ margin: '0 5px', color: '#a9aaab', fontSize: 14 }}>{timer}</span>
        </div>
        {loadTestStream && <Button text='Remount' onClick={onRemountVideo} containerStyle={{ marginBottom: 20 }} />}
      </div>
    </>
  );
};

export default forwardRef(LocalMedia);
