import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Input from '../../common/Input';
import Button from '../../common/Button';

const styles = {
  inputContainer: { margin: 5 },
  input: { fontSize: 16, marginBottom: 15, marginTop: 5 },
  button: { width: '100%', margin: '30px 0 15px 0', fontSize: 16 },
};

function LoginForm({ onSubmit }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  const handleInputChange = useCallback((e) => {
    const { value, name } = e.target;
    if (name === 'firstName') {
      setFirstNameError('');
      setFirstName(value);
    } else {
      setLastNameError('');
      setLastName(value);
    }
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!firstName) {
        setFirstNameError('First name is required');
      }

      if (!lastName) {
        setLastNameError('Last name is required');
      }

      if (firstName && lastName) {
        onSubmit({ firstName, lastName });
      }
    },
    [firstName, lastName, onSubmit],
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Input
        type='text'
        name='firstName'
        label='First Name'
        value={firstName}
        onChange={handleInputChange}
        style={styles.input}
        containerStyle={styles.inputContainer}
        hasError={firstNameError.length > 0}
        errorMessage={firstNameError}
        stacked
      />
      <Input
        type='text'
        name='lastName'
        label='Last Name'
        value={lastName}
        onChange={handleInputChange}
        style={styles.input}
        containerStyle={styles.inputContainer}
        hasError={lastNameError.length > 0}
        errorMessage={lastNameError}
        stacked
      />
      <Button text='Continue' type='submit' onClick={handleSubmit} containerStyle={styles.button} />
    </form>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
