import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import OffWallAttendeeListItem from './OffWallAttendeeListItem';
import './OffWallAttendeeList.css';

export default function PaginatedItems({ itemsPerPage, items, onStartPrivateChat }) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items, items.length]);

  // Invoke when user click to request another page.
  const _handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };

  return (
    <>
      {currentItems &&
        currentItems.map((item, index) => {
          const myUuid = localStorage.getItem('uuid');
          if (item.uuid === myUuid) {
            return null;
          }
          return (
            <OffWallAttendeeListItem
              index
              id={item._id}
              key={index}
              name={`${item.firstName} ${item.lastName}`.trim()}
              role={item.role}
              reaction={item.reactionStatus}
              status={item.status}
              uuid={item.uuid}
              onStartPrivateChat={() => onStartPrivateChat(item)}
            />
          );
        })}
      <ReactPaginate
        previousLabel='<'
        nextLabel='>'
        breakLabel='...'
        breakClassName='break-me'
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={_handlePageClick}
        containerClassName='pagination'
        activeClassName='active'
        // eslint-disable-next-line no-unused-vars
        hrefBuilder={(page, pageCount, selected) => (page >= 1 && page <= pageCount ? `/page/${page}` : '#')}
        hrefAllControls
      />
    </>
  );
}
