import React, { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { store } from '../../store';
import SocketClient from '../../utils/socket-client';
import hashCode from '../../utils/hash-code';
import LocalMedia from './LocalMedia';
import ParticipantList from './ParticipantList';
import ParticipantsGrid from './ParticipantsGrid';
import CollapseButton from './CollapseButton';
import TimeUtil from '../../utils/time-util';
import MediaDeviceUtil from '../../utils/media-device-util';
import { isIpad } from 'react-device-detect';
import '../../css/JanusBreakoutRoom.css';
import Chat from './chat/Chat';
import Lobby from './Lobby';
import API from './API';
import ScreenShareRoom from './ScreenShareRoom';
import swal from '@sweetalert/with-react';
import Storage from '../../utils/storage';
import JoinBreakoutRoom from '../../common/Messages/JoinBreakoutRoom';
import EndBreakoutRoom from '../../common/Messages/EndBreakoutRoom';
import WaitingForHost from '../../common/Messages/WaitingForHost';
import { axiosInstance, setStudioId, setToken } from '../../services/api';
import addReportingEvent from '../../utils/reporting-event.js';

const { REACT_APP_JANUS_SERVER_HOST } = process.env;
const localStorage = Storage.getLocalStorage();

class JanusBreakoutRoom extends PureComponent {
  janusInstance = undefined;
  localStream = undefined;
  videoRoom = undefined;
  localVideoRef = React.createRef();

  _myId = undefined;
  _privateId = undefined;
  _screenShareRoomId = undefined;
  _reconnectCounter = 0;

  constructor(props) {
    super(props);
    const audioEnabled = localStorage.getItem('breakout-room-audio-muted') ? false : true;
    const videoEnabled = localStorage.getItem('breakout-room-video-muted') ? false : true;

    this.state = {
      connected: false,
      remoteParticipants: [],
      isHost: false,
      canShareScreen: false,
      isSharingScreen: false,
      currentPresenter: null,
      remountVideo: false,
      currentHost: null,
      hideSideBar: false,
      showSettings: false,
      supportRequested: false,
      localMediaReady: false,
      credentials: undefined,
      waitingInLobby: false,
      validToken: true,
      screenShareActive: false,
      showParticipantList: true,
      collapsedTransitionEnded: false,
      doesRoomExist: null,
      breakoutStartTime: null,
      audioEnabled,
      videoEnabled,
    };
  }

  async componentDidMount() {
    const { loadTestStream } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get('accessToken');

    const studioId = localStorage.getItem('studioId');
    if (!axiosInstance.defaults.headers.common['x-pando-studio-id']) {
      setStudioId(studioId);
    }

    let result;
    if (loadTestStream) {
      this.eventId = urlParams.get('eventId');
      result = await API.getTokenForTestClient(this.eventId);
    } else {
      result = await API.verifyToken(this.token);
      this.eventId = result.credentials.event._id;
    }
    this.janusServerSuffix = result.credentials.janusServerSuffix;

    const { valid, credentials } = result;

    if (valid) {
      const { uuid, socketServerUrl, token: authToken } = credentials;
      setToken(authToken);
      this.uuid = uuid;
      if (!SocketClient.socket || !SocketClient.socket.connected) {
        await SocketClient.setup(socketServerUrl, this.uuid, this.eventId, studioId, authToken);
      }
      SocketClient.joinRoom(this.uuid);

      const _backToStudioQueue = () => {
        const { connected } = this.state;
        if (connected === false) {
          swal.close();
          this._janusDestroyed();
        } else {
          this.setState({ connected: false }, () => {
            this.disconnect(true);
          });
        }
      };

      SocketClient.addListener('breakout-stop', async ({ wait }) => {
        await swal({
          buttons: {},
          className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
          content: <EndBreakoutRoom wait={wait} />,
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
        _backToStudioQueue();
      });
      SocketClient.addListener('send-to-studio-queue', _backToStudioQueue);
      SocketClient.addListener('send-to-room', async () => {
        if (this.state.connected) {
          SocketClient.removeListener('message');
          SocketClient.removeListener('set-as-breakout-room-host');
          SocketClient.removeListener('remove-breakout-room-host');
          SocketClient.leaveRoom(this.opaqueId);
          this.disconnect();
        }

        if (!this.props.loadTestStream) {
          const { valid, credentials } = await API.verifyToken(this.token);
          this.janusServerSuffix = credentials.janusServerSuffix;

          await TimeUtil.sleep(1000);
          if (valid) {
            const newState = this.resetState();
            this.setState({ ...newState }, () => {
              this.setup(credentials);
            });
          }
        }
      });

      if (credentials.room !== null) {
        this.setup(credentials);
      }
    } else {
      // Invalid token
      this.setState({ validToken: false });
    }
  }

  componentWillUnmount() {
    SocketClient.leaveRoom(this.uuid);
    SocketClient.leaveRoom(this.opaqueId);
    SocketClient.removeAllListeners();
    clearInterval(this._reconnectTimer);
    this._unmounted = true;
  }

  _addListeners = () => {
    SocketClient.removeListener('message');
    SocketClient.removeListener('set-as-breakout-room-host');
    SocketClient.removeListener('remove-breakout-room-host');

    SocketClient.addListener('message', (data) => {
      if (data.roomName === this.opaqueId) {
        const { type } = data;
        if (type === 'make-presenter') {
          const { display } = data.item;
          const uuid = display.split('/')[1];
          if (this.state.credentials.uuid === uuid) {
            this.setState({ canShareScreen: true, isPresenter: true, currentPresenter: data.item });
          } else {
            this.setState({ currentPresenter: data.item });
          }
        } else if (type === 'make-participant') {
          const { display } = data.item;
          const uuid = display.split('/')[1];
          if (this.state.credentials.uuid === uuid && this.state.isSharingScreen) {
            this._stopScreenShare();
          }
          this.setState({ canShareScreen: false, isPresenter: false, currentPresenter: null });
        } else if (type === 'set-current-host') {
          this.setState({ currentHost: data.host });
        } else if (type === 'update-media-status') {
          const { id, audioEnabled, videoEnabled, currentPresenter, photoUrl } = data;
          const { remoteParticipants } = this.state;
          const remoteParticipantIndex = remoteParticipants.map((p) => p.id).indexOf(id);
          if (remoteParticipantIndex !== -1) {
            const newremoteParticipants = [...remoteParticipants];
            newremoteParticipants[remoteParticipantIndex].audioEnabled = audioEnabled;
            newremoteParticipants[remoteParticipantIndex].videoEnabled = videoEnabled;
            if (videoEnabled === false && photoUrl) {
              newremoteParticipants[remoteParticipantIndex].photoUrl = photoUrl;
            } else {
              newremoteParticipants[remoteParticipantIndex].photoUrl = null;
            }
            this.setState({ remoteParticipants: newremoteParticipants });
          }
          if (currentPresenter) {
            this.setState({ currentPresenter });
          }
        } else if (type === 'participant-joined') {
          const { audioEnabled, videoEnabled, currentPresenter, credentials } = this.state;
          SocketClient.emit('message', {
            roomName: this.opaqueId,
            message: {
              type: 'update-media-status',
              id: this._myId,
              audioEnabled,
              videoEnabled,
              currentPresenter,
              photoUrl: credentials.participant.photoUrl,
            },
          });
        }
      }
    });

    SocketClient.addListener('set-as-breakout-room-host', () => {
      this.setState({ isHost: true, canShareScreen: true, currentHost: this.state.credentials.uuid }, () => {
        SocketClient.emit('message', { roomName: this.opaqueId, message: { type: 'set-current-host', host: this.state.currentHost } });
      });
    });

    SocketClient.addListener('remove-breakout-room-host', () => {
      if (this.state.isSharingScreen) {
        this._stopScreenShare();
      }
      let { showParticipantList } = this.state;
      if (this.isTechSupportRoom) {
        showParticipantList = false;
      }
      this.setState({ isHost: false, canShareScreen: false, currentHost: null, showParticipantList }, () => {
        SocketClient.emit('message', { roomName: this.opaqueId, message: { type: 'set-current-host', host: this.state.currentHost } });
      });
    });
  };

  setup = (credentials) => {
    const { room, uuid, maxRooms } = credentials;
    this.opaqueId = `breakout-room-${room._id}`;
    this.roomId = hashCode(room._id);
    this._screenShareRoomId = hashCode(`screenshare-${room._id}`);

    if (REACT_APP_JANUS_SERVER_HOST) {
      this.serverUrl = `wss://${REACT_APP_JANUS_SERVER_HOST}/${this.janusServerSuffix}`;
    } else {
      this.serverUrl = `wss://${window.location.host}/${this.janusServerSuffix}`;
    }

    this.isTechSupportRoom = room.index > maxRooms;
    const showParticipantList = this.isTechSupportRoom ? false : true;

    SocketClient.joinRoom(this.opaqueId);
    this._addListeners();

    const isHost = uuid === room.host;

    this.setState(
      {
        isHost,
        credentials,
        showParticipantList,
        currentHost: room.host,
        canShareScreen: isHost,
      },
      () => {
        this.connect();
        this._reconnectTimer = setInterval(async () => {
          if (
            this.videoRoom &&
            this.videoRoom.webrtcStuff.pc &&
            ['closed', 'failed'].includes(this.videoRoom.webrtcStuff.pc.connectionState) &&
            !this._reconnecting &&
            !this._unmounted
          ) {
            this._reconnecting = true;
            if (++this._reconnectCounter === 10) {
              await swal({
                title: 'Connection Error',
                text: 'We seem to be having trouble with your connection. Please check your connection and reload the app.',
              });
              window.location.reload();
            }
            console.log('Janus disconnected. Attempting to reconnect...');
            await this._reconnect();
          }
        }, 3000);
      },
    );
  };

  _reconnect = async () => {
    await new Promise((resolve) => {
      this.janusInstance.reconnect({
        success: () => {
          resolve();
          this._reconnecting = false;
          this._reconnectCounter = 0;
        },
        error: () => {
          this._reconnecting = false;
        },
      });
    });
    this.videoRoom.send({
      message: { request: 'leave' },
      success: () => {
        this.setState({ remoteParticipants: [] }, () => {
          this._addListeners();
          this.videoRoom.detach();
          this.connect(true);
        });
      },
      error: console.error,
    });
  };

  resetState = () => ({
    connected: false,
    remoteParticipants: [],
    isHost: false,
    isPresenter: false,
    canShareScreen: false,
    isSharingScreen: false,
    audioEnabled: true,
    videoEnabled: true,
    currentPresenter: null,
    remountVideo: false,
    currentHost: null,
    hideSideBar: false,
    showSettings: false,
    supportRequested: false,
    localMediaReady: false,
    screenShareActive: false,
  });

  _isHost = (uuid) => {
    const { credentials } = this.state;
    const { host } = credentials.room;
    return uuid === host;
  };

  _janusDestroyed = () => {
    console.log(new Date(), 'Janus instance destroyed!!');
    const { videoEnabled } = this.state;
    const {
      router: {
        location: { state: participantInfo, search },
      },
    } = store.getState();
    const prevRoute = window.location.pathname;
    let searchParams = new URLSearchParams(search);
    searchParams.delete('accessToken');
    searchParams.delete('auto-connect');

    if (this.props.loadTestStream) {
      this.props.onBreakoutRoomStop();
    } else {
      addReportingEvent('LEAVE_BREAKOUT_ROOM', participantInfo.event._id, participantInfo.uuid, this.roomId);
      store.dispatch(
        push({ pathname: '/connect', search: `${searchParams.toString()}&auto-connect=true`, state: { ...participantInfo, prevRoute, videoEnabled } }),
      );
    }
  };

  roomExists = (roomId) => {
    return new Promise((resolve, reject) => {
      this.videoRoom.send({
        message: { request: 'exists', room: roomId },
        success: (response) => {
          const { videoroom, exists } = response;
          if (videoroom === 'success' && exists === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        },
        error: reject,
      });
    });
  };

  disconnect = (destroyInstance = false) => {
    if (this.localStream) {
      this.localStream.getTracks().forEach((t) => t.stop());
    }

    if (this.videoRoom) {
      this.videoRoom.send({
        message: { request: 'leave' },
        success: () => {
          this.videoRoom.detach();

          if (destroyInstance) {
            if (this.props.loadTestStream) {
              this.props.loadTestStream.getTracks().forEach((t) => t.stop());
            }
            this.janusInstance.destroy();
          }
        },
        error: (error) => {
          console.log('An error occured leaving the room', error);
        },
      });
    } else {
      if (destroyInstance && this.janusInstance) this.janusInstance.destroy();
    }
  };

  _joinBreakoutRoom = () => {
    const { event, uuid, room } = this.state.credentials;
    if (!this.props.loadTestStream) {
      addReportingEvent('ENTER_BREAKOUT_ROOM', event._id, uuid, room._id);
    }
  };

  connect = async (isReconnect = false) => {
    const { room, startTime, maxRooms } = this.state.credentials;

    if (!this.props.loadTestStream && !isReconnect) {
      await swal({
        buttons: {},
        closeOnClickOutside: false,
        closeOnEsc: false,
        className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
        content: (
          <JoinBreakoutRoom
            onClick={() => {
              swal.close();
              this._joinBreakoutRoom();
            }}
            roomName={room.index <= maxRooms ? `Breakout Room ${room.index}` : `Tech Support Room ${room.index - 15}`}
          />
        ),
      });
    }

    const iceServers = await API.getIceServers();
    if (!this._unmounted) {
      window.Janus.init({
        debug: false,
        dependencies: window.Janus.useDefaultDependencies(),
        callback: () => {
          if (!window.Janus.isWebrtcSupported) {
            console.error('No WebRTC support... ');
          }
          this.janusInstance = new window.Janus({
            server: this.serverUrl,
            iceServers,
            success: () => {
              this.setState({ connected: true, breakoutStartTime: new Date(startTime) });

              const { isHost, credentials } = this.state;
              const { uuid, identity } = credentials;

              this.janusInstance.attach({
                plugin: 'janus.plugin.videoroom',
                opaqueId: this.opaqueId,
                success: async (handle) => {
                  this.videoRoom = handle;

                  const joinRoomRequest = {
                    request: 'join',
                    room: this.roomId,
                    ptype: 'publisher',
                    id: hashCode(uuid),
                    display: `${identity}/${uuid}`,
                  };

                  const _connect = async () => {
                    if (this.state.isHost || this.isTechSupportRoom) {
                      const exists = await this.roomExists(this.roomId);
                      if (exists === true) {
                        this._joinRoom(joinRoomRequest);
                      } else {
                        console.log('creating room');
                        this.videoRoom.send({
                          message: {
                            request: 'create',
                            publishers: 17,
                            is_private: false,
                            room: this.roomId,
                            fir_freq: 1,
                            bitrate: 200000,
                            audiocodec: 'opus',
                            videocodec: 'vp8',
                          },
                          success: (result) => {
                            const event = result.videoroom;
                            if (event) {
                              this.setState({ doesRoomExist: true });
                              this._joinRoom(joinRoomRequest);
                            }
                          },
                        });
                      }
                    } else {
                      const exists = await this.roomExists(this.roomId);
                      if (exists) {
                        this._joinRoom(joinRoomRequest);
                        this.setState({ doesRoomExist: true });
                      } else {
                        setTimeout(_connect, 1000);
                        this.setState({ doesRoomExist: false });
                      }
                    }
                  };
                  _connect();
                },
                // webrtcState: function () {
                //   console.log('webrtcState', arguments);
                // },
                // iceState: function () {
                //   console.log('iceState', arguments);
                // },
                // mediaState: function () {
                //   console.log('mediaState', arguments);
                // },
                // slowLink: function () {
                //   console.log('slowLink', arguments);
                // },
                error: (error) => {
                  console.error('Error attaching plugin - videoroom', error);
                },
                onmessage: async (msg, jsep) => {
                  const event = msg.videoroom;
                  if (event) {
                    if (event === 'joined') {
                      this._myId = msg.id;
                      this._privateId = msg.private_id;
                      this._publishOwnFeed();
                      SocketClient.emit('message', { roomName: this.opaqueId, message: { type: 'participant-joined' } });

                      if (msg.publishers) {
                        for (let publisher of msg.publishers) {
                          this._newRemoteFeed(publisher);
                        }
                      }
                    } else if (event === 'event') {
                      if (msg.configured === 'ok') {
                        this.setState({ localMediaReady: true });
                      }
                      if (msg.publishers) {
                        for (let publisher of msg.publishers) {
                          this._newRemoteFeed(publisher);
                        }
                      } else if (msg.leaving) {
                        this._removeRemoteFeed(msg.leaving);
                      } else if (msg.unpublished && msg.unpublished !== 'ok') {
                        this._removeRemoteFeed(msg.unpublished);
                      }
                    }
                  }

                  if (jsep) {
                    this.videoRoom.handleRemoteJsep({ jsep });
                  }
                },
                onlocalstream: (stream) => {
                  this.localStream = stream;
                  if (this.localVideoRef.current) {
                    this.localVideoRef.current.srcObject = stream;
                  }
                },
                oncleanup: () => {
                  if (this.localStream) {
                    this.localStream.getTracks().forEach((t) => t.stop());
                  }
                },
              });
            },
            error: (error) => {
              console.error('error', error);
            },
            destroyed: this._janusDestroyed,
          });
        },
      });
    }
  };

  _joinRoom = (request) => {
    this.videoRoom.send({ message: request });
  };

  _removeRemoteFeed = (publisherId) => {
    const { remoteParticipants } = this.state;
    const remoteParticipantIndex = remoteParticipants.map((p) => p.id).indexOf(publisherId);
    if (remoteParticipantIndex !== -1) {
      const newremoteParticipants = [...remoteParticipants];
      newremoteParticipants.splice(remoteParticipantIndex, 1);

      const { currentPresenter } = this.state;
      if (currentPresenter && currentPresenter.id === publisherId) {
        this.setState({ remoteParticipants: newremoteParticipants, currentPresenter: null });
      } else {
        this.setState({ remoteParticipants: newremoteParticipants });
      }
    }
  };

  _newRemoteFeed = (publisher) => {
    const { remoteParticipants } = this.state;
    const remoteParticipantIndex = remoteParticipants.map((p) => p.id).indexOf(publisher.id);
    if (remoteParticipantIndex === -1) {
      this.setState({
        remoteParticipants: remoteParticipants.concat({ ...publisher, private_id: this._privateId, room: this.roomId, audioEnabled: true, videoEnabled: true }),
      });
    }
  };

  _publishOwnFeed = async () => {
    const { loadTestStream } = this.props;
    const { participant } = this.state.credentials;

    let options;
    if (!loadTestStream) {
      options = {
        media: {
          audioRecv: false,
          videoRecv: false,
          audioSend: true,
          videoSend: true,
        },
      };

      if (participant.selectedDevices) {
        const availableDevices = await MediaDeviceUtil.getAvailableDevices();

        const { videoInput, audioInput } = participant.selectedDevices;

        const savedVideoDevice = localStorage.getItem('videoInput') && JSON.parse(localStorage.getItem('videoInput'));
        const savedAudioDevice = localStorage.getItem('audioInput') && JSON.parse(localStorage.getItem('audioInput'));

        let selectedVideoInput;
        let selectedAudioInput;

        if (savedVideoDevice) {
          selectedVideoInput = availableDevices.find((d) => d.kind === 'videoinput' && d.label === savedVideoDevice.label.replace('Default - ', ''));
        } else {
          selectedVideoInput = availableDevices.find((d) => d.kind === 'videoinput' && d.label === videoInput.label.replace('Default - ', ''));
        }

        if (savedAudioDevice) {
          selectedAudioInput = availableDevices.find((d) => d.kind === 'audioinput' && d.label === savedAudioDevice.label.replace('Default - ', ''));
        } else {
          selectedAudioInput = availableDevices.find((d) => d.kind === 'audioinput' && d.label === audioInput.label.replace('Default - ', ''));
        }

        if (selectedVideoInput) {
          options.media.video = { deviceId: selectedVideoInput.deviceId };
        }
        if (selectedAudioInput) {
          options.media.audio = { deviceId: selectedAudioInput.deviceId };
        }
      }

      // Hardcode 320x240 for breakout rooms for now
      options.media.video = options.media.video || {};
      options.media.video.width = 320;
      options.media.video.height = 240;
    } else {
      options = { stream: loadTestStream };
    }

    this.videoRoom.createOffer({
      ...options,
      success: (jsep) => {
        this.videoRoom.send({
          message: {
            request: 'configure',
            audio: true,
            video: true,
          },
          success: () => {
            const { audioEnabled, videoEnabled, credentials } = this.state;
            const _muteTimerId = setInterval(() => {
              let muteAudioResult = audioEnabled;
              if (!audioEnabled) {
                muteAudioResult = this.videoRoom.muteAudio();
              }
              let muteVideoResult = videoEnabled;
              if (!videoEnabled) {
                muteVideoResult = this.videoRoom.muteVideo();
              }
              if (muteAudioResult && muteVideoResult) {
                clearInterval(_muteTimerId);
                SocketClient.emit('message', {
                  roomName: this.opaqueId,
                  message: {
                    type: 'update-media-status',
                    id: this._myId,
                    audioEnabled,
                    videoEnabled,
                    photoUrl: credentials.participant.photoUrl,
                  },
                });
              }
            }, 1000);
          },
          jsep: jsep,
        });
      },
      error: (error) => {
        console.error('WebRTC error', error);
      },
    });
  };

  _unpublishOwnFeed = () => {
    this.videoRoom.send({ message: { request: 'unpublish' } });
  };

  _stopScreenShare = () => {
    this.setState({ isSharingScreen: false, screenShareActive: false });
  };

  _toggleShareScreen = async ({ value }) => {
    this.setState({ isSharingScreen: value });
  };

  _onToggleAudio = () => {
    this.setState({ audioEnabled: !this.state.audioEnabled }, () => {
      const { audioEnabled, videoEnabled, credentials } = this.state;
      if (!audioEnabled) {
        localStorage.setItem('breakout-room-audio-muted', audioEnabled);
        this.videoRoom.muteAudio();
      } else {
        localStorage.removeItem('breakout-room-audio-muted');
        this.videoRoom.unmuteAudio();
      }
      SocketClient.emit('message', {
        roomName: this.opaqueId,
        message: { type: 'update-media-status', id: this._myId, audioEnabled, videoEnabled, photoUrl: credentials.participant.photoUrl },
      });
    });
  };

  _onToggleVideo = () => {
    this.setState({ videoEnabled: !this.state.videoEnabled }, () => {
      const { videoEnabled, audioEnabled, credentials } = this.state;
      if (!videoEnabled) {
        localStorage.setItem('breakout-room-video-muted', videoEnabled);
        this.videoRoom.muteVideo();
      } else {
        localStorage.removeItem('breakout-room-video-muted');
        this.videoRoom.unmuteVideo();
      }
      SocketClient.emit('message', {
        roomName: this.opaqueId,
        message: { type: 'update-media-status', id: this._myId, audioEnabled, videoEnabled, photoUrl: credentials.participant.photoUrl },
      });
    });
  };

  _onMakePresenter = (item) => {
    this.setState({ currentPresenter: item }, () => {
      SocketClient.emit('message', { roomName: this.opaqueId, message: { type: 'make-presenter', item } });
    });
  };

  _onMakeParticipant = (item) => {
    this.setState({ currentPresenter: null }, () => {
      SocketClient.emit('message', { roomName: this.opaqueId, message: { type: 'make-participant', item } });
    });
  };

  _remountVideo = () => {
    this.setState({ remountVideo: true });
  };

  _changeDevices = (deviceId, deviceType) => {
    let { audioEnabled, videoEnabled, credentials } = this.state;
    let options = { media: {} };
    if (deviceType === 'video') {
      options.media = {
        video: { deviceId },
        replaceVideo: true,
      };
      videoEnabled = true;
    } else {
      options.media = {
        audio: { deviceId },
        replaceAudio: true,
      };
      audioEnabled = true;
    }

    options.media.video = options.media.video || {};
    options.media.video.width = 320;
    options.media.video.height = 240;

    this.videoRoom.createOffer({
      ...options,
      success: (jsep) => {
        this.videoRoom.send({
          message: {
            audio: true,
            video: true,
          },
          jsep: jsep,
          success: () => {
            this.setState({ audioEnabled, videoEnabled }, () => {
              SocketClient.emit('message', {
                roomName: this.opaqueId,
                message: {
                  type: 'update-media-status',
                  id: this._myId,
                  audioEnabled,
                  videoEnabled,
                  photoUrl: credentials.participant.photoUrl,
                },
              });
            });
          },
        });
      },
      error: (error) => {
        console.error('WebRTC error publish own feed', error);
      },
    });
  };

  _requestSupport = ({ value }) => {
    this.setState({ supportRequested: value }, async () => {
      const { supportRequested, credentials } = this.state;
      const { uuid, event } = credentials;
      await API.requestSupport(uuid, event._id, supportRequested);
    });
  };

  render() {
    const { loadTestStream } = this.props;
    const {
      remoteParticipants,
      isHost,
      isSharingScreen,
      connected,
      currentHost,
      audioEnabled,
      videoEnabled,
      isPresenter,
      currentPresenter,
      hideSideBar,
      showSettings,
      supportRequested,
      localMediaReady,
      credentials,
      waitingInLobby,
      validToken,
      screenShareActive,
      showParticipantList,
      doesRoomExist,
      breakoutStartTime,
    } = this.state;

    if (!validToken) {
      return <div style={{ color: 'white', textAlign: 'center', padding: 10 }}>Token is invalid</div>;
    }

    if (waitingInLobby) return <Lobby />;

    return connected === true ? (
      <div className='janus-room-container'>
        <div className={hideSideBar === true ? 'sidebar collapsed' : 'sidebar'}>
          <LocalMedia
            ref={this.localVideoRef}
            name={credentials.identity}
            room={credentials.room}
            isHost={isHost}
            isPresenter={isPresenter}
            loadTestStream={loadTestStream}
            audioEnabled={audioEnabled}
            videoEnabled={videoEnabled}
            isSharingScreen={isSharingScreen}
            onToggleAudio={this._onToggleAudio}
            onToggleVideo={this._onToggleVideo}
            onToggleShareScreen={this._toggleShareScreen}
            onRemountVideo={this._remountVideo}
            showSettings={showSettings}
            onToggleSettings={() => this.setState({ showSettings: !showSettings })}
            changeDevices={this._changeDevices}
            participant={credentials.participant}
            supportRequested={supportRequested}
            onRequestSupport={this._requestSupport}
            isTechSupportRoom={this.isTechSupportRoom}
            localMediaReady={localMediaReady}
            screenShareActive={screenShareActive}
            showParticipantList={showParticipantList}
            onToggleParticipantList={() => this.setState({ showParticipantList: !showParticipantList })}
            breakoutStartTime={breakoutStartTime}
            maxRooms={credentials.maxRooms}
          />
          <div className='sidebar-container' style={!showParticipantList ? { display: 'none' } : { display: 'block' }}>
            <ParticipantList
              host={this.state.currentHost}
              credentials={credentials}
              currentPresenter={currentPresenter}
              remoteParticipants={remoteParticipants}
              loadTestStream={loadTestStream}
              onMakePresenter={this._onMakePresenter}
              onMakeParticipant={this._onMakeParticipant}
              connectedParticipants={remoteParticipants.length}
              audioEnabled={audioEnabled}
              videoEnabled={videoEnabled}
            />
          </div>
          {this.isTechSupportRoom && (
            <div className='sidebar-container' style={showParticipantList ? { display: 'none' } : { display: 'block' }}>
              <Chat credentials={credentials} />
            </div>
          )}
        </div>
        <div
          className={hideSideBar === true ? 'remote-media-container collapsed' : 'remote-media-container'}
          onTransitionEnd={() => this.setState({ collapsedTransitionEnded: true })}
        >
          {doesRoomExist === false && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
              <WaitingForHost />
            </div>
          )}
          <CollapseButton value={hideSideBar} onClick={() => this.setState({ hideSideBar: !hideSideBar })} />
          <ParticipantsGrid
            credentials={credentials}
            janusInstance={this.janusInstance}
            participants={this.state.remoteParticipants}
            collapsed={screenShareActive}
            remountVideo={this.state.remountVideo}
            afterRemount={() => this.setState({ remountVideo: false })}
            hideSideBar={hideSideBar}
            screenShareActive={screenShareActive}
            transitionEnded={this.state.collapsedTransitionEnded}
            onResetTransitionStatus={() => this.setState({ collapsedTransitionEnded: false })}
          />
          <ScreenShareRoom
            janusInstance={this.janusInstance}
            isHost={isHost}
            isTechSupportRoom={this.isTechSupportRoom}
            screenShareRoomId={this._screenShareRoomId}
            opaqueId={this.opaqueId}
            hideSideBar={hideSideBar}
            remountVideo={this.state.remountVideo}
            afterRemount={() => this.setState({ remountVideo: false })}
            onStopScreenShare={this._stopScreenShare}
            onStartScreenShare={() => this.setState({ screenShareActive: true })}
            credentials={credentials}
            isSharingScreen={isSharingScreen}
            currentPresenter={currentPresenter}
            currentHost={currentHost}
          />
        </div>
      </div>
    ) : null;
  }
}

export default JanusBreakoutRoom;
