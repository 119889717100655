import React from 'react';
import Logo from '../../assets/pando_branches_logo.png';
import BreakoutRoomIcon from '../../assets/breakout_room.svg';
import './Messages.css';

function JoinBreakoutRoom({ roomName, onClick }) {
  return (
    <div className='horizontal-message-box'>
      <div className='header'>
        <img src={Logo} alt='Pando Branches' />
      </div>
      <div className='content'>
        <div className='left'>
          <div className='text'>
            <div>You are about to join</div>
            <div>{roomName}</div>
          </div>
          <p style={{ fontSize: 13, color: '#fff' }}>If you had previously paused your video, it will resume once you proceed to the Breakout room.</p>
          <button onClick={onClick}>OK</button>
        </div>
        <div className='right'>
          <img src={BreakoutRoomIcon} alt='Breakout room' />
        </div>
      </div>
    </div>
  );
}

export default JoinBreakoutRoom;
