import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';

export default function EventSupportListHeader({ columns, onSortChange, currentValue }) {
  const _toggleSort = (sort) => {
    let activeSort;
    if (sort.value === currentValue.value) {
      activeSort = { value: sort.value, dir: currentValue.dir === 'desc' ? 'asc' : 'desc' };
    } else {
      activeSort = { value: sort.value, dir: 'desc' };
    }
    onSortChange && onSortChange(activeSort);
  };

  return (
    <div className='list-header'>
      {columns.map((col, index) => {
        const classes = col.value === currentValue.value ? 'column active' : 'column';
        const icon = currentValue.dir === 'desc' ? faSortAmountDown : faSortAmountUp;
        return (
          <div key={`col-${col.val}-${index}`} className={classes} onClick={() => _toggleSort(col)} style={{ flex: col.value === 'name' ? 0.6 : 0.4 }}>
            <span>{col.label}</span>
            {col.value === currentValue.value && <FontAwesomeIcon icon={icon} size='sm' color='#999999' />}
          </div>
        );
      })}
    </div>
  );
}
