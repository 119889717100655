import { useCallback, useEffect, useLayoutEffect, useRef, useMemo } from 'react';

export default function useKeyboardShortcut(shortcutKeys, callback) {
  const _callbackRef = useRef(callback);
  const _heldKeys = useRef([]);

  const _specialKeysMap = {
    ctrl: 17,
    control: 17,
    alt: 18,
    option: 18,
    cmd: 91,
  };

  const _shortcutArray = useMemo(
    () => shortcutKeys.map((key) => (key.length > 1 ? _specialKeysMap[key] : key.toUpperCase().charCodeAt(0))),
    [JSON.stringify(shortcutKeys)],
  );

  useLayoutEffect(() => {
    _callbackRef.current = callback;
  });

  useEffect(() => {
    _heldKeys.current = [];
  }, [_shortcutArray]);

  const _onKeyDown = useCallback(
    (event) => {
      event.stopPropagation();
      const loweredKey = event.keyCode;
      if (!(_shortcutArray.indexOf(loweredKey) >= 0)) return;

      if (_shortcutArray.includes(loweredKey) && !_heldKeys.current.includes(loweredKey)) {
        _heldKeys.current = [..._heldKeys.current, loweredKey];
      }
      if (_heldKeys.current.length === _shortcutArray.length) {
        _callbackRef.current(shortcutKeys);
      }
    },
    [shortcutKeys],
  );

  const _onKeyUp = useCallback(
    (event) => {
      event.stopPropagation();
      _heldKeys.current = [];
      return false;
    },
    [shortcutKeys],
  );

  useEffect(() => {
    window.addEventListener('keydown', _onKeyDown);
    window.addEventListener('keyup', _onKeyUp);
    return () => {
      window.removeEventListener('keydown', _onKeyDown);
      window.removeEventListener('keyup', _onKeyUp);
    };
  });
}
