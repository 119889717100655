import React from 'react';

export default function WebCamError() {
  return (
    <svg x='0px' y='0px' viewBox='0 0 600 600' width={210} style={{ marginRight: 10 }}>
      <style type='text/css'>{`
        .st0{fill:#3ABB79;}
        .st1{fill:#585A5A;}
        .st2{fill:#00832D;}
        .st3{fill:#0066DA;}
        .st4{fill:#D4363B;}
        .st5{fill:#2684FC;}
        .st6{fill:#00AC47;}
        .st7{fill:#FFBA00;}
        .st8{fill:#FFFFFF;}
      `}</style>
      <path
        className='st0'
        d='M279.095,384.084h10.092v13.115c0,0.893,0.591,1.677,1.449,1.923c0.183,0.052,0.368,0.077,0.551,0.077
	c0.677,0,1.324-0.345,1.696-0.94l15.115-24.185c0.386-0.616,0.406-1.394,0.054-2.029c-0.353-0.636-1.022-1.03-1.75-1.03h-10.092
	v-13.115c0-0.893-0.591-1.677-1.449-1.923c-0.858-0.244-1.774,0.106-2.247,0.863L277.4,381.024
	c-0.386,0.616-0.406,1.394-0.054,2.029C277.698,383.689,278.368,384.084,279.095,384.084z M292.211,364.873v8.142
	c0,1.104,0.896,2,2,2h8.484l-9.507,15.211v-8.142c0-1.104-0.896-2-2-2h-8.484L292.211,364.873z M357.86,385.505
	c8.866,11.261,11.92,26.61,8.168,41.061l-0.033,0.128c-2.504,9.648-7.611,17.952-14.771,24.019
	c-8.537,7.233-19.415,11.17-30.504,11.171c-1.835,0-3.674-0.108-5.512-0.326c-12.517-1.49-23.704-7.765-31.502-17.668
	c-6.314-8.021-9.791-17.644-10.095-27.661l-4.085,6.098c-0.386,0.575-1.019,0.887-1.664,0.887c-0.383,0-0.769-0.109-1.111-0.338
	c-0.917-0.615-1.163-1.857-0.548-2.775l7.995-11.936c0.614-0.917,1.855-1.163,2.775-0.549c0.246,0.165,0.44,0.377,0.585,0.614
	c0.004,0.006,0.01,0.009,0.014,0.015l7.995,11.937c0.615,0.918,0.369,2.16-0.549,2.775c-0.917,0.614-2.161,0.368-2.774-0.549
	l-4.651-6.944c0.147,9.396,3.344,18.441,9.256,25.951c7.137,9.064,17.376,14.807,28.832,16.17
	c12.008,1.427,23.717-2.096,32.958-9.925c6.527-5.529,11.19-13.125,13.485-21.967l0.033-0.128
	c3.439-13.252,0.658-27.301-7.438-37.585c-7.137-9.064-17.376-14.807-28.831-16.17c-1.097-0.131-1.88-1.126-1.75-2.223
	c0.131-1.098,1.13-1.867,2.223-1.75C338.875,369.327,350.063,375.602,357.86,385.505z M526.87,424.459
	c-1.018,0-1.846-0.827-1.846-1.846c0-10.18,8.28-18.46,18.458-18.46c4.356,0,8.574,1.551,11.924,4.377v-5.688
	c0-1.018,0.828-1.847,1.847-1.847s1.846,0.828,1.846,1.847v10.479c0,1.018-0.827,1.847-1.846,1.847h-10.478
	c-1.018,0-1.846-0.828-1.846-1.847s0.828-1.846,1.846-1.846h6.399c-2.698-2.345-6.119-3.63-9.69-3.63
	c-8.142,0-14.766,6.625-14.766,14.768C528.716,423.631,527.888,424.459,526.87,424.459z M561.909,422.627
	c0,10.18-8.282,18.46-18.461,18.46c-4.358,0-8.573-1.552-11.92-4.377v5.688c0,1.018-0.828,1.847-1.847,1.847
	c-1.018,0-1.846-0.828-1.846-1.847v-10.479c0-1.018,0.828-1.847,1.846-1.847h10.478c1.018,0,1.847,0.828,1.847,1.847
	s-0.828,1.846-1.847,1.846h-6.398c2.697,2.346,6.117,3.631,9.686,3.631c8.143,0,14.769-6.625,14.769-14.768
	c0-1.018,0.827-1.846,1.846-1.846C561.079,420.782,561.907,421.61,561.909,422.627z'
      />
      <path
        className='st1'
        d='M199.989,411.569c0,1.101-0.89,2-2,2c-1.1,0-2-0.899-2-2c0-1.109,0.9-2,2-2
	C199.099,409.569,199.989,410.46,199.989,411.569z M189.999,409.569c-1.11,0-2.01,0.891-2.01,2c0,1.101,0.9,2,2.01,2
	c1.1,0,2-0.899,2-2C191.999,410.46,191.099,409.569,189.999,409.569z M180.759,319.01c-0.78,0.78-0.78,2.04,0,2.83
	c0.39,0.39,0.9,0.58,1.42,0.58c0.51,0,1.02-0.19,1.41-0.58c0.78-0.79,0.78-2.05,0-2.83
	C182.809,318.229,181.539,318.229,180.759,319.01z M206.21,299.21c0.78-0.78,0.78-2.05,0-2.83c-0.78-0.78-2.04-0.78-2.82,0
	c-0.78,0.78-0.78,2.05,0,2.83c0.39,0.39,0.9,0.59,1.41,0.59C205.309,299.8,205.82,299.6,206.21,299.21z M192.07,307.689
	c-0.78,0.79-0.78,2.051,0,2.83c0.39,0.391,0.91,0.59,1.42,0.59c0.51,0,1.02-0.199,1.41-0.59c0.78-0.779,0.78-2.04,0-2.83
	C194.119,306.91,192.86,306.91,192.07,307.689z M197.729,302.04c-0.78,0.779-0.78,2.05,0,2.83c0.39,0.39,0.9,0.58,1.41,0.58
	c0.52,0,1.03-0.19,1.42-0.58c0.78-0.78,0.78-2.051,0-2.83C199.779,301.26,198.509,301.26,197.729,302.04z M189.249,313.35
	c-0.78-0.78-2.05-0.78-2.83,0c-0.78,0.78-0.78,2.05,0,2.83c0.39,0.39,0.9,0.58,1.41,0.58c0.51,0,1.03-0.19,1.42-0.58
	C190.029,315.399,190.029,314.13,189.249,313.35z M181.999,409.569c-1.11,0-2,0.891-2,2c0,1.101,0.89,2,2,2c1.1,0,2-0.899,2-2
	C183.999,410.46,183.099,409.569,181.999,409.569z M227.42,277.18c0.14,0,0.27-0.02,0.4-0.04c0.12-0.03,0.25-0.07,0.37-0.12
	c0.12-0.05,0.23-0.109,0.34-0.18s0.21-0.16,0.31-0.25c0.09-0.09,0.17-0.2,0.25-0.31c0.07-0.101,0.13-0.221,0.18-0.341
	c0.05-0.12,0.09-0.239,0.11-0.37c0.03-0.13,0.04-0.26,0.04-0.39c0-0.53-0.21-1.05-0.58-1.42c-0.75-0.75-2.09-0.74-2.83,0
	c-0.37,0.37-0.59,0.89-0.59,1.42s0.21,1.03,0.59,1.41S226.889,277.18,227.42,277.18z M164.59,410.149
	c-0.38,0.37-0.59,0.891-0.59,1.41c0,0.54,0.21,1.04,0.59,1.42c0.37,0.38,0.87,0.59,1.41,0.59c0.53,0,1.04-0.21,1.42-0.59
	c0.37-0.38,0.58-0.88,0.58-1.42c0-0.52-0.21-1.04-0.58-1.41C166.67,409.41,165.34,409.399,164.59,410.149z M72.091,443.162
	c0,8.16-6.639,14.799-14.799,14.799H19.95c-8.16,0-14.799-6.639-14.799-14.799V405.82c0-8.16,6.639-14.799,14.792-14.799h32.742
	h4.606c1.615,0,3.167,0.269,4.623,0.75v-1.544c0-0.867,0.086-1.713,0.213-2.544c-1.56-0.417-3.192-0.661-4.884-0.661h-4.6
	l-32.694-0.001c-10.366,0-18.799,8.434-18.799,18.799v37.342c0,10.365,8.433,18.799,18.799,18.799h37.342
	c10.037,0,18.238-7.914,18.75-17.826c-1.379-0.221-2.706-0.593-3.951-1.128V443.162z M173.999,409.569c-1.11,0-2,0.891-2,2
	c0,1.101,0.89,2,2,2c1.1,0,2-0.899,2-2C175.999,410.46,175.099,409.569,173.999,409.569z M231.989,411.569c0-1.109-0.9-2-2-2
	c-1.11,0-2,0.891-2,2c0,1.101,0.89,2,2,2C231.09,413.569,231.989,412.67,231.989,411.569z M436.999,409.569c-1.109,0-2,0.891-2,2
	c0,1.101,0.891,2,2,2c1.101,0,2-0.899,2-2C438.999,410.46,438.1,409.569,436.999,409.569z M428.999,409.569c-1.109,0-2,0.891-2,2
	c0,1.101,0.891,2,2,2c1.101,0,2-0.899,2-2C430.999,410.46,430.1,409.569,428.999,409.569z M444.999,409.569c-1.109,0-2,0.891-2,2
	c0,1.101,0.891,2,2,2c1.101,0,2-0.899,2-2C446.999,410.46,446.1,409.569,444.999,409.569z M420.999,409.569c-1.109,0-2,0.891-2,2
	c0,1.101,0.891,2,2,2c1.101,0,2-0.899,2-2C422.999,410.46,422.1,409.569,420.999,409.569z M412.999,409.569c-1.1,0-2,0.891-2,2
	c0,1.101,0.9,2,2,2c1.101,0,2-0.899,2-2C414.999,410.46,414.1,409.569,412.999,409.569z M205.989,409.569c-1.1,0-2,0.891-2,2
	c0,1.101,0.9,2,2,2c1.11,0,2-0.899,2-2C207.989,410.46,207.099,409.569,205.989,409.569z M462.999,411.569c0-1.109-0.899-2-2-2
	c-1.109,0-2,0.891-2,2c0,1.101,0.891,2,2,2C462.1,413.569,462.999,412.67,462.999,411.569z M452.999,409.569c-1.109,0-2,0.891-2,2
	c0,1.101,0.891,2,2,2c1.101,0,2-0.899,2-2C454.999,410.46,454.1,409.569,452.999,409.569z M213.989,409.569c-1.11,0-2,0.891-2,2
	c0,1.101,0.89,2,2,2s2-0.899,2-2C215.989,410.46,215.099,409.569,213.989,409.569z M221.769,282.83c0.51,0,1.02-0.19,1.41-0.58
	c0.78-0.79,0.78-2.05,0-2.83c-0.78-0.78-2.05-0.78-2.83,0c-0.78,0.78-0.78,2.04,0,2.83
	C220.739,282.64,221.249,282.83,221.769,282.83z M214.699,285.069c-0.78,0.78-0.78,2.051,0,2.83c0.39,0.391,0.9,0.58,1.41,0.58
	c0.51,0,1.02-0.189,1.42-0.58c0.78-0.779,0.78-2.05,0-2.83C216.739,284.29,215.479,284.29,214.699,285.069z M470.941,411.179
	c-0.001-0.006-0.001-0.012-0.002-0.018c-0.096-0.509-0.387-0.98-0.851-1.291l-11.936-7.995c-0.917-0.612-2.159-0.37-2.775,0.549
	c-0.614,0.918-0.369,2.16,0.549,2.775l9.462,6.338l-9.462,6.339c-0.918,0.614-1.163,1.856-0.548,2.774
	c0.386,0.576,1.019,0.887,1.663,0.887c0.383,0,0.77-0.109,1.111-0.339l11.936-7.996c0.07-0.047,0.128-0.103,0.19-0.157
	c0.012-0.011,0.025-0.02,0.038-0.031c0.328-0.298,0.544-0.684,0.623-1.1c0.007-0.035,0.006-0.071,0.011-0.107
	c0.012-0.09,0.025-0.179,0.025-0.27C470.974,411.417,470.962,411.297,470.941,411.179z M211.869,290.729
	c-0.78-0.779-2.05-0.779-2.83,0c-0.78,0.78-0.78,2.04,0,2.83c0.39,0.391,0.9,0.58,1.42,0.58c0.51,0,1.02-0.189,1.41-0.58
	C212.65,292.77,212.65,291.51,211.869,290.729z M404.999,409.569c-1.109,0-2,0.891-2,2c0,1.101,0.891,2,2,2c1.101,0,2-0.899,2-2
	C406.999,410.46,406.1,409.569,404.999,409.569z M221.989,409.569c-1.11,0-2,0.891-2,2c0,1.101,0.89,2,2,2c1.1,0,2-0.899,2-2
	C223.989,410.46,223.09,409.569,221.989,409.569z M396.999,409.569c-1.1,0-2,0.891-2,2c0,1.101,0.9,2,2,2c1.11,0,2-0.899,2-2
	C398.999,410.46,398.11,409.569,396.999,409.569z M239.088,409.869l-11.936-7.995c-0.919-0.615-2.161-0.368-2.775,0.549
	c-0.615,0.918-0.369,2.16,0.548,2.775l9.462,6.338l-9.462,6.339c-0.917,0.614-1.163,1.856-0.548,2.774
	c0.386,0.576,1.019,0.887,1.664,0.887c0.382,0,0.77-0.109,1.111-0.339l11.678-7.823c0.207-0.098,0.401-0.227,0.569-0.394
	c0.38-0.38,0.59-0.88,0.59-1.42c0-0.247-0.053-0.493-0.145-0.724C239.703,410.455,239.45,410.112,239.088,409.869z M192.608,322.92
	c-0.214-1.084-1.266-1.794-2.35-1.574l-11.173,2.209l2.209-11.173c0.214-1.083-0.49-2.136-1.574-2.35
	c-1.082-0.217-2.135,0.49-2.35,1.574l-2.728,13.797c-0.03,0.094-0.06,0.188-0.083,0.286c-0.02,0.12-0.04,0.261-0.04,0.391
	s0.01,0.26,0.04,0.39c0.03,0.13,0.06,0.25,0.11,0.37c0.05,0.12,0.11,0.24,0.19,0.35c0.07,0.101,0.15,0.21,0.25,0.301
	c0.042,0.042,0.09,0.073,0.134,0.11c0.03,0.026,0.06,0.051,0.091,0.075c0.051,0.038,0.105,0.076,0.16,0.109
	c0.06,0.038,0.122,0.071,0.186,0.102c0.027,0.013,0.051,0.031,0.079,0.043c0.014,0.006,0.03,0.008,0.044,0.013
	c0.234,0.095,0.485,0.151,0.746,0.151c0.128,0,0.259-0.012,0.39-0.038l14.094-2.786C192.117,325.056,192.822,324.003,192.608,322.92
	z'
      />
      <path className='st2' d='M41.529,424.491l3.881,4.436l5.218,3.335l0.91-7.743l-0.91-7.57l-5.318,2.93L41.529,424.491z' />
      <path
        className='st3'
        d='M19.01,431.543v6.597c0,1.508,1.222,2.73,2.73,2.73h6.597l1.365-4.986l-1.365-4.34l-4.527-1.365L19.01,431.543z
	'
      />
      <path
        className='st4'
        d='M316.737,136.405c1.431,0,2.51,1.502,1.748,3.015c-0.327,0.65-1.051,0.983-1.779,0.985
	c-9.247,0.017-16.767,7.536-16.784,16.784c-0.001,0.728-0.335,1.452-0.985,1.78c-1.513,0.762-3.015-0.318-3.015-1.748
	C295.922,145.743,305.259,136.405,316.737,136.405z M344.304,157.221c0,16.25-14.131,29.26-30.75,27.388
	c-12.723-1.433-22.916-11.733-24.232-24.469c-1.441-13.938,7.578-26.074,20.151-29.513c1.326-0.363,1.899-1.911,1.109-3.036
	c-0.009-0.012-0.017-0.025-0.026-0.037c-0.485-0.695-1.353-0.995-2.17-0.77c-14.15,3.887-24.36,17.39-23.112,33.016
	c1.231,15.415,13.775,27.828,29.202,28.909c18.424,1.291,33.829-13.333,33.829-31.488c0-8.921-3.721-16.988-9.691-22.734
	c-0.371-0.357-0.872-0.549-1.387-0.549l0,0c-1.779,0-2.697,2.176-1.414,3.408C341.042,142.364,344.304,149.417,344.304,157.221z
	 M360.432,157.221c0,25.203-21.45,45.492-47.053,43.568c-21.158-1.59-38.323-18.531-40.166-39.668
	c-1.935-22.196,12.838-41.374,33.128-46.344c0.904-0.221,1.552-1.011,1.552-1.941l0,0c0-1.293-1.215-2.247-2.471-1.94
	c-22.09,5.396-38.202,26.207-36.213,50.346c1.909,23.169,20.678,41.792,43.86,43.535c27.949,2.101,51.364-20.044,51.364-47.556
	c0-8.028-2.002-15.593-5.521-22.237c-0.344-0.649-1.029-1.046-1.763-1.046l0,0c-1.521,0-2.457,1.619-1.747,2.963
	C358.609,142.975,360.432,149.887,360.432,157.221z M19.01,417.439l4.8,1.365l4.527-1.365l1.342-4.281l-1.342-5.045L19.01,417.439z
	 M128.756,350.103c-3.036,0.537-5.939,1.738-8.467,3.502c-6.175,4.308-9.656,11.052-9.656,18.333c0,0.501,0.04,0.996,0.073,1.491
	c0.501,7.581,4.757,14.431,11.981,18.304c3.091,1.658,6.592,2.399,10.099,2.399h0.042l0.583-0.028
	c4.952-0.236,9.79-1.913,13.596-5.09c10.14-8.464,10.644-23.639,1.513-32.77C143.378,351.102,136.159,348.794,128.756,350.103z
	 M354.842,208.388l16.963,20.428c2.187,2.725,3.379,6.288,3.379,10.061c0,7.952-5.366,14.422-11.962,14.422
	c-7.729,0-13.612-1.762-19.84-3.626c-7.287-2.182-14.822-4.438-26.645-4.438s-19.357,2.256-26.645,4.438
	c-6.228,1.864-12.111,3.626-19.84,3.626c-6.596,0-11.961-6.47-11.961-14.422c0-3.771,1.192-7.335,3.357-10.034l16.984-20.454
	c-15.599-11.647-25.717-30.247-25.717-51.167c0-31.781,23.352-58.204,53.798-63.027c1.521-0.241,2.73,1.275,2.176,2.712
	l-0.017,0.045c-0.249,0.649-0.846,1.093-1.533,1.201c-28.535,4.524-50.424,29.281-50.424,59.069
	c0,33.931,28.395,61.355,62.671,59.756c30.775-1.436,55.688-26.522,56.924-57.306c0.326-8.123-0.978-15.909-3.61-23.059
	c-0.478-1.299,0.478-2.674,1.863-2.674l0,0c0.83,0,1.583,0.51,1.87,1.288c2.53,6.861,3.926,14.266,3.926,21.995
	C380.56,178.141,370.441,196.741,354.842,208.388z M351.543,210.676c-10.019,6.546-21.971,10.367-34.806,10.367
	c-12.835,0-24.787-3.822-34.806-10.368l-17.184,20.695c-1.576,1.967-2.456,4.642-2.456,7.506c0,5.747,3.571,10.422,7.961,10.422
	c7.144,0,12.496-1.603,18.693-3.458c7.21-2.158,15.383-4.605,27.792-4.605s20.582,2.447,27.792,4.605
	c6.197,1.855,11.549,3.458,18.692,3.458c4.391,0,7.962-4.675,7.962-10.422c0-2.865-0.88-5.54-2.478-7.531L351.543,210.676z
	 M411.504,119.107v-16.878c0-4.877-3.954-8.831-8.831-8.831h-79.95c-4.877,0-8.831,3.954-8.831,8.831v16.878
	c0,4.877,3.954,8.831,8.831,8.831h79.95C407.551,127.938,411.504,123.984,411.504,119.107z'
      />
      <path
        className='st5'
        d='M132.854,394.13v3.677v29.762c0,9.278-7.521,16.799-16.799,16.799H78.714c-0.912,0-1.8-0.093-2.673-0.232
	c-1.379-0.221-2.706-0.593-3.951-1.128c-5.983-2.57-10.176-8.513-10.176-15.438v-35.798v-1.544c0-0.867,0.086-1.713,0.213-2.544
	c1.229-8.067,8.175-14.255,16.586-14.255h31.991c0.355,5.366,2.59,10.365,6.428,14.202c4.191,4.192,9.765,6.501,15.693,6.501
	c0,0,0,0,0,0C132.836,394.132,132.845,394.13,132.854,394.13z M28.336,417.439H19.01v14.103h9.326V417.439z'
      />
      <path
        className='st6'
        d='M56.588,412.062l-5.96,4.886v15.313l5.987,4.909c0.896,0.701,2.206,0.061,2.206-1.078v-22.975
	C58.822,411.964,57.482,411.332,56.588,412.062L56.588,412.062z M41.529,424.491v7.052H28.336v9.326h19.563
	c1.508,0,2.73-1.222,2.73-2.73v-5.878L41.529,424.491z'
      />
      <path className='st7' d='M47.899,408.113H28.336v9.326h13.193v7.052l9.099-7.538v-6.11C50.628,409.335,49.407,408.113,47.899,408.113z' />
      <path
        className='st8'
        d='M140.19,376.474c0.781,0.781,0.781,2.048,0,2.828c-0.391,0.391-0.902,0.586-1.414,0.586
	s-1.024-0.195-1.414-0.586l-4.535-4.536l-4.536,4.536c-0.39,0.391-0.902,0.586-1.414,0.586s-1.023-0.195-1.414-0.586
	c-0.781-0.781-0.781-2.047,0-2.828l4.536-4.536l-4.536-4.536c-0.781-0.781-0.781-2.048,0-2.828c0.781-0.781,2.048-0.781,2.828,0
	l4.536,4.536l4.535-4.535c0.78-0.781,2.047-0.781,2.828,0s0.781,2.047,0,2.828l-4.535,4.535L140.19,376.474z M333.942,407.165
	l2.828,2.828l5.868-5.868l-2.828-2.828L333.942,407.165z M336.562,398.05l-5.867,5.867l-2.828-2.828l5.867-5.867L336.562,398.05z
	 M337.502,388.62c-0.965-0.966-2.249-1.497-3.614-1.497s-2.649,0.531-3.614,1.497l-11.571,11.571
	c-0.33-0.07-0.667-0.116-1.012-0.117c-1.188,0.024-2.309,0.505-3.158,1.354l-8.042,8.042c-2.25,2.251-4.044,4.859-5.331,7.752
	l-4.33,9.729l3.426,3.426l-4.454,4.453l2.828,2.828l4.454-4.453l1.573,1.573l-4.453,4.454l2.828,2.828l4.453-4.454l3.426,3.425
	l9.729-4.329c2.893-1.288,5.5-3.082,7.751-5.333l8.042-8.042c1.142-1.142,1.552-2.729,1.245-4.179l11.561-11.561
	c0.966-0.966,1.498-2.249,1.498-3.615c0-1.365-0.532-2.649-1.498-3.615L337.502,388.62z M333.604,420.498l-8.042,8.042
	c-1.903,1.902-4.106,3.419-6.55,4.507l-7.217,3.212l-10.193-10.193l3.211-7.218c1.087-2.442,2.603-4.646,4.505-6.55l8.042-8.042
	c0.156-0.155,0.324-0.182,0.411-0.184c0.095,0,0.205,0.017,0.299,0.111l0,0l15.605,15.603l0,0
	C333.848,419.961,333.816,420.286,333.604,420.498z M346.41,404.757l-11.054,11.055l-13.31-13.309l11.055-11.055
	c0.433-0.433,1.138-0.435,1.572,0l11.736,11.735c0.21,0.21,0.326,0.49,0.326,0.787S346.62,404.546,346.41,404.757z M590.707,371.514
	h-94.396c-1.93,0-3.5,1.57-3.5,3.5v74.246c0,1.93,1.57,3.5,3.5,3.5h94.396c1.93,0,3.5-1.57,3.5-3.5v-74.246
	C594.207,373.084,592.637,371.514,590.707,371.514z M590.207,375.514v16.151h-93.396v-16.151H590.207z M496.811,448.76v-53.095
	h93.396v53.095H496.811z M501.528,383.676L501.528,383.676c0-1.255,1.017-2.272,2.272-2.272h6.815c1.255,0,2.272,1.017,2.272,2.272
	l0,0c0,1.255-1.017,2.272-2.272,2.272H503.8C502.545,385.948,501.528,384.931,501.528,383.676z M78.305,399.587v13.975
	c0.012,3.16,2.593,5.703,5.741,5.691h20.37c0.579,0,1.045-0.466,1.045-1.033v-13.975c-0.012-3.16-2.593-5.703-5.741-5.691h-20.37
	C78.772,398.555,78.306,399.021,78.305,399.587L78.305,399.587z M106.758,405.039l8.41-6.144c0.73-0.604,1.296-0.453,1.296,0.642
	v18.733c0,1.246-0.693,1.096-1.296,0.642l-8.41-6.131V405.039z M337.243,118.179h-8.634v-14.991h8.634v2.604h-5.455v3.292h5.075
	v2.604h-5.075v3.866h5.455V118.179z M343.55,112.427v5.752h-3.179v-14.991h4.368c2.037,0,3.544,0.371,4.522,1.112
	c0.977,0.742,1.466,1.868,1.466,3.379c0,0.882-0.243,1.666-0.728,2.354c-0.486,0.687-1.173,1.225-2.062,1.615
	c2.256,3.37,3.726,5.547,4.409,6.532h-3.527l-3.578-5.752H343.55z M343.55,109.843h1.025c1.005,0,1.746-0.167,2.226-0.502
	c0.478-0.335,0.717-0.861,0.717-1.579c0-0.711-0.244-1.217-0.732-1.518c-0.489-0.301-1.246-0.451-2.271-0.451h-0.964V109.843z
	 M357.413,112.427v5.752h-3.179v-14.991h4.368c2.037,0,3.544,0.371,4.522,1.112c0.977,0.742,1.466,1.868,1.466,3.379
	c0,0.882-0.243,1.666-0.728,2.354c-0.486,0.687-1.173,1.225-2.062,1.615c2.256,3.37,3.726,5.547,4.409,6.532h-3.527l-3.578-5.752
	H357.413z M357.413,109.843h1.025c1.005,0,1.746-0.167,2.226-0.502c0.478-0.335,0.717-0.861,0.717-1.579
	c0-0.711-0.244-1.217-0.732-1.518c-0.489-0.301-1.246-0.451-2.271-0.451h-0.964V109.843z M381.705,110.663
	c0,2.481-0.615,4.389-1.846,5.722s-2.994,2-5.291,2s-4.061-0.667-5.291-2s-1.846-3.247-1.846-5.742s0.616-4.4,1.851-5.716
	c1.233-1.316,3.002-1.974,5.306-1.974s4.066,0.663,5.286,1.989C381.095,106.267,381.705,108.175,381.705,110.663z M370.764,110.663
	c0,1.675,0.318,2.936,0.953,3.784c0.637,0.848,1.586,1.271,2.852,1.271c2.535,0,3.804-1.685,3.804-5.055
	c0-3.377-1.261-5.065-3.784-5.065c-1.265,0-2.218,0.425-2.86,1.277C371.085,107.725,370.764,108.988,370.764,110.663z
	 M387.99,112.427v5.752h-3.179v-14.991h4.368c2.037,0,3.544,0.371,4.522,1.112c0.977,0.742,1.466,1.868,1.466,3.379
	c0,0.882-0.243,1.666-0.728,2.354c-0.486,0.687-1.173,1.225-2.062,1.615c2.256,3.37,3.726,5.547,4.409,6.532h-3.527l-3.578-5.752
	H387.99z M387.99,109.843h1.025c1.005,0,1.746-0.167,2.226-0.502c0.478-0.335,0.717-0.861,0.717-1.579
	c0-0.711-0.244-1.217-0.732-1.518c-0.489-0.301-1.246-0.451-2.271-0.451h-0.964V109.843z'
      />
    </svg>
  );
}
