import React from 'react';

export default function NoConnection() {
  return (
    <svg x='0px' y='0px' viewBox='0 0 250 250' width={210} height={210}>
      <style type='text/css'>{`
      .st0{fill:#D4363B;}
      .st1{fill:#585A5A;}
      .st2{fill:#3ABB79;}
    `}</style>
      <path
        className='st0'
        d='M51.881,106.465c0.99,0,1.793,0.803,1.793,1.793c0,0.99-0.803,1.793-1.793,1.793
	c-0.99,0-1.793-0.803-1.793-1.793C50.088,107.268,50.891,106.465,51.881,106.465z M57.259,108.258c0,0.99,0.803,1.793,1.793,1.793
	c0.99,0,1.793-0.803,1.793-1.793c0-0.99-0.803-1.793-1.793-1.793C58.062,106.465,57.259,107.268,57.259,108.258z M98.49,168.001
	c0,0.99-0.802,1.793-1.793,1.793l-11.086,0c-0.742,2.082-2.714,3.585-5.048,3.585l-10.756,0c-2.334,0-4.305-1.503-5.048-3.585
	l-11.086,0c-0.99,0-1.793-0.803-1.793-1.793c0-0.99,0.803-1.793,1.793-1.793l11.086,0c0.742-2.082,2.714-3.585,5.048-3.585l3.585,0
	l0-20.379c0-0.99,0.803-1.793,1.793-1.793c0.99,0,1.793,0.803,1.793,1.793l0,20.379l3.585,0c2.334,0,4.305,1.503,5.048,3.585
	l11.086,0C97.688,166.208,98.49,167.011,98.49,168.001z M82.356,168.001c0-0.988-0.804-1.793-1.793-1.793l-10.756,0
	c-0.988,0-1.793,0.804-1.793,1.793c0,0.988,0.804,1.793,1.793,1.793l10.756,0C81.551,169.794,82.356,168.989,82.356,168.001z
	 M73.393,106.465c-0.99,0-1.793,0.803-1.793,1.793c0,0.99,0.803,1.793,1.793,1.793l25.097,0c0.991,0,1.793-0.803,1.793-1.793
	c0-0.99-0.802-1.793-1.793-1.793L73.393,106.465z M50.088,126.11c0,0.99,0.803,1.793,1.793,1.793c0.99,0,1.793-0.803,1.793-1.793
	c0-0.99-0.803-1.793-1.793-1.793C50.891,124.318,50.088,125.121,50.088,126.11z M57.259,126.111c0,0.99,0.803,1.793,1.793,1.793
	c0.99,0,1.793-0.803,1.793-1.793c0-0.99-0.803-1.793-1.793-1.793C58.061,124.318,57.259,125.121,57.259,126.111z M73.393,124.318
	c-0.99,0-1.793,0.803-1.793,1.793c0,0.99,0.803,1.793,1.793,1.793l25.097,0c0.991,0,1.793-0.803,1.793-1.793
	c0-0.99-0.802-1.793-1.793-1.793L73.393,124.318z M44.71,115.43l0-12.497c0-1.019,0.826-1.845,1.845-1.845l57.261-0.001
	c1.019,0,1.845,0.826,1.845,1.845l0,6.982c0,0.918,0.625,1.784,1.534,1.911c1.106,0.155,2.052-0.699,2.052-1.774l0-7.118
	c0-2.999-2.431-5.43-5.43-5.43l-57.261,0.001c-2.999,0-5.43,2.431-5.43,5.43l0,14.288v0.002l0,0.616
	c0,0.917,0.623,1.784,1.532,1.912c1.106,0.156,2.053-0.699,2.053-1.774l0,1.039l63.121-0.001c1.01,0,1.829-0.819,1.829-1.83v0
	c0-1.01-0.819-1.829-1.83-1.829l-64.861,0.001c-1.019,0-1.845,0.826-1.845,1.845l0,14.235c0,2.999,2.431,5.43,5.43,5.43
	l57.261-0.001c2.999,0,5.43-2.431,5.43-5.43l0-6.98c0-0.918-0.625-1.784-1.534-1.911c-1.106-0.155-2.052,0.699-2.052,1.774l0,7.116
	c0,1.019-0.826,1.845-1.845,1.845l-57.261,0.001c-1.019,0-1.845-0.826-1.845-1.845l0-12.494 M104.674,166.421
	c-0.963,0-1.749,0.786-1.749,1.749c0,0.961,0.786,1.749,1.749,1.749c0.961,0,1.749-0.787,1.749-1.749
	C106.422,167.207,105.635,166.421,104.674,166.421z M118.803,166.421c-0.963,0-1.749,0.786-1.749,1.749
	c0,0.961,0.786,1.749,1.749,1.749c0.961,0,1.749-0.787,1.749-1.749C120.552,167.207,119.764,166.421,118.803,166.421z
	 M111.738,166.421c-0.963,0-1.749,0.786-1.749,1.749c0,0.961,0.786,1.749,1.749,1.749c0.961,0,1.749-0.787,1.749-1.749
	C113.487,167.207,112.7,166.421,111.738,166.421z M127.126,166.928c-0.49-0.49-1.277-0.647-1.906-0.368
	c-0.227,0.088-0.403,0.21-0.577,0.368c-0.157,0.174-0.28,0.367-0.367,0.576c-0.106,0.21-0.14,0.437-0.14,0.665
	c0,0.227,0.034,0.454,0.14,0.664c0.087,0.227,0.21,0.402,0.367,0.577c0.331,0.314,0.787,0.507,1.241,0.507s0.908-0.193,1.241-0.507
	c0.157-0.175,0.28-0.35,0.367-0.577c0.087-0.21,0.14-0.437,0.14-0.664c0-0.228-0.053-0.455-0.14-0.665
	C127.406,167.295,127.283,167.102,127.126,166.928z'
      />
      <path
        className='st1'
        d='M148.776,118.463c-0.927,0-1.698-0.717-1.758-1.643c-0.7-10.783-8.239-20.032-18.815-22.818
	c-0.466-0.121-0.869-0.436-1.104-0.865c-0.234-0.433-0.278-0.928-0.124-1.394c0.502-1.535,0.746-2.923,0.746-4.243
	c0-7.561-6.152-13.712-13.713-13.712c-4.155,0-8.053,1.91-10.695,5.239c-0.297,0.371-0.926,0.597-1.417,0.656l-0.109,0.013
	c-0.55-0.07-0.997-0.329-1.285-0.726c-7.68-10.515-19.994-16.792-32.938-16.792c-18.178,0-34.303,12.22-39.237,29.724
	c-0.206,0.73-0.819,1.284-1.575,1.346c-14.344,1.186-25.263,14.515-22.888,29.264c1.891,11.745,13.637,21.274,25.533,21.274h5.655
	c1.074,0,1.926,0.96,1.744,2.067c-0.143,0.87-0.961,1.47-1.842,1.47h-6.094C12.946,147.322,0,134.375,0,118.463
	c0-13.867,10.319-26.089,24.003-28.43l1.262-0.215l0.372-1.177c6.134-17.945,22.984-30.001,41.926-30.001
	c12.616,0,24.691,5.444,33.128,14.938l1.341,1.506l1.519-1.244c3.52-2.732,7.837-3.966,12.334-3.491
	c8.787,0.928,15.558,8.627,15.369,17.461c-0.012,0.54-0.054,1.081-0.126,1.644l-0.218,1.661l1.524,0.564
	c10.447,4.159,17.396,13.796,18.114,24.882C150.614,117.586,149.806,118.463,148.776,118.463L148.776,118.463z M241.291,192.051
	v-66.043c0-0.939-0.762-1.7-1.7-1.7H133.448c-0.938,0-1.7,0.761-1.7,1.7v66.043c0,0.938,0.762,1.7,1.7,1.7h106.143
	C240.529,193.751,241.291,192.989,241.291,192.051z M135.148,127.708h102.742v62.643H135.148V127.708z M249.567,194.105v-2.055
	c0-0.938-0.762-1.7-1.7-1.7h-122.65c-0.939,0-1.7,0.762-1.7,1.7v2.055c0,4.3,3.498,7.798,7.798,7.798H241.77
	C246.069,201.903,249.567,198.405,249.567,194.105z M246.167,193.751v0.354c0,2.425-1.973,4.397-4.397,4.397H131.314
	c-2.425,0-4.397-1.973-4.397-4.397v-0.354H246.167z'
      />
      <path
        className='st2'
        d='M170.241,160.945c-1.019,0-1.847-0.828-1.847-1.847c0-10.186,8.285-18.472,18.47-18.472
	c4.359,0,8.579,1.552,11.931,4.38v-5.692c0-1.019,0.829-1.848,1.848-1.848s1.847,0.829,1.847,1.848V149.8
	c0,1.019-0.828,1.848-1.847,1.848h-10.485c-1.019,0-1.847-0.829-1.847-1.848s0.828-1.847,1.847-1.847h6.403
	c-2.7-2.346-6.123-3.632-9.696-3.632c-8.147,0-14.775,6.629-14.775,14.777C172.088,160.117,171.259,160.945,170.241,160.945z
	 M203.453,157.266c-1.019,0-1.847,0.828-1.847,1.847c0,8.148-6.63,14.777-14.778,14.777c-3.571,0-6.993-1.286-9.692-3.633h6.402
	c1.019,0,1.848-0.828,1.848-1.847s-0.829-1.848-1.848-1.848h-10.485c-1.019,0-1.847,0.829-1.847,1.848v10.486
	c0,1.019,0.828,1.848,1.847,1.848s1.848-0.829,1.848-1.848v-5.692c3.35,2.827,7.567,4.38,11.928,4.38
	c10.186,0,18.473-8.286,18.473-18.472C205.3,158.094,204.471,157.266,203.453,157.266z'
      />
    </svg>
  );
}
