import React, { useEffect, useState } from 'react';
import StringUtil from '../../../utils/string-util';
import { get } from '../../../services/api';

export default function TabHeader({ activeChat, event, isPrivateChat = true, text, hideAttendeeDetails }) {
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    if (activeChat) {
      (async () => {
        try {
          const { data } = await get(`/participant/${activeChat.id}/status?event=${event._id}`);
          const offlineStatuses = ['NOT_CONNECTED', 'REMOVED', 'ARCHIVED'];
          setIsOffline(offlineStatuses.includes(data.status));
        } catch (err) {
          console.error(err);
        }
      })();
    }
  }, [activeChat]);

  const name = activeChat ? StringUtil.getDisplayName(activeChat.label, hideAttendeeDetails) : '';
  return (
    <div className='label_private_chat'>
      {isPrivateChat && <div className='initials'>{activeChat && StringUtil.getInitials(name)}</div>}
      <div className='text' style={{ marginRight: 4, flexDirection: 'row' }}>
        {isPrivateChat && <span>{`This is a private conversation with ${name}`}</span>}
        {!isPrivateChat && <span>{text}</span>}
        {isPrivateChat && isOffline && <span className='offline-label'>offline</span>}
      </div>
    </div>
  );
}
