import React from 'react';
import { isMobile } from '../utils/browser-util';

export default function HtmlContainer({ children, htmlLocked, backgroundColor, height, html }) {
  const style = {
    backgroundColor,
    height: isMobile() ? 0.75 * height : height,
    minHeight: isMobile() ? 0.75 * height : height,
    fontSize: isMobile() ? '0.75rem' : '1rem',
  };

  if (children) {
    return (
      <div
        className='html-container'
        style={{
          display: 'flex',
          overflow: 'hidden',
          ...style,
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: !htmlLocked
              ? `<div style='margin-left: 20px; width: 100%; height: 100%;'>${html}</div>`
              : `<div style='width: 100%; height: 100%;'>${html}</div>`,
          }}
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            ...style,
          }}
        />
        {children}
      </div>
    );
  } else {
    return (
      <div
        className='html-container'
        dangerouslySetInnerHTML={{
          __html: !htmlLocked
            ? `<div style='margin-left: 20px; width: 100%; height: 100%;'>${html}</div>`
            : `<div style='width: 100%; height: 100%;'>${html}</div>`,
        }}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          overflow: 'hidden',
          ...style,
        }}
      />
    );
  }
}
