import React, { useState, useEffect } from 'react';
import swal from '@sweetalert/with-react';
import Button from '../../../common/Button';
import { post } from '../../../services/api';
import CustomRadio from '../../../common/Radio/CustomRadio';
import Checkbox from '../../../common/Checkbox';
import './Poll.css';

const styles = {
  label: {
    textAlign: 'left',
    color: '#a9aaab',
    marginBottom: 5,
  },
  question: {
    fontSize: 18,
    textAlign: 'left',
  },
  list: {
    margin: '30px 0 20px 0',
  },
  button: {
    margin: '0 0 10px 0',
    width: '100%',
    padding: '2px 20px',
    fontSize: 14,
  },
};

const PrefixAnswersWith = {
  NOTHING: 0,
  LETTERS: 1,
  NUMBERS: 2,
};

const PollTypes = {
  POLL: 0,
  QUESTION: 1,
};

const PollInputTypes = {
  RADIO: 0,
  CHECKBOX: 1,
};

export default function Poll({ poll, onClose, eventParticipant, isOffWall, isOffWallReady, isVenueParticipant }) {
  const [question, setQuestion] = useState(null);
  const [responses, setResponses] = useState([]);
  const [selection, setSelection] = useState(null);
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  useEffect(() => {
    if (poll) {
      const parts = poll.data.split('\n').filter((d) => !!d);
      const _responses = [];

      parts.forEach((p, i) => {
        if (i === 0) {
          setQuestion(p);
        } else {
          _responses.push(p);
        }
      });
      setResponses(_responses);
      setSelection(null);
      setSelectedAnswers([]);
    }
  }, [poll]);

  const _submitAnswer = async () => {
    try {
      setIsSubmittingAnswer(true);

      if (poll.inputType === PollInputTypes.RADIO) {
        await post(`/poll/${poll._id}/answer`, { eventParticipant: eventParticipant._id, selection: selection.answer, selectedAt: selection.selectedAt });
        setSelection(null);
      } else {
        const isExclusiveAnswerSelected = selectedAnswers.some((a) => poll.exclusiveAnswers.includes(responses.indexOf(a.answer)));
        if (!isExclusiveAnswerSelected) {
          if (poll.minAnswers && selectedAnswers.length < poll.minAnswers) {
            swal({
              title: 'Error',
              text: `Please select at least ${poll.minAnswers} answers.`,
            });
            return;
          } else if (poll.maxAnswers && selectedAnswers.length > poll.maxAnswers) {
            swal({
              title: 'Error',
              text: `Please select no more than ${poll.maxAnswers} answers.`,
            });
            return;
          }
        }

        const updates = selectedAnswers.map((a) =>
          post(`/poll/${poll._id}/answer`, { eventParticipant: eventParticipant._id, selection: a.answer, selectedAt: a.selectedAt }),
        );
        await Promise.all(updates);
        setSelectedAnswers([]);
      }

      if (onClose) {
        onClose();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmittingAnswer(false);
    }
  };

  const _close = async () => {
    try {
      setIsSubmittingAnswer(true);
      await post(`/poll/${poll._id}/answer`, { eventParticipant: eventParticipant._id, selection: null });
      setSelection(null);
      if (onClose) {
        onClose();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmittingAnswer(false);
    }
  };

  const _renderResponses = () => {
    return responses.map((resp, i) => {
      const id = `poll-response-${i}`;
      let label;
      if (poll.prefixAnswersWith === PrefixAnswersWith.NOTHING) {
        label = resp;
      } else if (poll.prefixAnswersWith === PrefixAnswersWith.LETTERS) {
        label = `${String.fromCharCode(97 + i).toUpperCase()}. ${resp}`;
      } else if (poll.prefixAnswersWith === PrefixAnswersWith.NUMBERS) {
        label = `${i + 1}. ${resp}`;
      }
      if (poll.type === PollTypes.POLL && poll.inputType === PollInputTypes.CHECKBOX) {
        return (
          <li key={id} style={styles.listItem}>
            <Checkbox
              label={label}
              value={selectedAnswers.find((a) => a.answer === resp)}
              onChange={(checked) => {
                if (checked) {
                  if (
                    poll.exclusiveAnswers &&
                    poll.exclusiveAnswers.length &&
                    poll.exclusiveAnswers.includes(i)
                  ) {
                    setSelectedAnswers([
                      { answer: resp, selectedAt: new Date() },
                    ]);
                  } else {
                    const isExclusiveAnswerSelected = selectedAnswers.some(
                      (a) =>
                        poll.exclusiveAnswers.includes(
                          responses.indexOf(a.answer)
                        )
                    );
                    if (isExclusiveAnswerSelected) {
                      setSelectedAnswers([
                        { answer: resp, selectedAt: new Date() },
                      ]);
                    } else if (
                      poll.maxAnswers &&
                      selectedAnswers.length >= poll.maxAnswers
                    ) {
                      swal({
                        title: "Error",
                        text: `Please select no more than ${poll.maxAnswers} answers.`,
                      });
                    } else {
                      setSelectedAnswers([
                        ...selectedAnswers,
                        { answer: resp, selectedAt: new Date() },
                      ]);
                    }
                  }
                } else {
                  setSelectedAnswers(
                    selectedAnswers.filter((a) => a.answer !== resp)
                  );
                }
              }}
              style={{ marginBottom: 10 }}
            />
          </li>
        );
      }
      return (
        <li key={id} style={{ textAlign: 'left', listStyle: 'none' }}>
          <CustomRadio
            label={label}
            name='answer'
            value={resp}
            checked={selection && selection.answer === resp}
            onChange={({ target: { value } }) => setSelection({ answer: value, selectedAt: new Date() })}
          />
        </li>
      );
    });
  };

  let canShowPoll = true;
  if (isOffWall) {
    canShowPoll = isOffWallReady;
  }
  return poll && canShowPoll ? (
    <div className='poll-container' style={{ top: isOffWall || isVenueParticipant ? 0 : 180 }}>
      <div className='custom-scrollbar' style={{ flex: 1, overflowY: 'auto', margin: '5px 0' }}>
        {question && <div style={styles.question}>{question}</div>}
        <ul style={styles.list}>{_renderResponses()}</ul>
      </div>
      <div>
        <Button
          text='Submit'
          type='primary'
          onClick={_submitAnswer}
          containerStyle={styles.button}
          disabled={
            isSubmittingAnswer ||
            (poll.inputType === PollInputTypes.RADIO && selection === null) ||
            (poll.inputType === PollInputTypes.CHECKBOX && selectedAnswers.length === 0)
          }
        />
        <Button
          text='Close Without Responding'
          type='secondary'
          onClick={_close}
          containerStyle={{ ...styles.button, marginBottom: 0 }}
          disabled={isSubmittingAnswer}
        />
      </div>
    </div>
  ) : null;
}
