export const actions = {
  SET_ATTENDEE_LIST: 'SET_ATTENDEE_LIST',
  SET_OFF_WALL_REACTION_STATUS: 'SET_OFF_WALL_REACTION_STATUS',
  SET_LOGIN_URL_SEARCH_PARAMS: 'SET_LOGIN_URL_SEARCH_PARAMS',
  SET_IS_OFF_WALL_ATTENDEE_LIST_OPEN: 'SET_IS_OFF_WALL_ATTENDEE_LIST_OPEN',
  SET_IS_VENUE_PARTICIPANT: 'SET_IS_VENUE_PARTICIPANT',
};

export function setAttendeeList(data) {
  return {
    type: actions.SET_ATTENDEE_LIST,
    payload: { data },
  };
}

export function setOffWallReactionStatus(data) {
  return {
    type: actions.SET_OFF_WALL_REACTION_STATUS,
    payload: { data },
  };
}

export function setLoginUrlSearchParams(url) {
  return {
    type: actions.SET_LOGIN_URL_SEARCH_PARAMS,
    payload: { url },
  };
}

export function setIsOffWallAttendeeListOpen(value) {
  return {
    type: actions.SET_IS_OFF_WALL_ATTENDEE_LIST_OPEN,
    payload: { value },
  };
}

export function setIsVenueParticipant(value) {
  return {
    type: actions.SET_IS_VENUE_PARTICIPANT,
    payload: { value },
  };
}
