import Storage from './storage';
import { OBSERVER } from './user-util';
const localStorage = Storage.getLocalStorage();

export const getContextMenuItems = (uuid, role) => {
  const items = [];
  if (role !== OBSERVER) {
    items.push({ value: 1, label: 'Send Private Chat' });
  }

  const myUuid = localStorage.getItem('uuid');
  if (uuid === myUuid) {
    items.shift();
  }
  return items;
};
