import React from 'react';
import ScreeningQueue from '../../assets/screening_queue.svg';
import StringUtil from '../../utils/string-util';
import useWindowResize from '../../hooks/useWindowResize';
import { getMessageBoxTransform } from '../../utils/browser-util';
import './Messages.css';

function InScreeningQueueMessage({ show, position }) {
  const { height } = useWindowResize();
  return (
    <div className='message-box' style={getMessageBoxTransform(height)}>
      <span className='show-id'>{show}</span>
      <img src={ScreeningQueue} alt='In screening queue' />
      <span className='text green'>Please stand by for the next available screening technician who will confirm your ability to send and receive video.</span>
      <div className='message-footer'>
        <span className='top'>You Are Now</span>
        <div className='position'>{StringUtil.ordinalSuffix(position)}</div>
        <span className='bottom'>In The Screening Queue</span>
      </div>
    </div>
  );
}

export default InScreeningQueueMessage;
