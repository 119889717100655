import React, { forwardRef, useRef, useState, useEffect } from 'react';
import swal from '@sweetalert/with-react';
import axios from 'axios';
import Popover from 'react-popover';
import { faPlay, faPause, faCog } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../janusBreakoutRoom/IconButton';
import VideoOverlay from '../../common/VideoOverlay';
import Button from '../../common/Button';
import Select from '../../common/Select';
import Spinner from '../../common/Spinner';
import { fileToBase64, base64toBlob } from '../../utils/base64-util';
import { post, put } from '../../services/api';
import ImageCache from '../../utils/image-cache';
import { isIpad } from '../../utils/browser-util';
import Storage from '../../utils/storage';
import defaultPhoto from '../../assets/default-event.jpg';
import MediaDeviceUtil from '../../utils/media-device-util';
import SocketClient from '../../utils/socket-client';
import PhotoTakenIcon from '../../assets/icon-photo.svg';
import PhotoTakenCheckIcon from '../../assets/icon-photo-check.svg';
import ConfirmPauseMessage from '../../common/Messages/ConfirmPauseMessage';
import addReportingEvent from '../../utils/reporting-event';
import { store } from '../../store';
import EventEmitter from '../../utils/event-emitter';
import { IN_SCREENING_QUEUE, IN_SCREENING, IN_STUDIO_QUEUE, ON_AIR, ON_WALL } from '../../utils/user-util';

const localStorage = Storage.getLocalStorage();

const styles = {
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 20,
  },
  greyBtn: {
    padding: '2px 15px',
    fontSize: 12,
  },
};

const LocalMedia = (
  {
    status,
    mediaDevices,
    photoTaken,
    photoUrl,
    videoInputDevices,
    onVideoInputChange,
    videoInput,
    onAcceptPhoto,
    isOnMeetingBreak,
    customButtonStyle,
    acceptPhotoButtonStyle,
    uiVersion,
    participantData,
  },
  ref,
) => {
  const urlParams = new URLSearchParams(window.location.search);
  const autoConnect = urlParams.get('auto-connect');

  const { videoRef } = ref;
  const _canvasRef = useRef(null);
  const _imgRef = useRef(null);
  const _fileInputRef = useRef(null);
  const _pauseStateRef = useRef(null);
  const _pausedBeforeBreakRef = useRef(null);
  const _fileRef = useRef(null);

  const [mediaReady, setMediaReady] = useState(false);
  const [showMediaDevices, setShowMediaDevices] = useState(false);
  const [paused, setPaused] = useState(false);
  const [photoSrc, setPhotoSrc] = useState(null);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  // componentDidMount
  useEffect(() => {
    (async () => {
      _fileRef.current = {};
      const uuid = localStorage.getItem('uuid');
      const event = localStorage.getItem('participantEvent') && JSON.parse(localStorage.getItem('participantEvent'));
      const skipPhotoModal = localStorage.getItem('skipPhotoModal');

      if (skipPhotoModal === 'true') {
        localStorage.removeItem('skipPhotoModal');
        if (!photoUrl) {
          await _useDefault();
          await _acceptPhoto();
        } else {
          onAcceptPhoto && onAcceptPhoto({ photoUrl });
        }
      }
      const hasTakenPhoto = localStorage.getItem('photoTaken') && localStorage.getItem('photoTaken') === 'true';

      if (autoConnect || hasTakenPhoto) {
        onAcceptPhoto && onAcceptPhoto({ photoUrl });
      }

      if (event && photoUrl && photoUrl.includes(`${event._id}-${uuid}`)) {
        const url = new URL(photoUrl);
        try {
          const imgData = await ImageCache.get(url.pathname.substring(1));
          setPhotoSrc(imgData);
        } catch (err) {
          // Ignore in case the photo isn't available on S3
        }
      }

      const timerId = setTimeout(async () => {
        await swal({
          title: 'Cam/Mic Error',
          text: 'We are having trouble accessing you camera or microphone. Please close other applications that might be using your devices and hit refresh to reload the page.',
          buttons: {
            cancel: 'Refresh',
          },
          dangerMode: true,
        });
        window.location.reload();
      }, 15000);

      await Promise.all([MediaDeviceUtil.videoStreamInitialized.promise, MediaDeviceUtil.audioStreamInitialized.promise]);
      setMediaReady(true);
      clearTimeout(timerId);
    })();

    return () => {
      if (_pauseStateRef.current === true) SocketClient.emitClientAction('unpause', {});
    };
  }, []);

  useEffect(() => {
    if (photoTaken) {
      const {
        router: {
          location: { state: participantInfo },
        },
      } = store.getState();
      const eventId = participantInfo.event._id;
      const prevPausedState = localStorage.getItem(`paused-${eventId}`) || false;
      if (participantInfo.videoEnabled === false || prevPausedState) {
        const _onWebrtcConnected = () => {
          _pause();
          EventEmitter.removeListener('rtc-client-connected', _onWebrtcConnected);
        };
        EventEmitter.on('rtc-client-connected', _onWebrtcConnected);
      }
    }
  }, [photoTaken]);

  useEffect(() => {
    if (isOnMeetingBreak === true) {
      if (_pauseStateRef.current === true) {
        _pausedBeforeBreakRef.current = true;
      } else {
        _pause();
      }
    } else if (isOnMeetingBreak === false) {
      if (_pausedBeforeBreakRef.current === true) {
        _pausedBeforeBreakRef.current = false;
      } else {
        _unpause();
      }
    }
  }, [isOnMeetingBreak]);

  useEffect(() => {
    if (photoUrl && paused) {
      const url = new URL(photoUrl);
      ImageCache.get(url.pathname.substring(1)).then((photoData) => {
        _imgRef.current.setAttribute('src', photoData);
      });
    }
  }, [photoUrl, paused]);

  async function _pause() {
    let photoData;
    try {
      const event = localStorage.getItem('participantEvent') && JSON.parse(localStorage.getItem('participantEvent'));
      const eventId = event ? event._id : null;
      localStorage.setItem(`paused-${eventId}`, true);

      setPaused(true);
      _pauseStateRef.current = true;

      if (participantData) {
        await put(`/eventParticipant/${participantData._id}`, { paused: true });
      }

      const url = new URL(photoUrl);
      photoData = await ImageCache.get(url.pathname.substring(1));
    } catch (err) {
      console.error(err);
    } finally {
      if (!photoData) {
        let image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = function () {
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');
          canvas.height = this.naturalHeight;
          canvas.width = this.naturalWidth;
          ctx.drawImage(this, 0, 0);
          photoData = canvas.toDataURL('image/png');
          _imgRef.current.setAttribute('src', photoData);
          SocketClient.emitClientAction('pause', { photoUrl });
        };
        image.src = defaultPhoto;
      } else {
        _imgRef.current.setAttribute('src', photoData);
        SocketClient.emitClientAction('pause', { photoUrl });
      }
      _imgRef.current.style.display = 'block';
    }
  }

  async function _unpause() {
    setPaused(false);
    _pauseStateRef.current = false;
    _imgRef.current.style.display = 'none';
    SocketClient.emitClientAction('unpause', {});
    const event = localStorage.getItem('participantEvent') && JSON.parse(localStorage.getItem('participantEvent'));
    const eventId = event ? event._id : null;
    localStorage.removeItem(`paused-${eventId}`);
    if (participantData) {
      await put(`/eventParticipant/${participantData._id}`, { paused: false });
    }
  }

  async function _togglePause({ value }) {
    const uuid = localStorage.getItem('uuid');
    const event = localStorage.getItem('participantEvent') && JSON.parse(localStorage.getItem('participantEvent'));
    if (value === true) {
      await swal({
        buttons: {},
        closeOnClickOutside: false,
        closeOnEsc: false,
        className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
        content: (
          <ConfirmPauseMessage
            onAccept={() => {
              _pause();
              addReportingEvent('PAUSE', event, uuid, {});
              swal.close();
            }}
            onCancel={() => swal.close()}
          />
        ),
      });
    } else {
      _unpause();
      addReportingEvent('RESUME', event, uuid, {});
    }
  }

  function _resize(el, originalWidth, originalHeight) {
    const context = _canvasRef.current.getContext('2d');
    const { width: canvasWidth, height: canvasHeight } = _canvasRef.current;
    context.clearRect(0, 0, canvasWidth, canvasHeight);
    context.fillStyle = '#000000';
    context.fillRect(0, 0, canvasWidth, canvasHeight);

    const ratio = Math.min(canvasWidth / originalWidth, canvasHeight / originalHeight);
    const width = originalWidth * ratio;
    const height = originalHeight * ratio;

    context.drawImage(el, 0, 0, originalWidth, originalHeight, canvasWidth / 2 - width / 2, canvasHeight / 2 - height / 2, width, height);

    let data;
    let quality = 1;

    while (!data || data.length > 256 * 1024) {
      data = _canvasRef.current.toDataURL('image/jpeg', quality);
      quality = quality * 0.95;
    }
    const imgFile = _convertToFile(data);
    setPhotoSrc(data);
    setFile(imgFile);
    _fileRef.current = imgFile;
    console.log('compressed photo to', data.length, 'bytes', imgFile);
  }

  function _takePhoto() {
    const { videoWidth, videoHeight } = videoRef.current;
    _resize(videoRef.current, videoWidth, videoHeight);
  }

  function _convertToFile(data) {
    const block = data.split(';');
    const contentType = block[0].split(':')[1];
    const realData = block[1].split(',')[1];
    const blob = base64toBlob(realData, contentType);
    return new File([blob], 'image.png');
  }

  async function _onFileChange(event) {
    const { files } = event.target;
    const selectedFile = files[0];
    if (selectedFile) {
      const imgData = await fileToBase64(selectedFile);
      const img = new Image();
      img.onload = () => {
        const { naturalWidth, naturalHeight } = img;
        _resize(img, naturalWidth, naturalHeight);
      };
      img.src = imgData;
    }
  }

  function _uploadPhoto() {
    _fileInputRef.current.click();
  }

  async function _acceptPhoto() {
    let file = _fileRef.current;
    if (!file) {
      onAcceptPhoto && onAcceptPhoto({ photoUrl });
      return;
    }
    try {
      setUploading(true);
      let ext = 'png';
      let fileType = 'image/png';
      if (file && file.name) {
        ext = file.name.split('.').pop().toLowerCase();
        fileType = file.type;
      }
      const uuid = localStorage.getItem('uuid');
      const event = localStorage.getItem('participantEvent') && JSON.parse(localStorage.getItem('participantEvent'));
      const fileName = `${event._id}-${uuid}-${Date.now()}.${ext}`;
      const fileKey = `participants/${fileName}`;
      const response = await post('/participant/uploadPhoto', { fileName, fileType, fileKey, uuid, eventId: event._id });
      const returnData = response.data;
      const { signedRequest, url } = returnData;
      axios
        .put(signedRequest, file, {
          headers: {
            'Content-Type': fileType,
          },
        })
        .then(() => {
          ImageCache.remove(fileKey);
          ImageCache.get(fileKey);
        });
      localStorage.setItem('photoTaken', 'true');
      onAcceptPhoto && onAcceptPhoto({ photoUrl: url });
    } catch (error) {
      console.error(error);
      onAcceptPhoto && onAcceptPhoto({ photoUrl: null });
    }
  }

  function _useDefault() {
    return new Promise(async (resolve) => {
      const event = JSON.parse(localStorage.getItem('participantEvent'));
      let imgData = event && event.logo ? await ImageCache.get(event.logo) : defaultPhoto;
      const img = new Image();
      img.onload = () => {
        const { naturalWidth, naturalHeight } = img;
        _resize(img, naturalWidth, naturalHeight);
        resolve();
      };
      img.src = imgData;
    });
  }

  const instructionsTitle = photoSrc ? 'Great!' : 'Add Your Photo';
  const instructionsText = photoSrc
    ? `Press the Accept Photo button to proceed or feel free to try again.`
    : `Please select one of the options ${
        uiVersion === 'onboarding' ? 'below' : 'above'
      } to either take a new photo, upload an existing photo or use the default logo.`;

  return (
    <div>
      <input ref={_fileInputRef} hidden type='file' accept='image/*' onChange={_onFileChange} />
      {!photoTaken && uiVersion === 'onboarding' && (
        <div
          className='photo-modal-message'
          style={{ margin: '0px 60px', paddingTop: 0, backgroundColor: 'transparent', justifyContent: 'center', height: 40 }}
        >
          <div className='text'>
            <p style={{ fontSize: 14, textAlign: 'center' }}>{instructionsText}</p>
          </div>
        </div>
      )}
      <div style={styles.flexRow}>
        <div>
          <div className='webcam-container local' style={{ backgroundColor: '#1E2A37', marginBottom: photoTaken ? 0 : 10 }}>
            {!mediaReady && (
              <div className='spinner-container'>
                <Spinner />
              </div>
            )}
            {status === ON_AIR && (
              <span className='status-label on-air' style={{ width: 50 }}>
                ON AIR<span className='tooltip'>Your mic is live and your audio may be sent to other participants.</span>
              </span>
            )}
            {status === ON_WALL && (
              <span className='status-label on-wall' style={{ width: 65 }}>
                ON WALL<span className='tooltip'>Your video is live on the Pando Wall and may be seen by other participants.</span>
              </span>
            )}
            {status === IN_STUDIO_QUEUE && (
              <span className='status-label in-studio-queue' style={{ width: 126 }}>
                IN STUDIO QUEUE<span className='tooltip'>Your audio and video are not yet live but you may be added to the Pando Wall shortly.</span>
              </span>
            )}
            {photoTaken && <VideoOverlay paddingBottom={18} />}
            <canvas ref={_canvasRef} width={1280} height={720} style={{ display: 'none' }} />
            <div
              className='pause-image'
              style={{
                display: paused ? 'flex' : 'none',
              }}
            >
              <img ref={_imgRef} alt='' />
            </div>
            <video
              ref={videoRef}
              id='local-video'
              className='local'
              autoPlay
              muted
              disablePictureInPicture
              style={{ width: 320, opacity: paused ? 0 : 1 }}
            ></video>
            {photoTaken && (
              <div className='media-controls'>
                <div className='media'>
                  {status && status !== IN_SCREENING_QUEUE && status !== IN_SCREENING && (
                    <IconButton onIcon={faPlay} offIcon={faPause} value={paused} onChange={_togglePause} tooltip={paused ? 'Unpause' : 'Pause'} />
                  )}
                  <Popover
                    isOpen={showMediaDevices}
                    body={mediaDevices}
                    onOuterAction={() => setShowMediaDevices(false)}
                    tipSize={6}
                    place='right'
                    style={{ fill: '#1e272f', zIndex: 99999 }}
                  >
                    <IconButton onIcon={faCog} offIcon={faCog} value={showMediaDevices} onChange={() => setShowMediaDevices(true)} tooltip='Device Settings' />
                  </Popover>
                </div>
              </div>
            )}
          </div>
          {!photoTaken && videoInputDevices && videoInputDevices.length > 0 && (
            <div style={{ width: 320 }}>
              <Select
                list={videoInputDevices}
                listKey='deviceId'
                listLabel='label'
                onChange={onVideoInputChange}
                selected={videoInput && videoInput.deviceId}
                currentOption={videoInput && videoInput.label}
                containerStyle={{ width: '100%' }}
                disabled={!mediaReady}
                small
              />
            </div>
          )}
        </div>

        {!photoTaken && (
          <div style={styles.flexCol}>
            <div className='webcam-container photo' style={{ marginBottom: 15 }}>
              <img src={photoSrc} style={{ display: photoSrc ? 'block' : 'none' }} />
            </div>
            <div>
              <Button
                text='Take Photo'
                type='secondary'
                onClick={_takePhoto}
                containerStyle={{ ...styles.greyBtn, ...customButtonStyle, display: photoSrc ? 'none' : 'inline-block' }}
                disabled={uploading || !mediaReady}
              />
              <Button
                text='Upload Photo'
                type='secondary'
                onClick={_uploadPhoto}
                containerStyle={{ ...styles.greyBtn, ...customButtonStyle, display: photoSrc ? 'none' : 'inline-block' }}
                disabled={uploading || !mediaReady}
              />
              <Button
                text='Use Default'
                type='secondary'
                onClick={_useDefault}
                containerStyle={{ ...styles.greyBtn, ...customButtonStyle, display: photoSrc ? 'none' : 'inline-block' }}
                disabled={uploading || !mediaReady}
              />
              <div
                className='accept-button-container'
                style={{
                  display: !photoSrc ? 'none' : 'flex',
                  justifyContent: uiVersion === 'photo-modal' ? 'flex-end' : 'flex-end',
                  marginTop: 0,
                }}
              >
                <Button
                  text='Accept Photo'
                  type='primary'
                  onClick={_acceptPhoto}
                  disabled={uploading || !mediaReady || !photoSrc}
                  containerStyle={{ padding: '2px 30px' }}
                />
                <Button text='Change Photo' type='secondary' onClick={() => setPhotoSrc(null)} containerStyle={{ padding: '2px 30px' }} />
              </div>
            </div>
          </div>
        )}
      </div>

      {!photoTaken && uiVersion === 'photo-modal' && (
        <div className='photo-modal-message'>
          <img src={photoSrc ? PhotoTakenCheckIcon : PhotoTakenIcon} alt='icon' />
          <div className='text'>
            <h4>{instructionsTitle}</h4>
            <p>{instructionsText}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default forwardRef(LocalMedia);
