import React, { PureComponent } from 'react';
import { isMobile } from '../utils/browser-util';

export default class HtmlWidget extends PureComponent {
  render() {
    const { height, width, backgroundColor, pinTo, spacing, html, ignoreIsMobile = false } = this.props;
    const spacingOptions = pinTo.split('-');

    const _isMobile = !ignoreIsMobile && isMobile();
    const _width = _isMobile ? '100%' : width;
    const _height = _isMobile ? '100%' : height;

    let styleProperties = {
      position: 'absolute',
      height: _height,
      width: _width,
      backgroundColor,
      html,
      zIndex: 10,
      overflow: 'hidden',
      fontSize: _isMobile ? '0.75rem' : '1rem',
    };
    if (!_isMobile) {
      styleProperties = {
        ...styleProperties,
        [spacingOptions[0]]: spacing[spacingOptions[0]],
        [spacingOptions[1]]: spacing[spacingOptions[1]],
      };
    }

    return (
      <div
        style={styleProperties}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></div>
    );
  }
}
