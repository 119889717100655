import React from 'react';
import swal from '@sweetalert/with-react';
import Icon from '../../assets/unsupported_device_browser.svg';

export default function UnsupportedDevice() {
  return (
    <div className={'horizontal-message-box'} style={{ width: 'auto' }}>
      <div className='content' style={{ marginTop: 0 }}>
        <div className='left'>
          <div className='text'>
            <div>Notice</div>
            <div
              style={{
                fontSize: 14,
                color: '#797878',
                marginTop: 10,
                marginBottom: 5,
                textAlign: 'left',
              }}
            >
              For a seamless expierence, please use a desktop, laptop or an iPad with the latest version of Chrome, Edge or Safari.
            </div>
          </div>
          <button
            onClick={() => {
              swal.close();
              window._unsupportedDeviceAcknowledged();
            }}
          >
            Continue
          </button>
        </div>
        <div className='right'>
          <img src={Icon} alt='icon' />
        </div>
      </div>
    </div>
  );
}
