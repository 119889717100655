import React from 'react';

export default function ParticipantCount({ label, value }) {
  return (
    <div className='counter'>
      <div className='label'>{label}</div>
      <div className='value'>{value}</div>
    </div>
  );
}
