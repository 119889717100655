import React, { PureComponent } from 'react';
import uuidv4 from 'uuid/v4';
import SocketClient from '../../../utils/socket-client';
import StringUtil from '../../../utils/string-util';
import sendIcon from '../../../assets/new_icons/send.svg';
import MessageBox from './MessageBox';
import './Chat.css';

class Chat extends PureComponent {
  state = {
    message: '',
    messageList: [],
  };

  _messageListRef = React.createRef();

  componentDidMount() {
    this.roomName = `breakout-room-${this.props.credentials.room._id}`;
    SocketClient.joinRoom(this.roomName);
    SocketClient.addListener('chat-message', (data) => {
      if (data.roomName === this.roomName) {
        this.messageReceived(data);
      }
    });
  }

  componentWillUnmount() {
    SocketClient.leaveRoom(this.roomName);
    SocketClient.removeListener('chat-message');
  }

  componentDidUpdate() {
    this._scrollToBottom();
  }

  _scrollToBottom() {
    const { scrollHeight, clientHeight } = this._messageListRef.current;
    const maxScrollTop = scrollHeight - clientHeight;
    this._messageListRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }

  messageReceived = (message) => {
    this.setState({
      messageList: [...this.state.messageList, message],
    });
  };

  _onTextChange = (event) => {
    this.setState({ message: event.target.value });
  };

  _sendMessage = () => {
    console.log('sending message', this.state.message.trim().length);
    if (this.state.message.trim().length > 0) {
      let messagePayload = {
        id: uuidv4(),
        user: this.props.credentials.identity,
        message: this.state.message.trim(),
        date: new Date(),
      };
      this.setState({
        message: '',
        messageList: [...this.state.messageList, messagePayload],
      });

      SocketClient.socket.emit('chat-message', { roomName: this.roomName, message: messagePayload });
    }
  };

  _getUserInitials = (identity) => {
    const identityParts = identity.split(' ');
    return StringUtil.removeSpecialCharacters(`${identityParts[0][0].toUpperCase()}${identityParts[1] ? identityParts[1][0].toUpperCase() : ''}`.trim());
  };

  render() {
    const { hideAttendeeDetails } = this.props;
    const name = StringUtil.getDisplayName(this.props.credentials.identity, hideAttendeeDetails);

    return (
      <div className='chat'>
        <div ref={this._messageListRef} className='chat-messages'>
          {this.state.messageList.map((msg) => (
            <MessageBox key={msg.id} message={msg} credentials={this.props.credentials} hideAttendeeDetails={hideAttendeeDetails} />
          ))}
        </div>
        <div className='chat-footer'>
          <div className='chat-user'>{this._getUserInitials(name)}</div>
          <div className='chat-input'>
            <textarea
              value={this.state.message}
              placeholder='Type your message here...'
              onChange={this._onTextChange}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  event.stopPropagation();
                  this._sendMessage();
                }
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <button onClick={this._sendMessage}>
                <img src={sendIcon} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Chat;
