import React, { useRef, useCallback, useState, useEffect } from 'react';
import { format } from 'date-fns';
import StringUtil from '../../utils/string-util';
import { isMobile } from '../../utils/browser-util';
import sendIcon from '../../assets/new_icons/send.svg';
import { post } from '../../services/api';
import TabHeader from './Chat/TabHeader';

const styles = {
  response: {
    color: '#ddd',
    margin: 15,
    padding: '10px 15px',
    textAlign: 'center',
    fontSize: 12,
    borderRadius: 15,
    backgroundColor: '#4e6379',
    display: 'inline-block',
    alignSelf: 'flex-end',
  },
};

export default function QNAWidget({ event, participant, show }) {
  const _textareaRef = useRef(null);
  const _questionsContainerRef = useRef(null);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const _fetchQuestions = async () => {
      try {
        const { data } = await post('/qna/questions', { eventId: event._id, participantId: participant.uuid });
        setQuestions(data.map((q) => ({ question: q.question, timestamp: q.timestamp, from: participant.name })));
        _questionsContainerRef.current.scrollTop = _questionsContainerRef.current.scrollHeight;
      } catch (err) {
        console.error(err);
      }
    };
    _fetchQuestions();
  }, []);

  const _sendQuestion = useCallback(async () => {
    const question = _textareaRef.current.value.replace(/(\r\n|\n|\r)/gm, '');
    if (question.length === 0) {
      return;
    }

    try {
      await post(`/qna/createQuestion`, {
        question,
        eventId: event._id,
        participantName: participant.name,
        participantId: participant.uuid,
      });
      setQuestions((prevQuestions) => [...prevQuestions, { question, from: participant.name, timestamp: Date.now() }]);
      _questionsContainerRef.current.scrollTop = _questionsContainerRef.current.scrollHeight;
    } catch (err) {
      console.error(err);
    } finally {
      _textareaRef.current.value = null;
    }
  }, [event, participant]);

  return (
    <div className='qna-widget' style={{ display: show ? 'flex' : 'none', flexDirection: 'column', height: '100%' }}>
      {!isMobile() && (
        <TabHeader isPrivateChat={false} text='Enter your questions for the presenter here. They will be answered in the order they were received.' />
      )}
      <div ref={_questionsContainerRef} className='questions-container custom-scrollbar' style={{ flex: 1, color: 'white', overflowY: 'auto' }}>
        {questions.map((question, index) => {
          return (
            <div key={`q-${index}`} style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='chat-message'>
                <div className='container'>
                  <div className='initials'>{StringUtil.getInitials(question.from)}</div>
                  <div>
                    <div className='message-info'>
                      <small>{`${question.from} - ${format(new Date(question.timestamp), 'p')}`}</small>
                    </div>
                    <div className='message-text' style={{ color: '#fff' }}>
                      {question.question}
                    </div>
                  </div>
                </div>
              </div>
              {event.qnaWidgetAutoResponse && event.qnaWidgetAutoResponse.length > 0 && <div style={styles.response}>{event.qnaWidgetAutoResponse}</div>}
            </div>
          );
        })}
      </div>
      <div className='chat-input-container'>
        <div className='chat-input'>
          <textarea
            ref={_textareaRef}
            placeholder='Type your question here...'
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                _sendQuestion();
              }
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <button onClick={_sendQuestion}>
              <img src={sendIcon} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
