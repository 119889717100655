import React from 'react';
import PropTypes from 'prop-types';
import './Input.css';

const styles = {
  inputErrorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
};

function Input(props) {
  const { label, id, style, onChange, name, type, value, stacked, labelStyle, containerStyle, hasError, errorMessage } = props;
  const input = <input id={id} name={name} type={type} value={value} onChange={onChange} style={style} autoComplete='off' />;
  if (stacked) {
    return (
      <div className={hasError ? 'stacked-input error' : 'stacked-input'}>
        <label htmlFor={id}>{label}</label>
        {input}
        {hasError && <small className='error-message'>{errorMessage}</small>}
      </div>
    );
  } else {
    return (
      <div className='input-wrapper' style={containerStyle}>
        <label htmlFor={id} className={hasError ? 'input-label error' : 'input-label'}>
          <span style={labelStyle}>{label}</span>
          <div style={styles.inputErrorWrapper}>
            {input}
            {hasError && <small className='error-message'>{errorMessage}</small>}
          </div>
        </label>
      </div>
    );
  }
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password']).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  stacked: PropTypes.bool,
  labelStyle: PropTypes.shape({}),
  containerStyle: PropTypes.shape({}),
  style: PropTypes.shape({}),
  hasError: PropTypes.bool,
};

Input.defaultProps = {
  value: '',
  id: '',
  label: '',
  stacked: false,
  labelStyle: {},
  containerStyle: {},
  style: {},
  hasError: false,
};

export default Input;
