import React, { PureComponent } from 'react';
import JanusBreakoutRoom from '../janusBreakoutRoom';
import TwilioBreakoutRoom from '../twilioBreakoutRoom/index';

export default class BreakoutRoomTestClient extends PureComponent {
  canvasRef = React.createRef();

  state = {
    stream: null,
  };

  async componentDidMount() {
    this.canvasRef.current.style.display = 'none';

    this.runAnimation();
    const canvas = this.canvasRef.current;
    const stream = canvas.captureStream(30); // 30 fps
    const intervalId = setInterval(() => {
      if (stream.getTracks().length !== 0) {
        this.setState({ stream });
        clearInterval(intervalId);
      }
    }, 250);
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animationId);
  }

  runAnimation = () => {
    let canvas = this.canvasRef.current;
    let ctx = canvas.getContext('2d'),
      particles = [],
      patriclesNum = 20,
      w = 1280,
      h = 720,
      colors = ['#f35d4f', '#f36849', '#c0d988', '#6ddaf1', '#f1e85b'];

    canvas.width = 1280;
    canvas.height = 720;

    function Factory() {
      this.x = Math.round(Math.random() * w);
      this.y = Math.round(Math.random() * h);
      this.rad = Math.round(Math.random() * 1) + 10;
      this.rgba = colors[Math.round(Math.random() * 3)];
      this.vx = Math.round(Math.random() * 3) - 1.5;
      this.vy = Math.round(Math.random() * 3) - 1.5;
    }

    function draw() {
      ctx.clearRect(0, 0, w, h);
      ctx.globalCompositeOperation = 'lighter';
      for (let i = 0; i < patriclesNum; i++) {
        let temp = particles[i];
        let factor = 1;

        for (let j = 0; j < patriclesNum; j++) {
          let temp2 = particles[j];
          ctx.linewidth = 0.5;

          if (temp.rgba == temp2.rgba && findDistance(temp, temp2) < 50) {
            ctx.strokeStyle = temp.rgba;
            ctx.beginPath();
            ctx.moveTo(temp.x, temp.y);
            ctx.lineTo(temp2.x, temp2.y);
            ctx.stroke();
            factor++;
          }
        }

        ctx.fillStyle = temp.rgba;
        ctx.strokeStyle = temp.rgba;

        ctx.beginPath();
        ctx.arc(temp.x, temp.y, temp.rad * factor, 0, Math.PI * 2, true);
        ctx.fill();
        ctx.closePath();

        ctx.beginPath();
        ctx.arc(temp.x, temp.y, (temp.rad + 25) * factor, 0, Math.PI * 2, true);
        ctx.stroke();
        ctx.closePath();

        temp.x += temp.vx;
        temp.y += temp.vy;

        if (temp.x > w) temp.x = 0;
        if (temp.x < 0) temp.x = w;
        if (temp.y > h) temp.y = 0;
        if (temp.y < 0) temp.y = h;
      }
    }

    function findDistance(p1, p2) {
      return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    this.animationId = window.requestAnimFrame = (function () {
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function (callback) {
          window.setTimeout(callback, 1000 / 60);
        }
      );
    })();

    (function init() {
      for (var i = 0; i < patriclesNum; i++) {
        particles.push(new Factory());
      }
    })();

    (function loop() {
      draw();
      window.requestAnimFrame(loop);
    })();
  };

  render() {
    const { stream } = this.state;
    return (
      <>
        <canvas ref={this.canvasRef} />
        {/* {stream ? <JanusBreakoutRoom loadTestStream={stream} onBreakoutRoomStop={this.props.onBreakoutRoomStop} /> : null} */}
        {stream ? <TwilioBreakoutRoom loadTestStream={stream} onBreakoutRoomStop={this.props.onBreakoutRoomStop} /> : null}
      </>
    );
  }
}
