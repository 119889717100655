import React, { useState } from 'react';
import { isIpad } from '../../utils/browser-util';

export default function ReactionButton({
  action,
  value,
  onIcon,
  offIcon,
  hoverColor,
  onClick,
  style,
  iconStyle,
  changeIconOnHover = true,
  display = true,
  tooltip,
}) {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const _onClick = () => {
    if (!isIpad) {
      setHovered(false);
      setClicked(true);
    }
    onClick && onClick(action);
  };

  if (!display) {
    return null;
  }

  return (
    <button
      className='reaction-button'
      onClick={_onClick}
      onMouseEnter={() => {
        if (!isIpad) {
          setHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (!isIpad) {
          setClicked(false);
          setHovered(false);
        }
      }}
      style={{ ...style, background: hovered || value ? hoverColor : '#24313f', boxShadow: clicked ? '0px 0px 10px rgb(255 255 255 / 40%)' : 'none' }}
    >
      <img src={(hovered && changeIconOnHover) || value ? onIcon : offIcon} width={30} height={30} style={{ pointerEvents: 'none', ...iconStyle }} />
      {tooltip && <span className='tooltip top'>{tooltip}</span>}
    </button>
  );
}
