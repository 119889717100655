import React, { useEffect, useState } from 'react';
import List from '../../../common/List';
import Storage from '../../../utils/storage';
import ArrayUtil from '../../../utils/array-util';
import { useSelector } from 'react-redux';
import AttendeeListItem from './AttendeeListItem';
import { EVENT_SUPPORT } from '../../../utils/user-util';
import './AttendeeList.css';

const localStorage = Storage.getLocalStorage();

export default function AttendeeList({ attendees, show, isOffWallUser, hideAttendeeDetails }) {
  const offWallReactionStatus = useSelector((state) => state.app.offWallReactionStatus);
  const uuid = localStorage.getItem('uuid');
  const [sortedList, setSortedList] = useState([]);

  const _sortList = () => {
    const filtered = attendees.filter((a) => a.role !== EVENT_SUPPORT);
    const sorted = ArrayUtil.sort(filtered, { value: 'name', dir: 'desc' });
    const index = sorted.findIndex((s) => s.uuid === uuid);
    if (index !== -1) {
      const u = sorted.splice(index, 1);
      sorted.unshift(u[0]);
    }
    setSortedList(sorted);
  };

  useEffect(() => {
    _sortList();
  }, [attendees]);

  return (
    <div className='attendee-list' style={{ display: show ? 'flex' : 'none' }}>
      <List
        data={sortedList}
        renderItem={(item) => {
          let name = `${item.firstName} ${item.lastName}`.trim();
          if (hideAttendeeDetails) {
            name = item.firstName.trim();
          }
          return (
            <AttendeeListItem
              name={name}
              reaction={isOffWallUser && uuid === item.uuid ? offWallReactionStatus : item.reactionStatus}
              status={item.status}
              highlight={uuid && uuid === item.uuid}
            />
          );
        }}
        keyExtractor={(item) => `es-${item.uuid}`}
      />
    </div>
  );
}
